import { Typewriter } from "react-simple-typewriter";
import Navbar from "../components/Navbar.js";
import Footer from "../components/Footer.js";
import { Row, Col } from "react-grid-system";
import "../style/Homepage.css";
import SlickSlider from "../components/SlickSlider.js";
import CustomizedTables from "../components/CustomizedTables.js";
import Form from "../components/Formd.js";
import ReactPlayer from "react-player";
import "aos/dist/aos.css";
import { Container } from "@material-ui/core";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import { CardActionArea } from "@mui/material";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import { Table } from "react-bootstrap";
import PriceComp from "../components/PriceComp.js";
import { Link } from "react-router-dom";
import Videoplayer from "../components/Videoplayer.js";
import Formd from "../components/Formd.js";
import React, { useEffect } from "react";
import ReactGA from "react-ga";
import { Helmet } from "react-helmet";

const HomePage = () => {
  useEffect(() => {
    ReactGA.initialize("G-FP7N65QWQ6");
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  return (
    <div>
      <Helmet>
        <title>
          Best Water Ionizer in India. Kangen Water Machine Price- Tyent
        </title>
        <meta
          name="description"
          content="Best Water Ionizer in India. Kangen Water Machine Price- Tyent"
        />
      </Helmet>
      <Carousel autoPlay showThumbs={false}>
        <div>
          <img src={require("../asserts/b2.webp")} alt="" />
        </div>
        <div>
          <img src={require("../asserts/b3.webp")} alt="" />
        </div>
        <div>
          <img src={require("../asserts/b4.webp")} alt="" />
        </div>
      </Carousel>
      <br />
      <br />

      <div>
        <Row style={{ width: "100%" }}>
          <Col
            md={9}
            className="d-flex align-items-center justify-content-center text-center"
          >
            <div className="leftside" style={{ justifyContent: "center" }}>
              <h3
                style={{
                  color: "#0082bc",
                  fontSize: "30px",
                  marginLeft: "20px",
                }}
              >
                <span>
                  <b>
                    <Typewriter
                      words={["Improving  health  and  preserving nature"]}
                      loop
                      cursor
                      cursorStyle="|"
                      typeSpeed={80}
                      deleteSpeed={50}
                      delaySpeed={1000}
                    />
                  </b>{" "}
                </span>
              </h3>
              <br />

              <p
                style={{
                  textAlign: "justify",
                  color: "#000",
                  fontSize: "20px",
                  marginLeft: "22px",
                  lineHeight: 1.4,
                  wordSpacing: "8px",
                }}
              >
                <span style={{ color: "#0082bc" }}>
                  <b style={{ fontSize: "22px", fontWeight: "900" }}>Tyent</b>
                </span>{" "}
                is a certified Medical grade water ionizer machine with Japanese
                technology from{" "}
                <span
                  style={{
                    color: "#0082bc",
                    fontSize: "22px",
                    fontWeight: "900",
                  }}
                >
                  {" "}
                  <b>TAE-YOUNG E&T CO. LTD,</b>
                </span>{" "}
                South korea. Tyent is a most trusted & top selling reputed water
                ionizer brand in the space of Hydrogen Rich alkaline water
                ionizers spreading roots across 86+ countries across the globe,
                Since 1995. Tyent is well known for its patented & updated
                technology. Tyent is always one step ahead of its completion
                with its own in house R&D, testing, innovation and execution
                experience since 1995 with a incredible history of 25+ years.
              </p>
              <br />
              <br />
              <p
                style={{
                  textAlign: "justify",
                  color: "#000",
                  fontSize: "20px",
                  marginLeft: "22px",
                  lineHeight: 1.4,
                  wordSpacing: "8px",
                }}
              >
                <span
                  style={{
                    color: "#0082bc",
                    fontSize: "22px",
                    fontWeight: "900",
                  }}
                >
                  <b>Medilight healthcare</b>
                </span>{" "}
                has been the most significant & most recognized company in the
                space of Hydrogen-rich alkaline water ionizers in India since
                2016. After good research time, Medilight health care has
                partnered with Tyent water ionizers in India to launch a
                trustable and certified water ionizer without too many middlemen
                interference and hold good after-sales and support
                responsibilities. So To date, We have successfully reached more
                than 200,000 + kitchens in the span of 5 years. Our in detail &
                In house specialized R&D, testing, installation procedure,
                techniques, on-time doorstep service & complete analysis of
                typical Indian input waters make the water ionizer's
                functionality more reliable & trouble-free for Indian users.
              </p>
              <br />
              <br />
            </div>
          </Col>
          <Col md={3} className="align-items-center justify-content-center">
            <div className="align-items-center justify-content-center">
              <center>
                <span id="colorp">
                  <strong>
                    <b>7</b>
                  </strong>
                </span>
              </center>
              <p
                style={{
                  color: "#0082bc",
                  textAlign: "center",
                  lineHeight: 1.4,
                  letterSpacing: "8px",
                  fontSize: "18px",
                  fontWeight: "600",
                }}
              >
                YEARS
              </p>
              <p
                style={{
                  color: "#0082bc",
                  textAlign: "center",
                  lineHeight: 1.4,
                  letterSpacing: "8px",
                  fontSize: "18px",
                  fontWeight: "600",
                }}
              >
                OF EXCELLENCE
              </p>
            </div>
          </Col>
        </Row>

        <br />
        <br />

        <h3 style={{ color: "#0082bc", padding: "30px" }}>
          <b>Why To drink Tyent Water ?</b>
        </h3>
        <Row className="d-flex justify-content-center align-item-center">
          <Col
            className="d-flex justify-content-center align-item-center"
            md={2.9}
          >
            <Card>
              <CardActionArea>
                <CardMedia
                  component="img"
                  width={"100%"}
                  src={require("../asserts/phlevel.webp")}
                  alt="truested"
                />
                <CardContent style={{ height: "310px" }}>
                  <Typography gutterBottom variant="h5">
                    <p style={{ color: "#0082bc" }}>
                      {" "}
                      <b>Alkalinity</b>
                    </p>
                  </Typography>
                  <Typography variant="body2" color="#000">
                    <p style={{ fontSize: "15px", textAlign: "justify" }}>
                      Acidic stress is the root cause of all the major lifestyle
                      diseases such as Diabetes, Instead of Thyroid, Instead of
                      Gastric, Constipation, Acidity, Cancer, Cardiovascular
                      diseases, Chronic respiratory diseases, and Coronary heart
                      diseases. <br />
                      <br /> Tyent ionized hydrogen alkaline water is alkaline
                      in nature (with a pH value of drinking water (7pH - 9.5pH)
                      which helps in neutralizing disease-causing acidic nature.
                    </p>
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          </Col>
          <Col
            className="d-flex justify-content-center align-item-center"
            md={2.9}
          >
            <Card>
              <CardActionArea>
                <CardMedia
                  component="img"
                  width={"100%"}
                  src={require("../asserts/soda.webp")}
                  alt="truested"
                />
                <CardContent style={{ height: "310px" }}>
                  <Typography gutterBottom variant="h5" component="div">
                    <p style={{ color: "#0082bc" }}>
                      {" "}
                      <b> Micro-clustered water</b>
                    </p>
                  </Typography>
                  <Typography variant="body2" color="#000">
                    <p style={{ fontSize: "15px", textAlign: "justify" }}>
                      Tyent ionized hydrogen rich alkaline water is
                      Micro-clustered water. Due to its micro- clustered
                      property it will penetrate faster to all the cells & major
                      organs in human body than regular tap or RO water.
                      <br />
                      <br />
                      Tyent Micro-clustered water provides better penetration,
                      better hydration, better detox and faster supply of
                      minerals.
                    </p>
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          </Col>
          <Col
            className="d-flex justify-content-center align-item-center"
            md={2.9}
          >
            <Card>
              <CardActionArea>
                <CardMedia
                  component="img"
                  width={"100%"}
                  src={require("../asserts/anti.webp")}
                  alt="truested"
                />
                <CardContent style={{ height: "310px" }}>
                  <Typography gutterBottom variant="h5" component="div">
                    <p style={{ color: "#0082bc" }}>
                      {" "}
                      <b>Anti-oxidant Molecular H2</b>
                    </p>
                  </Typography>
                  <Typography variant="body2" color="#000">
                    {" "}
                    <p style={{ fontSize: "15px", textAlign: "justify" }}>
                      Tyent ionized hydrogen rich alkaline water has selective
                      anti-oxidant called Molecular hydrogen. Molecular hydrogen
                      is rich in electrons or -ORP Which helps in neutralizing
                      free radicals (ROS) & Oxidative stress. <br />
                      <br /> It also helps in reducing inflammation, protecting
                      against oxidative stress, enhancing athletic performance &
                      supports gut health too.
                    </p>
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          </Col>
          <Col
            className="d-flex justify-content-center align-item-center"
            md={2.9}
          >
            <Card>
              <CardActionArea>
                <CardMedia
                  component="img"
                  width={"100%"}
                  src={require("../asserts/min.webp")}
                  alt="truested"
                />
                <CardContent style={{ height: "310px" }}>
                  <Typography gutterBottom variant="h5">
                    <p style={{ color: "#0082bc" }}>
                      {" "}
                      <b>Natural minerals</b>
                    </p>
                  </Typography>
                  <Typography variant="body2" color="#000">
                    <p style={{ fontSize: "15px", textAlign: "justify" }}>
                      Tyent ionized hydrogen rich alkaline water is rich in
                      alkaline minerals such as calcium, magnesium, potassium,
                      and sodium. <br />
                      <br /> Alkaline minerals help in promoting bone health,
                      Aid in muscle function, Support cardiovascular health,
                      Boost immune function & boosts metabolism.
                    </p>
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          </Col>
        </Row>

        <br />
        <br />

        <div
          style={{
            width: "100%",
            textAlign: "center",
            color: "#FFF",
            backgroundColor: "#0082bc",
          }}
        >
          <Container>
            <br />
            <br />
            <p
              style={{
                textAlign: "justify",
                fontSize: "30px",
                fontWeight: "600",
              }}
            >
              What is Kangen Water ?
            </p>
            <br />
            <p
              style={{
                textAlign: "justify",
                fontWeight: "500",
                lineHeight: 1.4,
                fontSize: "20px",
                wordSpacing: "5px",
              }}
            >
              Kangen is a Water Ionizer which is manufactured by Enagic & Co.
              Which is nothing special than other water ionizers except that it
              is little popular because of its “Referal Marketing”(similar to
              MLM) business model. The Enagic company’s Kangen machine is sold
              at “Double the price” compared to other water ionizers in the
              market as nearly 49% of the machine cost will be distributed as
              commission to almost 30 middle men through their MLM structure on
              each sale. The biggest set back in considering a kangen water
              machine for domestic use in India is lack of integrated after
              sales customer support or door step service in major cities across
              the country.
            </p>
            <br />
            <p
              style={{
                textAlign: "justify",
                fontSize: "35px",
                fontWeight: "600",
              }}
            >
              Why Tyent ?
            </p>
            <br />
            <p
              style={{
                textAlign: "justify",
                textJustify: "inter-word",
                fontWeight: "500",
                lineHeight: 1.4,
                fontSize: "20px",
                wordSpacing: "5px",
              }}
            >
              At Medilight healthcare, We believe in Traditional marketing
              method to ensure product delivery occurs from the Manufacturing
              unit to the Customer without middlemen. Tyent water ionizers are
              designed to provide pure, healthy hydration for the modern family
              with advanced Japanese technology where the Ionization chamber has
              been manufactured by Permelec Company, Japan. Tyent water ionizers
              made available at an affordable cost with more transparency to the
              customers.
            </p>
            <br />
            <br />
          </Container>
        </div>

        <br />
        <br />

        <CustomizedTables />
      </div>

      <br />

      <div
        style={{
          width: "100%",
          textAlign: "center",
          color: "#FFF",
          backgroundColor: "#0082bc",
        }}
      >
        <Container>
          <Link
            to={"/tyentvskangen"}
            style={{ textDecoration: "none", color: "#FFF" }}
          >
            <p style={{ padding: "1px", fontSize: "20px" }}>
              Click here to know in detail product technical comparsion between{" "}
              <b>TYENT</b>{" "}
              <span
                style={{
                  fontSize: "50px",
                  fontWeight: "800",
                  textShadow: "4px 4px #000",
                  transform: "rotate(-90deg)",
                }}
              >
                VS
              </span>{" "}
              <b>ENAGIC KANGEN</b>
            </p>
          </Link>
        </Container>
      </div>
      <Container>
        <PriceComp />
      </Container>
      <img src={require("../asserts/section2bg.png")} alt="" width={"100%"} />
      <br />
      <br />

      <Row>
        <Col md={4}>
          <div
            style={{
              display: "d-flex",
              color: "#0082bc",
              justifyContent: "center",
              textAlign: "center",
              fontWeight: "600",
              fontSize: "20px",
              padding: "5px",
            }}
          >
            <br />
            <p>
              World's No: 1 Tyent Alkaline water ionizer - Now officially
              available in India.
            </p>
            <br />
            <div className="cont embed-responsive embed-responsive-16by9">
              <iframe
                class="embed-responsive-item"
                src="https://www.youtube.com/embed/fZSV5ebM3D0?rel=0"
                style={{ borderRadius: "8px", width: "100%", height: "290px" }}
                allowFullScreen
              />
            </div>
          </div>
        </Col>
        <Col md={4}>
          <div
            style={{
              display: "d-flex",
              color: "#0082bc",
              justifyContent: "center",
              textAlign: "center",
              fontWeight: "600",
              fontSize: "20px",
              padding: "5px",
            }}
          >
            <br />
            <p>
              Tyent NMP series water ionizer (Above the Counter Extreme Series)
            </p>
            <br />
            <div className="cont embed-responsive embed-responsive-16by9">
              <iframe
                class="embed-responsive-item"
                src="https://www.youtube.com/embed/k213C_-BOq0?rel=0"
                style={{ borderRadius: "8px", width: "100%", height: "290px" }}
                allowFullScreen
              />
            </div>
          </div>
        </Col>
        <Col md={4}>
          <div
            style={{
              display: "d-flex",
              color: "#0082bc",
              justifyContent: "center",
              textAlign: "center",
              fontWeight: "600",
              fontSize: "20px",
              padding: "5px",
            }}
          >
            <br />
            <p>
              Tyent Vs Enagic kangen, In-detail product, technology & warrenty
              comparison
            </p>
            <br />
            <div className="cont embed-responsive embed-responsive-16by9">
              <iframe
                class="embed-responsive-item"
                src="https://www.youtube.com/embed/JAHUlOASNrI?rel=0"
                style={{ borderRadius: "8px", width: "100%", height: "290px" }}
                allowFullScreen
              />
            </div>
          </div>
        </Col>
      </Row>
      <br />
      <br />
      <br />

      <p
        id="piah"
        style={{
          textAlign: "center",
          color: "#0082bc",
          lineHeight: 1.4,
          fontWeight: "600",
          wordSpacing: "5px",
        }}
      >
        <span>
          {" "}
          Tyent water ionizer has more than 60+ International patents{" "}
        </span>
        <br />
        <span style={{ fontWeight: "600" }}>
          and certified by all International quality certification authorities.
        </span>
      </p>
      <br />
      <Container>
        <center>
          <Row className="justify-content-around  text-align-center space-evenly">
            <div id="gridcert">
              <div>
                <img src={require("../asserts/1.png")} width="90%" alt="map" />
              </div>
              <div>
                <img src={require("../asserts/2.png")} width="90%" alt="map2" />
              </div>
              <div>
                <img src={require("../asserts/3.png")} width="90%" alt="map3" />
              </div>
              <div>
                <img src={require("../asserts/4.png")} width="80%" alt="map4" />
              </div>
              <div>
                <img src={require("../asserts/5.png")} width="80%" alt="map5" />
              </div>
              <div>
                <img
                  src={require("../asserts/ce.png")}
                  width="70%"
                  alt="map6"
                />
              </div>
              <div>
                <img
                  src={require("../asserts/ul.png")}
                  width="100%"
                  alt="map6"
                />
              </div>
              <div>
                <img
                  src={require("../asserts/fda.png")}
                  width="80%"
                  alt="map6"
                />
              </div>
              <div>
                <img
                  src={require("../asserts/food.png")}
                  width="100%"
                  alt="map6"
                />
              </div>
              <div>
                <img
                  src={require("../asserts/B+.png")}
                  width="100%"
                  alt="map3"
                />
              </div>
            </div>
          </Row>
          <p style={{ color: "#0082bc", fontSize: "18px" }}>
            {" "}
            <Link to={"/Certifications"}>
              Click here to view our Prestigious certification
            </Link>
          </p>
          <br />
        </center>
      </Container>
      <br />
      <br />
      <br />
      <p
        id="piah"
        style={{ textAlign: "center", fontWeight: "600", color: "#0082bc" }}
      >
        EVERYONE TALKING ABOUT TYENT
      </p>
      <SlickSlider />

      <Row>
        <Col md={7}>
          <img
            src={require("../asserts/maps.png")}
            style={{ width: "100%" }}
            alt="map"
          />
          <p
            style={{ textAlign: "center", fontSize: "30px", fontWeight: "600" }}
          >
            Technical brillences with{" "}
            <span style={{ color: "#0082bc" }}>global avaliblity</span>
          </p>
        </Col>
        <Col
          md={5}
          className="d-flex align-items-center justify-content-center"
        >
          <p
            style={{
              fontSize: "50px",
              color: "#008ac7",
              bottom: "0",
              width: "100%",
              textAlign: "left",
              padding: "10px",
            }}
          >
            <b>GLOBAL</b>
            <br />
            <b>AVAILABILITY</b>
            <br />
            <span
              style={{
                backgroundColor: "#008ac7",
                color: "white",
                padding: "8px",
                borderRadius: "15px",
                fontSize: "50px",
                lineHeight: "210%",
              }}
            >
              32
            </span>{" "}
            countries <br />
            <span
              style={{
                backgroundColor: "#008ac7",
                color: "white",
                padding: "8px",
                borderRadius: "15px",
                fontSize: "50px",
                lineHeight: "20%",
              }}
            >
              40
            </span>{" "}
            Locations{" "}
          </p>
        </Col>
      </Row>

      <Container></Container>

      <br />

      <Formd />
      <Footer />
    </div>
  );
};

export default HomePage;
