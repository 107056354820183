import { Container } from '@material-ui/core';
import React, { useEffect } from 'react';
import { Col, Row } from 'react-bootstrap';
import {  NavLink, useLocation } from 'react-router-dom';
import "../style/ProductSlider.css";
import Navbar from './Navbar';
import Footer from '../components/Footer'


const ProductSlider = () => {

   
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  let rating = 5
  return (
   <>
    <div style={{backgroundColor:'#0082bc'}}>
<center>

   
<h1 style={{color:'#ffff',padding:'20px', letterSpacing:'2px'}}>1) Countertop water ionizers</h1>

      <div id="gridproduct">
           <div>
            <NavLink to="/nmp5">
               <div className='cardtm d-flex justify-content-center align-items-center' style={{backgroundColor:'#EBECEE'}}>
               <Row  style={{width:'100%', borderRadius:'10px'}}>
                  <Col md={6} className='d-flex justify-content-center align-items-center' style={{borderRadius:'10px'}}> <img src={require('../asserts/products/NMP5.png')} alt="" width={'100%'}  className="slimg"/> </Col>
                  <Col md={6} className='d-flex justify-content-center align-items-center'>
                  <div>
                      <div className='ptitle'>Tyent <span style={{color:'#55981f', fontSize:'30px',fontWeight:'900'}}> <b>NMP - 5</b></span> </div>
                      <div className="grid-container" style={{display: 'grid', width:'100%',  gridTemplateColumns: 'auto auto',padding:'1px'}}>
                        <div className="gri">pH range</div>
                        <div className="gri1"> 2.5 - 11.5</div>
                        <div className="gri">ORP Range</div>  
                        <div className="gri1"> up to - 850<sup>*</sup></div>
                        <div className="gri">Generate</div>
                        <div className="gri1"> 7 types of water levels.</div>  
                        <div className="gri">Surface area</div>
                        <div className="gri1"> 52.9*5 = 264.5.</div>
                        <div className="gri d-flex">Warranty</div>
                        <div className="gri1"> 3 years on machine & 15 years on plates.</div> 
                     </div>
                     </div>
            </Col>    
            </Row>
            </div>
            </NavLink>   
           </div>


           <div><NavLink to="/nmp7" >
            <div className='cardtm d-flex justify-content-center align-items-center'  style={{backgroundColor:'#EBECEE'}}>
            <Row style={{width:'100%'}}>
                     <Col md={6} className='d-flex justify-content-center align-items-center'> <img src={require('../asserts/products/NMP5.png')} alt="" width={'100%'}  className="slimg"/></Col>
                      <Col  md={6} className='d-flex justify-content-center align-items-center'>
                        <div>
                        <div className='ptitle'>Tyent <span style={{color:'#55981f', fontSize:'30px',fontWeight:'900'}}> <b>NMP - 7</b></span> </div>
                      <div className="grid-container" style={{display: 'grid', gridTemplateColumns: 'auto auto',padding: '5px'}}>
                      <div class="gri">pH range</div>
                           <div className="gri1">&nbsp; 2.5 - 11.5</div>
                           <div className="gri">ORP Range</div>  
                           <div className="gri1">&nbsp; up to - 950<sup>*</sup></div>
                           <div className="gri">Generate</div>
                           <div className="gri1">&nbsp; 7 types of water levels.</div>  
                           <div className="gri">Surface area</div>
                           <div className="gri1">&nbsp; 52.9*7 = 370.3.</div>
                           <div className="gri">Warranty</div>
                           <div className="gri1">&nbsp; 3 years on machine & 15 years on plates.</div>
                       </div>
                       </div>
            </Col>
            </Row>
            </div>
            </NavLink>   
           </div>
           <div><NavLink to="/nmp9" >
            <div className='cardtm d-flex justify-content-center align-items-center' style={{backgroundColor:'#EBECEE'}}>
            <Row style={{width:'100%'}}>
               <Col md={6} className='d-flex justify-content-center align-items-center'><img src={require('../asserts/products/NMP 9.png')} alt="" width={'100%'}  className="slimg"/></Col>
                      <Col md={6} className='d-flex justify-content-center align-items-center'>
                        <div>
                      <div className='ptitle'>Tyent <span style={{color:'#55981f', fontSize:'30px',fontWeight:'900'}}> <b>NMP - 9</b> </span> </div>  
                   
                      <div className="grid-container" style={{display: 'grid', gridTemplateColumns: 'auto auto',padding: '5px'}}>
                      <div class="gri">pH range</div>
                              <div className="gri1">&nbsp; 2.5 - 11.5</div>
                              <div className="gri">ORP Range</div>  
                              <div className="gri1">&nbsp; up to - 1050<sup>*</sup></div>
                              <div className="gri">Produces</div>
                              <div className="gri1">&nbsp; 7 types of water.</div>  
                              <div className="gri">Surface area</div>
                              <div className="gri1">&nbsp; 52.9*9 = 476.1.</div>
                              <div className="gri">Warranty:</div>
                              <div className="gri1">&nbsp; 3 years on machine & 15 years on plates.</div>
                       </div>
                       </div>
            </Col>
            </Row>
            </div>
            </NavLink>   
           </div>
           <div><NavLink to="/nmp11">
            <div className='cardtm d-flex justify-content-center align-items-center' style={{backgroundColor:'#EBECEE'}}>
            <Row style={{width:'100%'}}>
               <Col md={6} className='d-flex justify-content-center align-items-center'> <img src={require('../asserts/products/NMP 9.png')} alt="" width={'100%'}  className="slimg"/></Col>
                     <Col md={6} className='d-flex justify-content-center align-items-center'>
                        <div>
                      <div className='ptitle'>Tyent <span style={{color:'#55981f', fontSize:'30px',fontWeight:'900'}}> <b>NMP - 11</b></span></div>
                      <div className="grid-container" style={{display: 'grid', gridTemplateColumns: 'auto auto',padding: '5px'}}>
                      <div class="gri">pH range</div>
                                 <div className="gri1">&nbsp; 2.5 - 11.5</div>
                                 <div className="gri">ORP Range</div>  
                                 <div className="gri1">&nbsp; up to -1200<sup>*</sup></div>
                                 <div className="gri">Produces</div>
                                 <div className="gri1">&nbsp; 7 types of water.</div>  
                                 <div className="gri">Surface area</div>
                                 <div className="gri1">&nbsp; 52.9*11 = 581.9.</div>
                                 <div className="gri">Warranty</div>
                                 <div className="gri1">&nbsp; 3 years on machine & 15 years on plates.</div>
                       </div>
                       </div>
            </Col>
            </Row>
            </div>
            </NavLink>   
           </div>
           <div><NavLink to="/ace">
            <div className='cardtm d-flex justify-content-center align-items-center' style={{backgroundColor:'#EBECEE'}}>
            <Row style={{width:'100%'}}>
               <Col md={6} className='d-flex justify-content-center align-items-center'><img src={require('../asserts/products/Ace 13.png')} alt="" width={'100%'} className="slimg"/></Col>
                <Col md={6} className='d-flex justify-content-center align-items-center'>
                  <div>      
                      <div className='ptitle'>Tyent <span style={{color:'#55981f', fontSize:'30px',fontWeight:'900'}}> <b>ACE - 13</b></span> </div>
                      <div className="grid-container" style={{display: 'grid', gridTemplateColumns: 'auto auto',padding: '5px'}}>
                      <div class="gri">pH range</div>
                              <div className="gri1">&nbsp; 2.5 - 11.5</div>
                              <div className="gri">ORP Range</div>  
                              <div className="gri1">&nbsp; up to -1250<sup>*</sup></div>
                              <div className="gri">Produces</div>
                              <div className="gri1">&nbsp; 8 types of water.</div>  
                              <div className="gri">Surface area</div>
                              <div className="gri1">&nbsp; 52.9*13 = 687.7.</div>
                              <div className="gri">Warranty</div>
                              <div className="gri1">&nbsp; 3 years on machine & 15 years on plates.</div>
                       </div>
                       </div>
            </Col>
            </Row>
            </div>
           
            </NavLink>
            </div>
           <div><NavLink to="/hybrid"><div className='cardtm' style={{backgroundColor:'#EBECEE'}}>
            <Row style={{width:'100%'}}>
               <Col md={6} className='d-flex justify-content-center align-items-center'><img src={require('../asserts/products/Hybrid.png')} alt="" width={'100%'} className="slimg"/></Col>
                      <Col md={6} className='d-flex justify-content-center align-items-center'>
                        <div>
                      <div className='ptitle'>Tyent <span style={{color:'#55981f', fontSize:'30px',fontWeight:'900'}}> <b>H2-Hybrid</b> </span></div>
                      <div className="grid-container" style={{display: 'grid', gridTemplateColumns: 'auto auto',padding: '5px'}}>
                      <div class="gri">pH range</div>
                              <div className="gri1"> 2.5 - 11.5</div>
                              <div className="gri">ORP Range</div>  
                              <div className="gri1"> up to -1250<sup>*</sup></div>
                              <div className="gri">Produces</div>
                              <div className="gri1"> 8 types of water.</div>  
                              <div className="gri">Technology</div>
                              <div className="gri1"> Patented Twin cell technology</div>
                              <div className="gri">Warranty</div>
                              <div className="gri1"> 3 years on machine & 15 years on plates.</div>
                       </div>
                       </div>
                  
            </Col>
            </Row>
            </div>  
            </NavLink>
            </div>
           </div>
         
               <h1 style={{color:'#ffff',padding:'20px', letterSpacing:'2px'}}>2)under-Counter water ionizers</h1>
              
            <div id="gridproduct">

           <div>
            <NavLink to="/uce"> <div className='cardtm' style={{backgroundColor:'#EBECEE'}}>
            <Row  style={{width:'100%'}}>
               <Col md={6} className='d-flex justify-content-center align-items-center'> <img src={require('../asserts/products/Uce.webp')} alt="" width={'100%'} className="slimg"/></Col>
                     <Col md={6} className='d-flex justify-content-center align-items-center'>
                        <div>
                      <div className='ptitle'>Tyent <span style={{color:'#55981f', fontSize:'30px',fontWeight:'900'}}> <b>UCE - 9 PLUS</b> </span> </div>
                      <div className="grid-container" style={{display: 'grid', gridTemplateColumns: 'auto auto',padding: '5px'}}>
                      <div class="gri">pH range</div>
                           <div className="gri1"> 2.5-11.5</div>
                           <div className="gri">ORP Range</div>  
                           <div className="gri1"> up to -1050</div>
                           <div className="gri">Produces</div>
                           <div className="gri1"> 8 types of water.</div>  
                           <div className="gri">Surface area</div>
                           <div className="gri1"> 52.9*9 = 476.1.</div>
                           <div className="gri">Warranty</div>
                           <div className="gri1"> 3 years on machine & 15 years on plates.</div>
                       </div>
                       </div>
            </Col>
            </Row>
            </div>
            </NavLink>
            </div>
            <div>

            <NavLink to="/uce11" > <div className='cardtm' style={{backgroundColor:'#EBECEE'}}>
               <Row  style={{width:'100%'}}>
                  <Col md={6} className='d-flex justify-content-center align-items-center'><img src={require('../asserts/products/Uce.webp')} alt="" width={'100%'} className="slimg"/></Col>
                      <Col md={6} className='d-flex justify-content-center align-items-center'>
                      <div>
                      <div className='ptitle'>Tyent <span style={{color:'#55981f', fontSize:'30px',fontWeight:'900'}}><b>UCE - 11 PLUS</b></span>   </div>
                      
                      <div className="grid-container" style={{display: 'grid', gridTemplateColumns: 'auto auto',padding: '5px'}}>
                      <div class="gri">pH range</div>
                              <div className="gri1"> 2.5-11.5</div>
                              <div className="gri">ORP Range</div>  
                              <div className="gri1"> up to -1200</div>
                              <div className="gri">Produces</div>
                              <div className="gri1"> 8 types of water.</div>  
                              <div className="gri">Surface area</div>
                              <div className="gri1"> 52.9*11 = 581.9.</div>
                              <div className="gri">Warranty</div>
                              <div className="gri1"> 3 years on machine & 15 years on plates.</div>
                       </div>
                       </div>
                  
            </Col>
            </Row>
            </div>
            </NavLink>
            </div>
            <div>
            <NavLink to="/uce13" > <div className='cardtm' style={{backgroundColor:'#EBECEE'}}>
               <Row  style={{width:'100%'}}>
                  <Col md={6} className='d-flex justify-content-center align-items-center'><img src={require('../asserts/products/Uce.webp')} alt="" width={'100%'} className="slimg"/></Col>
                      <Col md={6} className='d-flex justify-content-center align-items-center'>
                        <div>
                      <div className='ptitle'>Tyent <span style={{color:'#55981f', fontSize:'30px',fontWeight:'900'}}><b>UCE - 13 PLUS</b></span> </div>
                      <div>
                      <div className="grid-container" style={{display: 'grid', gridTemplateColumns: 'auto auto',padding: '5px'}}>
                      <div class="gri">pH range</div>
                              <div className="gri1"> 2.5-11.5</div>
                              <div className="gri">ORP Range</div>  
                              <div className="gri1"> up to -1250</div>
                              <div className="gri">Produces</div>
                              <div className="gri1"> 8 types of water.</div>  
                              <div className="gri">Surface area</div>
                              <div className="gri1"> 52.9*13 = 687.7.</div>
                              <div className="gri">Warranty</div>
                              <div className="gri1"> 3 years on machine & 15 years on plates.</div>
                       </div>
                       </div>
                       </div>
                               
            </Col>
            </Row>
            </div>
            </NavLink>
           </div>
           </div>

           <h1 style={{color:'#ffff',padding:'20px', letterSpacing:'2px'}}>3)  Sterilizing water generator</h1>
              
              <div id="gridproduct">
  
             <div>
              <NavLink to="/watergen"> <div className='cardtm' style={{backgroundColor:'#EBECEE'}}>
              <Row  className='d-flex justify-content-center align-items-center'  style={{width:'100%'}}>
                 <Col md={6} > <img src={require('../asserts/watergen/watergen.png')} alt="" width={'100%'}  className="slimg"/></Col>
                       <Col md={6} className='d-flex justify-content-center align-items-center'>
                          <div>
                        <div className='ptitle'>Tyent <span style={{color:'#55981f', fontSize:'30px',fontWeight:'900'}}> <b>TIE-N15WR</b> </span> </div>
                        <div className="grid-container" style={{display: 'grid', gridTemplateColumns: 'auto auto',padding: '5px'}}>
                        <div class="gri">Product name	</div>
                             <div className="gri12">Sterilizing water generator</div>
                             <div className="gri">Model</div>  
                             <div className="gri12"> TIE-N15WR</div>
                             <div className="gri">Size(mm)</div>
                             <div className="gri12"> 545x225x470</div>  
                             <div className="gri">Places to use</div>
                             <div className="gri12"> School, military, hospital, hotel restaurant, cafe</div>
                             <div className="gri">Warranty</div>
                             <div className="gri12"> 3 years on machine & 15 years on plates.</div>
                         </div>
                         </div>
              </Col>
              </Row>
              </div>
              </NavLink>
              </div>
              <div>
           </div>
           </div>   

           <h1 style={{color:'#ffff',padding:'20px', letterSpacing:'2px'}}>4)  Soap</h1>


           
           <div id="gridproduct">
  
  <div>
   <NavLink to="/soap"> <div className='cardtm' style={{backgroundColor:'#EBECEE'}}>
   <Row  style={{width:'100%'}}>
      <Col> <img src={require('../asserts/products/soap/tm-x-beauty-soap.jpg')} alt="" width={'50%'}/></Col>
   </Row>
   </div>
   </NavLink>
   </div>
   <div>
</div>
</div>   
                     
      </center>

      <Footer />
      
    </div>

    </>    
    
  )
}

export default ProductSlider
