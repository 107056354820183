import React from "react";
import { MDBFooter, MDBIcon } from "mdb-react-ui-kit";
import { Row, Col } from "react-grid-system";
import "../style/Footer.css";
import {
  FaThLarge,
  FaChartBar,
  FaHandHoldingHeart,
  FaBoxTissue,
  FaHandshake,
  FaFacebook,
  FaTwitter,
  FaGoogle,
  FaInstagram,
  FaLinkedinIn,
  FaPhoneAlt,
  FaMapMarkerAlt,
  FaEnvelopeOpenText,
  FaAddressBook,
  FaUsers,
  FaList,
  FaTint,
  FaHeadset,
  FaYoutube,
} from "react-icons/fa";

import { NavLink } from "react-router-dom";
import { Container } from "react-bootstrap";

const Footer = () => {
  return (
    <MDBFooter className="footer">
      <section
        className="d-flex justify-content-center justify-content-between p-4 border-bottom"
        style={{ backgroundColor: "rgba(0,0,0,0.2)" }}
      >
        <div className="d-none d-md-block">
          <span>
            <h3 style={{ color: "#0082bc" }}>
              <b>Get connected with us on social networks&nbsp;:</b>
            </h3>
          </span>
        </div>

        <div>
          <a
            href="https://www.youtube.com/@TyentIndia3170"
            target="_blank"
            rel="noreferrer"
            className="me-4 text-reset"
          >
            <FaYoutube size={40} style={{ color: "#FF0000" }} />
          </a>
          <a
            href="https://www.facebook.com/TyentIndiaOfficial/"
            target="_blank"
            rel="noreferrer"
            className="me-4 text-reset"
          >
            <FaFacebook size={30} style={{ color: "#3b5998" }} />
          </a>
          <a
            href="https://www.instagram.com/tyentindia/"
            className="me-4 text-reset"
          >
            <FaInstagram size={30} style={{ color: "#EA0C5F" }} />
          </a>
          <a
            href="https://www.linkedin.com/company/tyent-india1/"
            className="me-4 text-reset"
          >
            <FaLinkedinIn size={30} style={{ color: "#0077B5" }} />
          </a>
        </div>
      </section>

      <Container fluid className="text-center text-md-start">
        <Row style={{ padding: "20px" }}>
          <Col
            md={3}
            className="d-flex justify-content-center align-items-center"
          >
            <div>
              <h6 className="text-uppercase fw-bold">
                <MDBIcon icon="gem" />
                <img
                  src={require("../asserts/logo.png")}
                  alt="Logo"
                  style={{ width: "70%" }}
                />
              </h6>
              <p
                style={{
                  textAlign: "justify",
                  width: "90%",
                  textJustify: "inter-word",
                }}
              >
                Tyent ionizers, the world's most advanced water ionizers with
                all certifications like GMP, KFDA & ISO 13485 which are
                available in more than 86+ counties.
              </p>
            </div>
          </Col>

          <Col md={3} className="d-flex align-items-left">
            <div>
              <h3
                className="text-uppercase fw-bold mb-4"
                style={{ color: "#0082bc", fontSize: "20px" }}
              >
                Useful links
              </h3>
              <p>
                <NavLink to="/" className="nav-linkf" style={{ color: "#000" }}>
                  <FaThLarge size={15} />
                  <span>&nbsp; Home</span>
                </NavLink>
              </p>
              <p>
                <NavLink
                  to="/Story"
                  className="nav-linkf"
                  style={{ color: "#000" }}
                >
                  <FaChartBar size={15} />
                  <span>&nbsp; Our Story</span>
                </NavLink>
              </p>
              <p>
                <NavLink
                  to="/water"
                  className="nav-linkf"
                  style={{ color: "#000" }}
                >
                  <FaHandHoldingHeart size={15} />
                  <span>&nbsp; Why Water Ionizer</span>
                </NavLink>
              </p>
              <p>
                <NavLink
                  to="/whytent"
                  className="nav-linkf"
                  style={{ color: "#000" }}
                >
                  <FaHandshake size={15} />
                  <span>&nbsp; Why Tyent</span>
                </NavLink>
              </p>
              <p>
                <NavLink
                  to="/testmonial"
                  className="nav-linkf"
                  style={{ color: "#000" }}
                >
                  <FaBoxTissue size={15} />
                  <span>&nbsp; Testmonials</span>
                </NavLink>
              </p>
              <p>
                <NavLink
                  to="/benfits"
                  className="nav-linkf"
                  style={{ color: "#000" }}
                >
                  <FaAddressBook size={15} />
                  <span>&nbsp; Health benefits</span>
                </NavLink>
              </p>
            </div>
          </Col>

          <Col md={3} className="d-flex align-items-left">
            <div>
              <h3
                className="text-uppercase fw-bold mb-4"
                style={{
                  paddingLeft: "10px",
                  color: "#0082bc",
                  fontSize: "20px",
                }}
              >
                Products
              </h3>
              <p>
                <NavLink to="/Products" className="nav-linkf" style={{ textDecoration: "none" }}>
                  <div
                    className="nav-linkf d-flex align-items-left"
                    style={{ padding: "0px", color: "#000" }}
                  >
                    <FaTint size={15} />
                    &nbsp;&nbsp; CounterTop Water Ionizers
                  </div>
                </NavLink>
              </p>
              <p>
                <NavLink to="/Products"  className="nav-linkf" style={{ textDecoration: "none" }}>
                  <div
                    className="nav-linkfr d-flex align-items-left"
                    style={{ padding: "0px", color: "#000" }}
                  >
                    <FaTint size={15} />
                    &nbsp;&nbsp; Under-Counter Water Ionizers
                  </div>
                </NavLink>
              </p>
              <p>
                <NavLink to="/plates" style={{ textDecoration: "none" }}>
                  <div
                    className="nav-linkfr d-flex align-items-left"
                    style={{ padding: "0px", color: "#000" }}
                  >
                    <FaTint size={15} />
                    &nbsp;&nbsp; Tyent's Solid hybrid mesh plates.
                  </div>
                </NavLink>
              </p>
              <p>
                <NavLink to="/smps" style={{ textDecoration: "none" }}>
                  <div
                    className="nav-linkfr d-flex align-items-left"
                    style={{ padding: "0px", color: "#000" }}
                  >
                    <FaTint size={15} />
                    &nbsp;&nbsp; Patented SMPS PLUS Power supply.
                  </div>
                </NavLink>
              </p>
              <p>
                <NavLink to="/replacement" style={{ textDecoration: "none" }}>
                  <div
                    className="nav-linkfr d-flex align-items-left"
                    style={{ padding: "0px", color: "#000" }}
                  >
                    <FaTint size={15} />
                    &nbsp;&nbsp; Replacement Filters
                  </div>
                </NavLink>
              </p>
              <p>
                <NavLink to="/tyentvskangen" style={{ textDecoration: "none" }}>
                  <div
                    className="nav-linkfr d-flex align-items-left"
                    style={{ padding: "0px", color: "#000" }}
                  >
                    <FaTint size={15} />
                    &nbsp;&nbsp; Tyent vs kangen
                  </div>
                </NavLink>
              </p>
            </div>
          </Col>

          <Col md={3} className="d-flex align-items-left">
            <div>
              <h3
                className="text-uppercase fw-bold mb-4"
                style={{ color: "#0082bc", fontSize: "20px" }}
              >
                Contact
              </h3>
              <p>
                <div
                  className="nav-linkfr d-flex align-items-left"
                  style={{ color: "#000" }}
                >
                  <FaPhoneAlt size={15} />
                  &nbsp;&nbsp; +91 91824 14181
                </div>
              </p>
              <p>
                <div
                  className="nav-linkfr d-flex align-items-left"
                  style={{ color: "#000" }}
                >
                  <FaEnvelopeOpenText size={15} />
                  &nbsp;&nbsp; Contact@medilightindia.com
                </div>
              </p>
              <p>
                <div
                  className="nav-linkfr d-flex align-items-left"
                  style={{ color: "#000" }}
                >
                  <FaEnvelopeOpenText size={15} />
                  &nbsp;&nbsp; Technical@medilightindia.com
                </div>
              </p>
              <p>
                <div
                  className="nav-linkf d-flex align-items-left"
                  style={{ color: "#000" }}
                >
                  <FaMapMarkerAlt size={40} />
                  &nbsp;&nbsp;
                  <div style={{ padding: "5px", textAlign: "justify" }}>
                    Medilight Private Limited, 8-277/45, UBI Colony, Banjara
                    Hills Rd Number 3, Hyderabad, Telangana - 500034.
                    <br />
                    <b>Landmark:</b> &nbsp; Near TV9 news office
                  </div>
                </div>
              </p>
              <p>
                <div className="nav-linkfr" style={{ color: "#000" }}>
                  <FaHeadset size={15} />
                  &nbsp;&nbsp;Customer Care : 912-12-12-555
                </div>
              </p>
            </div>
          </Col>
        </Row>
      </Container>

      <div
        className="text-center p-4"
        style={{
          backgroundColor: "#0082bc",
          textAlign: "center",
          color: "#FFF",
        }}
      >
        © 2021 Copyright:
        <a className="text-reset fw-bold" href="https://www.tyent.co.in/">
          &nbsp; Tyent India
        </a>
      </div>
    </MDBFooter>
  );
};

export default Footer;
