import React from "react";
import { Row, Col } from "react-grid-system";
import Navbar from "../components/Navbar.js";
import "../style/Nmp5.css";
import { Button } from "@material-ui/core";
import { FaPhoneAlt } from "react-icons/fa";
import Footer from "../components/Footer";
import { Container } from "react-bootstrap";

class watgen extends React.Component {
  state = {
    products: [
      {
        src: [
          "https://www.tyent.co.in/pimage/eswg.png",
          "https://www.tyent.co.in/pimage/eswg4.jpg",
        ],
      },
    ],
    index: 0,
  };

  myRef = React.createRef();

  handleTab = (index) => {
    this.setState({ index: index });
    const image = this.myRef.current.children;
    for (let i = 0; i < image.length; i++) {
      image[i].className = image[i].className.replace("active", "");
    }
    image[index].className = "active";
  };

  componentDidMount() {
    const { index } = this.state;
    this.myRef.current.children[index].className = "active";
  }

  render() {
    const { products, index } = this.state;

    return (
      <div className="hbody">
        <div className="home__image">
          <img src={require("../asserts/pro_20.png")} alt="" width="100%" />
        </div>
        <Row>
          <Row>
            <Col
              md={6}
              className="d-flex justify-content-center align-items-center"
            >
              <br />

              <div
                style={{ width: "100%", margin: "30px", position: "relative" }}
              >
                {products.map((item) => (
                  <div className="details" key={item.src}>
                    <div className="d-flex justify-content-center align-items-center">
                      <img src={item.src[index]} alt="" width="50%" />
                    </div>
                    <div
                      className="thumb justify-content-center align-items-center"
                      ref={this.myRef}
                    >
                      {item.src.map((img, index) => (
                        <img
                          src={img}
                          alt=""
                          key={index}
                          onClick={() => this.handleTab(index)}
                        />
                      ))}
                    </div>
                  </div>
                ))}
              </div>
              <br />
            </Col>
            <Col md={6} className="d-flex justify-content-center">
              <div>
                <br />
                <h2
                  style={{
                    color: "#0082bc",
                    textAlign: "center",
                    justifyContent: "center",
                  }}
                >
                  <b>TIE-N15WR </b>
                </h2>
                <br />
                <table id="customers">
                  <tbody style={{ textAlign: "left" }}>
                    <tr>
                      <td>product name </td>
                      <td></td>
                      <td>:&nbsp;Sterilizing water generator</td>
                    </tr>
                    <tr>
                      <td>Model </td>
                      <td></td>
                      <td>:&nbsp;TIE - N15WR</td>
                    </tr>
                    <tr>
                      <td>Purpose of use </td>
                      <td></td>
                      <td>
                        :&nbsp;disinfection and sterilization of food, utensis,
                        and other apparatus
                      </td>
                    </tr>
                    <tr>
                      <td>Places to use</td>
                      <td></td>
                      <td>
                        :&nbsp;School, military, hospital, hotel restaurant,
                        cafe
                      </td>
                    </tr>
                    <tr>
                      <td>Warranty </td>
                      <td></td>
                      <td>:&nbsp;3 Years on machine & 15 years on plates</td>
                    </tr>
                    <tr>
                      <td colSpan="3">
                        <h2
                          style={{
                            color: "#000",
                            textAlign: "left",
                            fontSize: "15px",
                          }}
                        >
                          <b>*Depends on input water quality and Flow rate</b>
                        </h2>
                      </td>
                    </tr>
                    <tr>
                      <td colSpan="3">
                        <h2
                          style={{
                            color: "#000",
                            textAlign: "left",
                            fontSize: "15px",
                          }}
                        >
                          <b>
                            (No use of external chemicals, salts, or artificial
                            enhancers)
                          </b>
                        </h2>
                      </td>
                    </tr>
                  </tbody>
                </table>

                <Button
                  variant="contained"
                  style={{
                    color: "#FFF",
                    float: "right",
                    backgroundColor: "#0082bc",
                    textTransform: "none",
                    margin: "20px",
                  }}
                >
                  <FaPhoneAlt />
                  &nbsp;&nbsp;Call To Order
                </Button>
              </div>
            </Col>
          </Row>
          <br />

          <div style={{ margin: "30px" }}>
            <center>
              <div>
                <img
                  src={require("../asserts/watergen/pro_01.png")}
                  alt=""
                  width="60%"
                />
              </div>

              <p
                style={{
                  color: "#0082bc",
                  fontSize: "30px",
                  fontWeight: "600",
                }}
              >
                1) Generative Principle & Purpose Of use
              </p>
              <Row>
                <Col
                  md={8}
                  className="d-flex"
                  style={{
                    justifyContent: "center",
                    textAlign: "center",
                    alignItems: "center",
                  }}
                >
                  <p
                    style={{
                      fontSize: "20px",
                      fontWeight: "400",
                      margin: "20px",
                    }}
                  >
                    <b>Gushing sterilized water just from Water and Salt !</b>
                    <br />
                    <ul style={{ textAlign: "left" }}>
                      <br />
                      <li>
                        <b>Convenient hygiene management</b>
                      </li>
                      <p>
                        Process that does not require dilution increases the
                        production efficiency.
                        <br />
                        The automatic consecutive system allows you to use the
                        water like a regular tap water. <br />
                        The one-touch control provides easy and safe use.
                      </p>
                      <br />
                      <li>
                        <b>Clean Quality assurance</b>
                      </li>
                      <p>
                        Quick sterilization and minimal residue is a great
                        advantage. - Ideal for food sterilization without
                        affecting the taste and the smell of the food.
                      </p>
                      <br />
                      <li>
                        <b>Safe Working environment</b>
                      </li>
                      <p>
                        The electrolysis of water and salt does not affect the
                        environment. When the residue is contacted with organic
                        matter after the use, the active chlorine decreases and
                        naturally dissolute.
                      </p>
                    </ul>
                  </p>
                </Col>
                <Col md={4}>
                  <img
                    src={require("../asserts/watergen/pro_02.png")}
                    alt=""
                    style={{ width: "100%" }}
                  />
                </Col>
              </Row>
              <img
                src={require("../asserts/watergen/pro_03.png")}
                alt=""
                style={{ width: "100%" }}
              />
            </center>

            <br />
            <p
              style={{
                textAlign: "center",
                fontSize: "40px",
                fontWeight: "600",
              }}
            >
              Generative Principle
            </p>
            <br />
            <Row>
              <Col
                md={4}
                className="d-flex"
                style={{
                  justifyContent: "center",
                  textAlign: "center",
                  alignItems: "center",
                }}
              >
                <p>Sterilized Water Producer</p>
              </Col>
              <Col md={8}>
                <p>
                  <span style={{ color: "#0082bc" }}>
                    is a device that electrolyzes water and small quantity of
                    salt to produce an environment friendly sterilized water
                    (sodium hypochlorite)
                  </span>
                  <br />
                  The sterilized water produced is popularly used in various
                  places and areas that require sterilization and disinfection
                  as it has strong sterilizing power especially in virus and
                  germs found in the hospital and the resistant bacteria. This
                  is an innovative HACCP product that considers both sanitation
                  and safety and solves the problem of hard handling of the
                  original chemical based toxic sterilizer, dilution, and
                  ruining the environment.
                </p>
              </Col>
            </Row>
            <img
              src={require("../asserts/watergen/pro13_1_1_img01.png")}
              alt=""
              style={{ width: "100%" }}
            />
            <center>
              <Container>
                <img
                  src={require("../asserts/watergen/sterilization.png")}
                  alt=""
                  style={{ width: "100%" }}
                />
              </Container>
            </center>
            <img
              src={require("../asserts/watergen/pro_11.png")}
              alt=""
              style={{ width: "100%" }}
            />
            <br />
            <br />
            <center>
              <Container>
                <img
                  src={require("../asserts/watergen/pro13_1_1_img04.png")}
                  alt=""
                  style={{ width: "80%" }}
                />
              </Container>
            </center>
            <p
              style={{
                textAlign: "center",
                fontSize: "40px",
                fontWeight: "600",
              }}
            >
              Product Features
            </p>

            <Row>
              <Col
                md={8}
                className="d-flex"
                style={{
                  justifyContent: "center",
                  textAlign: "center",
                  alignItems: "center",
                }}
              >
                <p>
                  <span style={{ fontSize: "50px", fontWeight: "600" }}>
                    Dual Electrolyzer System
                  </span>{" "}
                  <br />
                  (Patent technology 10-1556371) <br />
                  <br />
                  <span
                    style={{
                      fontSize: "20px",
                      fontWeight: "600",
                      color: "#0082bc",
                    }}
                  >
                    The electrolyzer is the key component that generates
                    sterilized water.
                  </span>{" "}
                  <br />
                  <br />A more stable available chlorine concentration is
                  maintained through a two-step electrolysis process.
                </p>
              </Col>
              <Col md={4}>
                <img
                  src={require("../asserts/watergen/pro_13.png")}
                  alt=""
                  style={{ width: "100%" }}
                />
              </Col>
            </Row>

            <Row>
              <Col md={4}>
                <img
                  src={require("../asserts/watergen/pro_14.png")}
                  alt=""
                  style={{ width: "100%" }}
                />
              </Col>
              <Col
                md={8}
                className="d-flex"
                style={{
                  justifyContent: "center",
                  textAlign: "center",
                  alignItems: "center",
                }}
              >
                <p>
                  <span style={{ fontSize: "50px", fontWeight: "600" }}>
                    ONE TOUCH automatic flush
                  </span>{" "}
                  <br />
                  The touch sense system allows you to easily get the water
                  going or stop. You can see the status of the button you chose.
                  <br />
                  <br />
                  <span style={{ fontSize: "50px", fontWeight: "600" }}>
                    Production of different concentrations of active chlorine
                  </span>{" "}
                  <br />
                  The product automatically produces the sterilized water based
                  on its use (100/200/1000ppm)
                </p>
              </Col>
            </Row>
            <Row>
              <Col
                md={8}
                className="d-flex"
                style={{
                  justifyContent: "center",
                  textAlign: "center",
                  alignItems: "center",
                }}
              >
                <p>
                  <span style={{ fontSize: "50px", fontWeight: "600" }}>
                    1000ppm generation function for high concentration
                  </span>{" "}
                  <br />
                </p>
              </Col>
              <Col md={4}>
                <img
                  src={require("../asserts/watergen/pro_15.png")}
                  alt=""
                  style={{ width: "100%" }}
                />
              </Col>
            </Row>
            <Row>
              <Col md={4}>
                <img
                  src={require("../asserts/watergen/pro_16.png")}
                  alt=""
                  style={{ width: "100%" }}
                />
              </Col>
              <Col
                md={8}
                className="d-flex"
                style={{
                  justifyContent: "center",
                  textAlign: "center",
                  alignItems: "center",
                }}
              >
                <p>
                  <span style={{ fontSize: "50px", fontWeight: "600" }}>
                    SMPS Power method
                  </span>{" "}
                  <br />
                  SMPS power supply method provides steady power. <br />
                  <br />
                  <span style={{ fontSize: "50px", fontWeight: "600" }}>
                    Safe self check function
                  </span>{" "}
                  <br />
                  The safety function has improved by displaying and alerting
                  the unmet condition of the electrolytes (salt) and flow.
                </p>
              </Col>
            </Row>

            <Row>
              <Col
                md={8}
                className="d-flex"
                style={{
                  justifyContent: "center",
                  textAlign: "center",
                  alignItems: "center",
                }}
              >
                <p>
                  <span style={{ fontSize: "50px", fontWeight: "600" }}>
                    TPE material salt container & integral structure
                  </span>{" "}
                  <br />
                  he salt container made of environmentally friendly TPE
                  material is safe and durable.
                  <br />
                  <br />
                  It has high space utilization due to the integral structure of
                  salt container. No separate space is required for the salt
                  container installation.
                </p>
              </Col>
              <Col md={4}>
                <img
                  src={require("../asserts/watergen/pro_17.png")}
                  alt=""
                  style={{ width: "100%" }}
                />
              </Col>
            </Row>

            <Row>
              <Col md={4}>
                <img
                  src={require("../asserts/watergen/pro_18.png")}
                  alt=""
                  style={{ width: "100%" }}
                />
              </Col>
              <Col
                md={8}
                className="d-flex"
                style={{
                  justifyContent: "center",
                  textAlign: "center",
                  alignItems: "center",
                }}
              >
                <p>
                  <span style={{ fontSize: "50px", fontWeight: "600" }}>
                    Salt drain valve fitted
                  </span>{" "}
                  <br />
                  Convenient for cleaning the salt container after not using it
                  for long time.
                </p>
              </Col>
            </Row>

            <Row>
              <Col
                md={8}
                className="d-flex"
                style={{
                  justifyContent: "center",
                  textAlign: "center",
                  alignItems: "center",
                }}
              >
                <p>
                  <span style={{ fontSize: "50px", fontWeight: "600" }}>
                    Available with Wall-mounted
                  </span>{" "}
                  <br />
                  The wall-mounted design provides effective use of space.
                </p>
              </Col>
              <Col md={4}>
                <img
                  src={require("../asserts/watergen/pro_19.png")}
                  alt=""
                  style={{ width: "100%" }}
                />
              </Col>
            </Row>
          </div>
        </Row>
        <Footer />
      </div>
    );
  }
}
export default watgen;
