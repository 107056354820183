import React from "react";
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";
import "../style/Hybrid.css";
import { Row, Col } from "react-grid-system";
import { Button } from "@material-ui/core";
import { FaPhoneAlt } from "react-icons/fa";
import Acetable from "../components/Acetable.js";
import { Container } from "@material-ui/core";
import Smartfeatures from "../components/Smartfeatures";
import { FaTint } from "react-icons/fa";

class hybrid extends React.Component {
  state = {
    products: [
      {
        src: [
          "https://firebasestorage.googleapis.com/v0/b/tyent-fa17c.appspot.com/o/hybrid%2FHybride1.webp?alt=media&token=996c8fe7-189b-4bd0-b439-4003cdf525a3",
          "https://firebasestorage.googleapis.com/v0/b/tyent-fa17c.appspot.com/o/hybrid%2FHybride2.webp?alt=media&token=28f2a08e-5ec7-4342-ad39-63f982d2814a",
          "https://firebasestorage.googleapis.com/v0/b/tyent-fa17c.appspot.com/o/hybrid%2FHybride3.webp?alt=media&token=68d9a27f-6a8d-4f6e-8951-d5c69ead7d98",
        ],
      },
    ],
    index: 0,
  };

  myRef = React.createRef();

  handleTab = (index) => {
    this.setState({ index: index });
    const image = this.myRef.current.children;
    for (let i = 0; i < image.length; i++) {
      image[i].className = image[i].className.replace("active", "");
    }
    image[index].className = "active";
  };

  componentDidMount() {
    const { index } = this.state;
    this.myRef.current.children[index].className = "active";
  }

  render() {
    const { products, index } = this.state;

    return (
      <div className="hbody">
        <div className="home__image">
          <img src={require("../asserts/b5.webp")} alt="" width="100%" />
        </div>
        <Container>
          <Row
            className="d-flex justify-content-center"
            style={{ borderRadius: "8px" }}
          >
            <Col
              md={6}
              className="d-flex justify-content-center align-items-center"
            >
              <br />
              <div
                style={{ width: "100%", margin: "30px", position: "relative" }}
              >
                {products.map((item) => (
                  <div className="details" key={item.src}>
                    <div className="d-flex justify-content-center align-items-center">
                      <img
                        src={item.src[index]}
                        alt=""
                        width="100%"
                        className="pi"
                      />
                    </div>
                    <div
                      className="thumb justify-content-center align-items-center"
                      ref={this.myRef}
                    >
                      {item.src.map((img, index) => (
                        <img
                          src={img}
                          alt=""
                          key={index}
                          onClick={() => this.handleTab(index)}
                        />
                      ))}
                    </div>
                  </div>
                ))}
              </div>
              <br />
            </Col>
            <Col md={6} className="d-flex">
              <div>
                <br />
                <h2
                  style={{
                    color: "#0082bc",
                    textAlign: "left",
                    fontSize: "30px",
                    justifyContent: "center",
                  }}
                >
                  <b>Tyent H2 Hybrid(H20 water) </b>
                </h2>
                <h3
                  style={{
                    color: "#000",
                    textAlign: "left",
                    fontSize: "40px",
                    justifyContent: "center",
                  }}
                >
                  M.R.P :{" "}
                  <span style={{ color: "#ff0000" }}> ₹ 3,67,000/-</span>
                </h3>
                <br />
                <table id="customers">
                  <tbody style={{ textAlign: "left" }}>
                    <tr>
                      <td>pH range </td>
                      <td></td>
                      <td>:&nbsp;2.5 - 11.5*</td>
                    </tr>
                    <tr>
                      <td>ORP Range </td>
                      <td></td>
                      <td>:&nbsp;up to - 1300*</td>
                    </tr>
                    <tr>
                      <td>Generates </td>
                      <td></td>
                      <td>:&nbsp;8 types of water levels.</td>
                    </tr>
                    <tr>
                      <td>Technology </td>
                      <td></td>
                      <td>:&nbsp;Twin cell Technology</td>
                    </tr>
                    <tr>
                      <td>Warranty </td>
                      <td></td>
                      <td>:&nbsp;3 Years on machine & 15 years on plates</td>
                    </tr>
                    <tr>
                      <td colSpan="3">
                        <h2
                          style={{
                            color: "#000",
                            textAlign: "left",
                            fontSize: "15px",
                          }}
                        >
                          <b>*Depends on input water quality and Flow rate</b>
                        </h2>
                      </td>
                    </tr>
                    <tr>
                      <td colSpan="3">
                        <h2
                          style={{
                            color: "#000",
                            textAlign: "left",
                            fontSize: "15px",
                          }}
                        >
                          <b>
                            (No use of external chemicals, salts, or artificial
                            enhancers)
                          </b>
                        </h2>
                      </td>
                    </tr>
                  </tbody>
                </table>

                <Button
                  variant="contained"
                  style={{
                    color: "#FFF",
                    float: "right",
                    backgroundColor: "#0082bc",
                    textTransform: "none",
                    margin: "20px",
                  }}
                >
                  <FaPhoneAlt />
                  &nbsp;&nbsp;Call To Order
                </Button>
              </div>
            </Col>
          </Row>
        </Container>

        <div>
          <center>
            <div>
              <p id="color">
                <strong>
                  <b>Colour Full Display</b>
                </strong>
              </p>
            </div>
            <br />

            <Container>
              <div className="gridh">
                <div className="boxh">
                  <img
                    src={require("../asserts/hybrid/1.png")}
                    alt=""
                    width="100%"
                  />
                </div>
                <div className="boxh">
                  <img
                    src={require("../asserts/hybrid/2.png")}
                    alt=""
                    width="100%"
                  />
                </div>
                <div className="boxh">
                  <img
                    src={require("../asserts/hybrid/3.png")}
                    alt=""
                    width="100%"
                  />
                </div>
                <div className="boxh">
                  <img
                    src={require("../asserts/hybrid/4.png")}
                    alt=""
                    width="100%"
                  />
                </div>
                <div className="boxh">
                  <img
                    src={require("../asserts/hybrid/5.png")}
                    alt=""
                    width="100%"
                  />
                </div>
                <div className="boxh">
                  <img
                    src={require("../asserts/hybrid/6.png")}
                    alt=""
                    width="100%"
                  />
                </div>
                <div className="boxh">
                  <img
                    src={require("../asserts/hybrid/7.png")}
                    alt=""
                    width="100%"
                  />
                </div>
                <div className="boxh">
                  <img
                    src={require("../asserts/hybrid/8.png")}
                    alt=""
                    width="100%"
                  />
                </div>
              </div>
            </Container>

            <Row>
              <Col md={8}>
                <img
                  src={require("../asserts/hybrid/hybrid02.png")}
                  alt=""
                  width="100%"
                />
              </Col>
              <Col
                md={4}
                className="d-flex justify-content-center align-items-center"
              >
                <div className="oneT">
                  <hr
                    style={{
                      height: "15vh",
                      width: ".5vw",
                      borderWidth: "0",
                      backgroundColor: "#0082bc",
                    }}
                  />
                  <p
                    style={{
                      color: "#000",
                      fontSize: "20px",
                      textAlign: "left",
                      padding: "10px",
                    }}
                  >
                    <span
                      style={{
                        fontSize: "40px",
                        color: "#60B45A",
                        fontWeight: "600",
                      }}
                    >
                      ECO MODE SETTING <br />
                    </span>
                    Save energy by enabling Eco Mode on Your ionizer to only
                    display the current time after non-use!
                  </p>
                </div>
              </Col>
            </Row>
            <Row>
              <Col
                md={6}
                className="d-flex justify-content-center align-items-center"
              >
                <img
                  src={require("../asserts/hybrid/hybrid04.png")}
                  alt=""
                  width="100%"
                />
              </Col>
              <Col
                md={6}
                className="d-flex justify-content-center align-items-center"
              >
                <div className="oneT">
                  <hr
                    style={{
                      height: "15vh",
                      width: ".5vw",
                      borderWidth: "0",
                      backgroundColor: "#0082bc",
                    }}
                  />
                  <p
                    style={{
                      textAlign: "left",
                      padding: "10px",
                      fontSize: "20px",
                    }}
                  >
                    <span
                      style={{
                        color: "#0082bc",
                        fontSize: "40px",
                        fontWeight: "600",
                      }}
                    >
                      Real time Filter Usage Indication
                    </span>
                    <br />
                    The display indicates the filter usage on the LCD screen
                    allowing <br />
                    you to Know exactly when it's time to change your filters!
                  </p>
                </div>
              </Col>
            </Row>
            <Row>
              <Col
                md={6}
                className="d-flex align-items-center justify-content-center"
              >
                <img
                  src={require("../asserts/hybrid/hybrid03.png")}
                  alt=""
                  width="50%"
                />
              </Col>
              <Col
                md={6}
                className="d-flex align-items-center justify-content-center"
              >
                <div>
                  <Row>
                    <Col></Col>
                  </Row>
                  <div className="oneT">
                    <hr
                      style={{
                        height: "35vh",
                        width: ".5vw",
                        borderWidth: "0",
                        backgroundColor: "#0082bc",
                      }}
                    />
                    <p
                      style={{
                        textAlign: "left",
                        padding: "10px",
                        fontSize: "20px",
                      }}
                    >
                      <span
                        style={{
                          color: "#0082bc",
                          fontSize: "40px",
                          fontWeight: "600",
                        }}
                      >
                        Fixed quantity discharge function
                      </span>
                      <br />
                      <Row>
                        <Col className="d-flex justify-content-center align-items-center">
                          <div className="box1">
                            <p
                              style={{
                                fontSize: "30px",
                                fontWeight: "600",
                                textAlign: "center",
                                alignItems: "center",
                              }}
                            >
                              1.5L
                            </p>
                          </div>
                        </Col>
                        <Col className="d-flex justify-content-center align-items-center">
                          <div className="box1">
                            <p style={{ fontSize: "30px", fontWeight: "600" }}>
                              1.0L
                            </p>
                          </div>
                        </Col>
                        <Col className="d-flex justify-content-center align-items-center">
                          <div className="box1">
                            <p style={{ fontSize: "30px", fontWeight: "600" }}>
                              1.0L
                            </p>
                          </div>
                        </Col>
                      </Row>
                      <br />
                      For your convenience, the ionizerhas the ability to
                      dispense a predetermined <br />
                      amount of water and then stop. presets include 0.5L, 1.0L,
                      & 1.5L.
                    </p>
                  </div>
                </div>
              </Col>
            </Row>

            <div className="rows" style={{ marginTop: "10px" }}>
              <p
                style={{
                  textAlign: "center",
                  fontSize: "40px",
                  fontWeight: "600",
                  padding: "40px",
                }}
              >
                {" "}
                Composition of Product{" "}
              </p>
              <div className="columns">
                <img
                  src={require("../asserts/hybrid/h1.png")}
                  alt=""
                  style={{ width: "50%" }}
                />
                <br />
                <img
                  src={require("../asserts/hybrid/h2.png")}
                  alt=""
                  style={{ width: "50%" }}
                />
              </div>
              <div className="columns">
                <img
                  src={require("../asserts/hybrid/hybrid.png")}
                  alt=""
                  width="100%"
                />
              </div>
              <div className="columns">
                <img
                  src={require("../asserts/hybrid/h3.png")}
                  alt=""
                  style={{ width: "50%" }}
                />
                <br />
                <img
                  src={require("../asserts/hybrid/h4.png")}
                  alt=""
                  style={{ width: "50%" }}
                />
              </div>
            </div>
            <Row>
              <Col
                md={4}
                className="d-flex justify-content-center text-align-center"
              >
                <img
                  src={require("../asserts/hybrid/filter.png")}
                  alt=""
                  width="100%"
                />
              </Col>
              <Col
                md={8}
                className="d-flex justify-content-center align-items-center"
              >
                <p style={{ fontSize: "22px", textAlign: "left" }}>
                  <span
                    style={{
                      color: "#0082bc",
                      fontSize: "25px",
                      fontWeight: "800",
                    }}
                  >
                    Secondary Electrolysis System
                  </span>
                  <br />
                  it is the secondary electrolysis system through the ionized
                  water electrolytic cell after the primary electrolytic cell
                  through the hydrogen water electrolytic cell. in this way,
                  Hydrogen Alkaline water with high concentration of hydrogen is
                  generated in real time.
                  <br />
                  <br />
                  <span
                    style={{
                      color: "#0082bc",
                      fontSize: "25px",
                      fontWeight: "800",
                    }}
                  >
                    ROUND Mesh ionized water electrode maximizes electrolytic
                    efficiency & ROUND CROSS edge Hydrogen water electrode
                    maximizes hydrogen generation rate
                  </span>
                  <br />
                  Round cross edge type electrode design considering the
                  characteristics of electricity is very high in functional
                  water generation efficiency.
                  <br />
                  <br />
                  <span
                    style={{
                      color: "#0082bc",
                      fontSize: "25px",
                      fontWeight: "800",
                    }}
                  >
                    Using the largest size of electrodes covered by platinum
                    coated titanium mesh
                  </span>
                  <br />
                  Compare to other products, it is 3-10 times bigger.
                  <br />
                  <br />
                  <span
                    style={{
                      color: "#0082bc",
                      fontSize: "25px",
                      fontWeight: "800",
                    }}
                  >
                    4 Way valve type automatic outflow switching type
                    electroylzer
                  </span>
                  <br />
                  Acidic and alkaline are not mixed during polarity switching
                  and cleaning o Wastewater, except for hydrogen water, is
                  discharged only  through waste Water drain.
                  <br />
                  <br />
                  <span
                    style={{
                      color: "#0082bc",
                      fontSize: "25px",
                      fontWeight: "800",
                    }}
                  >
                    Electrolyzer module with anti-scale through polarity
                    switching
                  </span>
                  <br />
                  Scaling of the cathode electrode, which is the biggest problem
                  of Hydrogen Electrolyzer, is basically blocked.
                  <br />
                  <br />
                  <span
                    style={{
                      color: "#0082bc",
                      fontSize: "25px",
                      fontWeight: "800",
                    }}
                  >
                    Automatic CLEANING Function
                  </span>
                  <br />
                  The inside of the Electrolyzer can be periodically cleaned
                  according to program control
                </p>
              </Col>
            </Row>
            <Smartfeatures />
            <Row>
              <Col md={4}>
                <img
                  src={require("../asserts/ace/spec.png")}
                  alt=""
                  style={{
                    display: "block",
                    marginLeft: "auto",
                    marginRight: "auto",
                  }}
                  width="60%"
                />
              </Col>
              <Col md={8}>
                <div style={{ margin: "20px" }}>
                  <Acetable />
                </div>
              </Col>
            </Row>
            <Row style={{ backgroundColor: "#FFF" }}>
              <Col md={6}>
                <img
                  src={require("../asserts/nmp/drain.png")}
                  alt=""
                  width="80%"
                />
              </Col>

              <Col
                md={6}
                className="d-flex"
                style={{ backgroundColor: "#FFF" }}
              >
                <div className="oneT">
                  <hr
                    style={{
                      height: "15vh",
                      width: ".5vw",
                      borderWidth: "0",
                      backgroundColor: "#0082bc",
                    }}
                  />
                  <p
                    style={{
                      margin: "20px",
                      fontSize: "21px",
                      textAlign: "left",
                    }}
                  >
                    <span
                      style={{
                        color: "#0082bc",
                        fontSize: "40px",
                        fontWeight: "600",
                      }}
                    >
                      Drain Faucet
                    </span>{" "}
                    <br />
                    It comes with Drain faucet that can be easily installed to
                    anywhere on the sink
                  </p>
                </div>
              </Col>
            </Row>

            <Container>
              <p
                style={{
                  fontSize: "50px",
                  fontWeight: "600",
                  textAlign: "center",
                  padding: "10px",
                  color: "#0082bc",
                }}
              >
                Tyent H2 HYBRID Specification's
              </p>
              <div className="d-flex justify-content-center align-iteams-center">
                <table className="spec" style={{ color: "#0082bc" }}>
                  <tr>
                    <td>
                      <b>Model</b>{" "}
                    </td>
                    <td>Tyent H2 Hybrid.</td>
                  </tr>
                  <tr>
                    <td>
                      <b>Electrode Materials</b>{" "}
                    </td>
                    <td>:&nbsp;platinum and Titanium</td>
                  </tr>
                  <tr>
                    <td>
                      <b>pH Range</b>{" "}
                    </td>
                    <td>
                      :&nbsp;2.5 - 11.5.<sup>*</sup>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <b>ORP Range</b>{" "}
                    </td>
                    <td>
                      :&nbsp;up to - 1250.<sup>*</sup>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <b>Generates</b>{" "}
                    </td>
                    <td>:&nbsp;8 types of water levels.</td>
                  </tr>
                  <tr>
                    <td>
                      <b>pH Levels</b>{" "}
                    </td>
                    <td>
                      :&nbsp;3 alkaline, 2 Acidic, 1 neutral, & 1 Turbo (Strong
                      alkaline 11.5pH).
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <b> plate Surface area</b>{" "}
                    </td>
                    <td>:&nbsp;52.9*13 = 687.7 sq inches.</td>
                  </tr>
                  <tr>
                    <td>
                      <b>
                        Water Generating
                        Capacity&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      </b>{" "}
                    </td>
                    <td>:&nbsp;1.5-3.0L/min.</td>
                  </tr>
                  <tr>
                    <td>
                      <b>Filters Structure</b>{" "}
                    </td>
                    <td>:&nbsp;2 filters( .01 micron NSF & ANSI certified).</td>
                  </tr>
                  <tr>
                    <td>
                      <b>Filter Materials</b>{" "}
                    </td>
                    <td>
                      :&nbsp;Carbon Block, UF, TM ceramics, Membrance & Calcium
                      sulfite.
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <b>Filter Capacity</b>{" "}
                    </td>
                    <td>:&nbsp;8000L.</td>
                  </tr>
                  <tr>
                    <td>
                      <b>Dimension(mm)</b>{" "}
                    </td>
                    <td>:&nbsp;300(W) * 135(D) * 355(H).</td>
                  </tr>
                  <tr>
                    <td>
                      <b>Weight(kg)</b>{" "}
                    </td>
                    <td>:&nbsp;5.2</td>
                  </tr>
                  <tr>
                    <td>
                      <b>Cleaning type</b>{" "}
                    </td>
                    <td>:&nbsp;Automatic (PAST technology).</td>
                  </tr>

                  <tr>
                    <td>
                      <b>Display Type</b>{" "}
                    </td>
                    <td>:&nbsp;One-touch LCD.</td>
                  </tr>

                  <tr>
                    <td>
                      <b>power Supply</b>{" "}
                    </td>
                    <td>:&nbsp;Patented SMPS PLUS power supply.</td>
                  </tr>

                  <tr>
                    <td>
                      <b>Power consumption</b>{" "}
                    </td>
                    <td>:&nbsp; Max 220W.</td>
                  </tr>

                  <tr>
                    <td>
                      <b>Operating Voltage Range</b>{" "}
                    </td>
                    <td>:&nbsp; 200 - 240V, 50-60HZ.</td>
                  </tr>

                  <tr>
                    <td>
                      <b>Voice Confirmation</b>{" "}
                    </td>
                    <td>:&nbsp; Yes</td>
                  </tr>

                  <tr>
                    <td>
                      <b>Input water parameters required</b>
                    </td>
                    <td>
                      {" "}
                      &nbsp; Input water TDS: 90 - 160.
                      <br /> : Input water pH: 7pH. <br /> &nbsp; Input water
                      Pressure: 5-7L/Min.{" "}
                    </td>
                  </tr>

                  <tr>
                    <td>
                      <b>Warranty</b>{" "}
                    </td>
                    <td>
                      :&nbsp;3 Years on ionizer & 15 years on Electrodes/plates.
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <b>General Services</b>{" "}
                    </td>
                    <td>
                      :&nbsp;3 Services per year up to 3 years in Warranty
                      peroid (PAN INDIA)
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <b>Safety Function</b>{" "}
                    </td>
                    <td>
                      :&nbsp;Water temperature sensor, Supplying water sensor,
                      Overheating protection sensor & Error indication sensor.
                    </td>
                  </tr>
                </table>
              </div>
            </Container>

            <hr
              style={{
                height: "1px",
                width: "100%",
                borderWidth: "0",
                backgroundColor: "#0082bc",
              }}
            />
            <br />
            <p
              style={{
                fontSize: "35px",
                textAlign: "center",
                fontWeight: "600",
                color: "#0082bc",
              }}
            >
              Tyent's NSF & EPA certified Extreme .01 Micron filtration
            </p>

            <Row>
              <Col
                md={6}
                className="d-flex justify-content-center align-iteams-center"
              >
                <img
                  src={require("../asserts/filter.png")}
                  alt=""
                  width="65%"
                />
              </Col>
              <Col md={6} className="d-flex">
                <div className="oneT">
                  <div>
                    <p style={{ fontSize: "25px", textAlign: "left" }}>
                      <FaTint size={30} color="#0082bc" />
                      &nbsp;Tyent filters are EPA & NSF Certified.
                      <br />
                      <br />
                      <FaTint size={30} color="#0082bc" />
                      &nbsp;Tyent Extreme .01 micron filters remove even the
                      toughest <br />{" "}
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;contaminants
                      such as arsenic, chromium VI.etc
                      <br />
                      <br />
                      <FaTint size={30} color="#0082bc" />
                      &nbsp;Tyent filters can remove heavy metals,
                      pharmaceuticals, heavy
                      <br />{" "}
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;contamination,
                      Micro-organisms, Bacteria, Viruses, algae, fungi.
                    </p>
                  </div>
                </div>
              </Col>
            </Row>

            <Row>
              <Col md={6}>
                <Container>
                  <div className="cardfil">
                    <p style={{ fontSize: "20px", textAlign: "justify" }}>
                      <span
                        style={{
                          fontSize: "40px",
                          fontWeight: "600",
                          color: "#0082bc",
                        }}
                      >
                        Carbon block{" "}
                      </span>
                      <br />
                      Activated carbon was made into a block form has a larger
                      surfer area than partice -type activated carbon. it has a
                      very high adsorption rate. it works to filter residual
                      chlorine, organic compounds and heavy metals by activated
                      adsorption
                    </p>
                  </div>

                  <br />
                  <div className="cardfil">
                    <p style={{ fontSize: "20px", textAlign: "justify" }}>
                      <span
                        style={{
                          fontSize: "40px",
                          fontWeight: "600",
                          color: "#0082bc",
                        }}
                      >
                        Membrane{" "}
                      </span>
                      <br />
                      Pretretment filtration function for filtering various
                      precipitates contained in raw water
                    </p>
                  </div>

                  <br />
                  <div className="cardfil">
                    <p style={{ fontSize: "20px", textAlign: "justify" }}>
                      <span
                        style={{
                          fontSize: "40px",
                          fontWeight: "600",
                          color: "#0082bc",
                        }}
                      >
                        Calcium sulfite
                      </span>{" "}
                      <br />
                      Removes residual chlorine completely by oxidation
                      reduction reaction
                    </p>
                  </div>
                </Container>
              </Col>
              <Col md={6}>
                <Container>
                  <div className="cardfil">
                    <p
                      style={{
                        fontSize: "20px",
                        textAlign: "justify",
                        margin: "20px",
                      }}
                    >
                      <span
                        style={{
                          fontSize: "40px",
                          fontWeight: "600",
                          color: "#0082bc",
                        }}
                      >
                        TM Ceramic
                      </span>
                      <br />
                      The ceramic were made by mixing the high-emissivity far
                      infrarred minerals containing potassium(k), sodium(Na) and
                      calcium(Ca) with TM and TM - X stock solutions. These
                      multi functional ceramic have antioxidants, antimicrobial
                      and far infared Radiation
                    </p>
                  </div>

                  <br />
                  <div className="cardfil">
                    <p
                      style={{
                        fontSize: "20px",
                        textAlign: "justify",
                        margin: "20px",
                      }}
                    >
                      <span
                        style={{
                          fontSize: "40px",
                          fontWeight: "600",
                          color: "#0082bc",
                        }}
                      >
                        UF
                      </span>
                      <br />
                      Many Fine Pores on thr filter surface remove containment
                      from the water . I removes bad elements such as bacteria
                      viruses and particulates but does not remove substance
                      that are benefical to our body, such as minerals
                    </p>
                  </div>
                </Container>
              </Col>
            </Row>
          </center>
        </div>
        <Footer />
      </div>
    );
  }
}
export default hybrid;
