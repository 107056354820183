import React, { useEffect } from "react";
import Navbar from "../components/Navbar";
import { Row, Col } from "react-grid-system";
import "../style/TeamPage.css";
import { Container } from "@material-ui/core";
import Footer from "../components/Footer";
import Formd from "../components/Formd";
import PHvalueRange from "../components/PHvalueRange";
import { useLocation } from "react-router-dom";
import ReactGA from "react-ga";
import { Helmet } from "react-helmet";

const TeamPage = () => {
  useEffect(() => {
    ReactGA.initialize("G-FP7N65QWQ6");
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return (
    <div>
      <Helmet>
        <title>
          What is Water Ionizer? Advantages with Tyent Water Ionizer
        </title>

        <meta
          name="description"
          content="What is Water Ionizer? Advantages with Tyent Water Ionizer"
        />
      </Helmet>
      <Row style={{ marginTop: "20px" }}>
        <Col md={6}>
          <img
            src={require("../asserts/waterbody.png")}
            alt=""
            width={"100%"}
          />
        </Col>
        <Col md={6}>
          <p style={{ textAlign: "justify", textJustify: "inter-word" }}>
            <span
              style={{ color: "#0082bc", fontWeight: "900", fontSize: "30px" }}
            >
              Is the majority of the human body is
            </span>
            <br />
            <span
              style={{ color: "#88bd2f", fontWeight: "900", fontSize: "80px" }}
            >
              {" "}
              WATER ?{" "}
            </span>
            <br />
            <span
              style={{
                fontSize: "20px",
                color: "#000",
                textAlign: "justify",
                textJustify: "inter-word",
              }}
            >
              According to H.H. Mitchell, Journal of Biological Chemistry 158.
              Water plays an important role in our body, it constitutes 60 to
              70% of our body weight. On average, the body of an adult human
              being contains 60% water. Most of the water in the human body is
              contained inside our cells. In fact, our billions of cells must
              have water to live. The total amount of water in our body is found
              in three main locations: within our cells (two-thirds of the
              water), in the space between our cells and in our blood (one-third
              of the water). For example, a 70-kg man is made up of about 42L of
              total water.
              <br />
              <br />
              So it's not much of a stretch to say that "the basis of vitality,
              Health & long life is water." If you want to feel good and be at
              your optimal health level, one should drink water with essential
              minerals on a daily basis. But not just any type of Water
            </span>
          </p>
        </Col>
      </Row>
      <br />
      <Container>
        <br />
        <p style={{ textAlign: "center", fontSize: "60px", fontWeight: "900" }}>
          Problems with <span style={{ color: "#0082bc" }}> Bottled Water</span>
        </p>
        <br />
        <Row>
          <Col md={4} className="d-flex justify-content-center">
            <img
              src={require("../asserts/i1.jpg")}
              alt=""
              style={{ width: "70%", borderRadius: "5%" }}
            />
          </Col>
          <Col md={8}>
            <p
              style={{
                textAlign: "justify",
                textJustify: "inter-word",
                fontSize: "20px",
                color: "#000",
              }}
            >
              Bottled water is not necessarily healthier than tap water and yet
              it costs much more! Although associated with healthy water,
              bottled water is not guaranteed to be any healthier than tap
              water. Often the only difference is the added minerals which have
              no proven health benefits. Bottled water can still create health
              problems as it is slightly acidic, which may affect your body's pH
              balance.
              <br />
              <br />
              Testing from the NRDC found that over 30% of bottled water samples
              showed signs of contamination. That means it may not be safer than
              some tap water options that are available in the India and around
              the world. Finally, the environmental impact caused by the
              production and disposal of plastic bottles is harmful to our
              ecosystem.
            </p>
          </Col>
        </Row>
      </Container>
      <br />
      <div className="buy-cont">
        <p>Your Health Is An Investment. Not An Expense.</p>
      </div>
      <br />
      <Container>
        <br />
        <p style={{ textAlign: "center", fontSize: "60px", fontWeight: "900" }}>
          Problems with <span style={{ color: "#0082bc" }}>RO Water</span>
        </p>
        <br />
        <Row>
          <Col md={8}>
            <p
              style={{
                textAlign: "justify",
                textJustify: "inter-word",
                fontSize: "20px",
                color: "#000",
              }}
            >
              Reverse osmosis is a water purification technology that uses a
              semi-permeable membrane to remove ions, molecules and larger
              particles from drinking water. In reverse osmosis, all the natural
              use-full minerals which are present in the water will be removed
              along all the bacteria, virus and other hard minerals such
              chlorine and Florine. These minerals not only provide good taste,
              they also serve a vital function in the body's system. When
              stripped of these minerals, water can be unhealthy.
              <br />
              <br />
              The problem with this process is that dangerous chemicals like
              pesticides, herbicides, and chlorine are molecularly smaller than
              water and can pass freely through the filter. Also, 2-3 gallons of
              water are wasted for every gallon of purified water produced.
            </p>
          </Col>
          <Col md={4} className="d-flex justify-content-center">
            <img
              src={require("../asserts/i2.jpg")}
              alt=""
              style={{ width: "70%", borderRadius: "5%" }}
            />
          </Col>
        </Row>
      </Container>
      <br />
      <div className="buy-cont">
        <p>Healthy water is the worlds first and foremost medicine</p>
      </div>

      <br />
      <Container>
        <br />
        <p style={{ textAlign: "center", fontSize: "58px", fontWeight: "900" }}>
          What is <span style={{ color: "#0082bc" }}>Water ionizer ?</span>
        </p>
        <br />
        <Row>
          <Col md={4} className="d-flex justify-content-center">
            <img
              src={require("../asserts/i3.jpg")}
              alt=""
              style={{ width: "70%", borderRadius: "5%" }}
            />
          </Col>
          <Col md={8}>
            <p
              style={{
                textAlign: "justify",
                textJustify: "inter-word",
                fontSize: "20px",
                color: "#000",
              }}
            >
              A water ionizer is a home appliance which claims to raise the pH
              of drinking water by using electrolysis to separate the incoming
              water stream into acidic and alkaline components. The alkaline
              stream of the treated water is called alkaline water. Health
              experts claim that consumption of alkaline water results in a
              variety of health benefits.
              <br />
              <br />
              Water ionizers produce alkaline water containing dissolved
              selective anti-oxidant called molecular hydrogen at the cathode
              (the negative electrode) and acidic water at the anode (the
              positive electrode). Water ionizers are plugged into the AC
              outlet, the power is then transformed to direct current (DC) so
              that electrolysis can be performed.
            </p>
          </Col>
        </Row>
      </Container>
      <br />
      <div className="buy-cont">
        <p>Healthy water is the worlds first and foremost medicine</p>
      </div>
      <br />
      <Container>
        <br />
        <p style={{ textAlign: "center", fontSize: "60px", fontWeight: "900" }}>
          Hydrogen an{" "}
          <span style={{ color: "#0082bc" }}>Emerging Medical Gas</span>
        </p>
        <br />
        <Row>
          <Col md={8}>
            <p
              style={{
                textAlign: "justify",
                textJustify: "inter-word",
                fontSize: "20px",
                color: "#000",
              }}
            >
              Although the research is early, the 1000+ scientific articles
              suggest that H2 has therapeutic potential in over 170 different
              human and animal disease models, and essentially every organ of
              the human body. Molecular hydrogen (H2) or diatomic hydrogen is a
              tasteless, odorless, flammable gas.
              <br />
              <br />
              H2 reduces oxidative stress and improves redox homeostasis partly
              mediated via the Nrf2 pathway, which regulates levels of
              glutathione, superoxide dismutase, catalase, etc. <sup>*</sup>H2,
              like other gaseous-signaling molecules (e.g. NO, CO, H2 S),
              modulates signal transduction, protein phosphorylation, and gene
              expression, which provides its anti-inflammatory, anti-allergy,
              and anti-apoptotic protective effects.
            </p>
          </Col>
          <Col md={4} className="d-flex justify-content-center">
            <img
              src={require("../asserts/Hydrogen_Tile.png")}
              alt=""
              style={{ width: "70%", borderRadius: "5%" }}
            />
          </Col>
        </Row>
      </Container>
      <div className="buy-cont">
        <p>Healthy water ! Healthy life !</p>
      </div>

      <Container>
        <br />
        <p style={{ textAlign: "center", fontSize: "60px", fontWeight: "900" }}>
          Advantages with{" "}
          <span style={{ color: "#0082bc" }}>Tyent®️ ionized wate</span>
        </p>
        <br />
        <Row>
          <Col md={3}>
            <img
              src={require("../asserts/i4.jpg")}
              alt=""
              style={{ width: "100%", borderRadius: "5%" }}
            />
          </Col>
          <Col md={9}>
            <p
              style={{
                textAlign: "justify",
                textJustify: "inter-word",
                fontSize: "20px",
                color: "#000",
              }}
            >
              Ionized hydrogen rich alkaline Water will contain thousands of
              tiny bubbles when it pours out of a Tyent water ionizer. Those
              bubbles are molecular hydrogen, which is the antioxidant property
              of ionized alkaline water. Molecular hydrogen is the most fragile
              aspect of ionized water, lasting only a maximum of 18-24 hours,
              which is why it is important to consume ionized water when it is
              fresh out of your Tyent water ionizer. Ionized hydrogen rich
              alkaline Water is rich in minerals such as calcium, magnesium,
              Potassium & silica which are most required for human body
              metabolism.
              <br />
              <br />
              Ionized hydrogen rich alkaline Water helps in boosting immunity,
              anti-inflammatory properties, anti-allergy properties,
              anti-apoptotic protective, anti-aging properties, colon-cleansing
              properties, weight loss, cancer resistance & and other detoxifying
              properties. Although the research says 1000+ scientific articles
              suggest that H2 has therapeutic potential in over 170 different
              human and animal disease models, and essentially every organ of
              the human body.
            </p>
          </Col>
        </Row>
      </Container>

      <Container></Container>

      <br />
      <Formd />
      <Footer />
    </div>
  );
};

export default TeamPage;
