import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import Contact from "./Pages/ContactPage.js";
import Home from "./Pages/HomePage.js";
import Story from "./Pages/StoryPage.js";
import Team from "./Pages/TeamPage.js";
import TestmonialsPage from "./Pages/TestmonialsPage.js";
import FoFpage from "./Pages/FoFPages.js";
import WhytentPage from "./Pages/WhytentPage.js";
import BenfitsPage from "./Pages/BenfitsPage.js";
import Nmp5 from "./Products/nmp5.js";
import Nmp7 from "./Products/nmp7.js";
import Nmp9 from "./Products/nmp9.js";
import Nmp11 from "./Products/nmp11.js";
import Uce from "./Products/uce.js";
import Uce11 from "./Products/uce11.js";
import Uce13 from "./Products/uce13.js";
import Ace from "./Products/ace.js";
import Watergen from "./Products/watgen.js";
import ProductsPage from "./Pages/ProductsPage.js";
import Hybrid from "./Products/hybrid.js";
import { FloatingWhatsApp } from "react-floating-whatsapp";
import Fab from "@material-ui/core/Fab";
import { FaPhoneAlt } from "react-icons/fa";
import ProductSlider from "./components/ProductSlider.js";
import "./style/index.css";
import Appbar from "./components/Appbar.js";
import Plates from "./Pages/Plates.js";
import TyentvsKangen from "./Pages/TyentvsKangen.js";
import Replacement from "./Pages/Replacement.js";
import Smps from "./Pages/Smps.js";
import Soap from "./Products/soap.js";
import CertificatePage from "./Pages/CertificatePage.js";
import { useEffect } from "react";
import ReactGA from "react-ga";
import GoogleAnalytics from "./components/GoogleAnalytics.js";

function App() {
  useEffect(() => {
    <GoogleAnalytics />;
  }, []);

  function CallComponent() {
    window.location = "tel:+919182414181";
  }

  return (
    <div>
      <Appbar />
      <div
        style={{
          position: "fixed",
          bottom: "40px",
          left: "40px",
          zIndex: "99",
        }}
      >
        <Fab aria-label="add" onClick={CallComponent}>
          <FaPhoneAlt fontSize={24} style={{ color: "#0082bc" }} />
        </Fab>
      </div>

      {/* <FloatingWhatsApp
        phoneNumber="+919705731131"
        accountName="Tyent India"
        avatar="https://firebasestorage.googleapis.com/v0/b/demoweb-9a79d.appspot.com/o/Untitled-2.png?alt=media&token=9d7b16d5-b9aa-4122-b253-3e965bec3ab6"
        allowEsc
        allowClickAway
        notification
        notificationSound
      /> */}

      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route exact path="/home" element={<Navigate to="/" replace />} />
        <Route exact path="/story" element={<Story />} />
        <Route exact path="/whytent" element={<WhytentPage />} />
        <Route exact path="/water" element={<Team />} />
        <Route exact path="/testmonial" element={<TestmonialsPage />} />
        <Route exact path="/benfits" element={<BenfitsPage />} />
        <Route exact path="/contact" element={<Contact />} />
        <Route exact path="/Products" element={<ProductSlider />} />

        <Route exact path="*" element={<FoFpage />} />

        <Route exact path="/nmp5" element={<Nmp5 />} />
        <Route exact path="/nmp7" element={<Nmp7 />} />
        <Route exact path="/nmp9" element={<Nmp9 />} />
        <Route exact path="/nmp11" element={<Nmp11 />} />

        <Route exact path="/uce" element={<Uce />} />
        <Route exact path="/uce11" element={<Uce11 />} />
        <Route exact path="/uce13" element={<Uce13 />} />

        <Route exact path="/ace" element={<Ace />} />
        <Route exact path="/hybrid" element={<Hybrid />} />
        <Route exact path="/watergen" element={<Watergen />} />
        <Route exact path="/soap" element={<Soap />} />

        <Route exact path="/tyentvskangen" element={<TyentvsKangen />} />
        <Route exact path="/replacement" element={<Replacement />} />
        <Route exact path="/smps" element={<Smps />} />
        <Route exact path="/plates" element={<Plates />} />

        <Route exact path="/Certifications" element={<CertificatePage />} />
      </Routes>
    </div>
  );
}

export default App;
