import { Container } from "react-bootstrap";
import Footer from "../components/Footer";
import React, { useEffect } from "react";
import ReactGA from "react-ga";
import { useLocation } from "react-router-dom";

const Smps = () => {
  useEffect(() => {
    ReactGA.initialize("G-FP7N65QWQ6");
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);


  
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return (
    <div>
      <img
        src={require("../asserts/SMPS SCROLLING WEB DESIGN.webp")}
        alt="smps banner"
        width={"100%"}
      />
      <Container>
        <h3
          style={{
            color: "#0082bc",
            color: "#0082bc",
            textAlign: "center",
            paddingTop: "20px",
            fontSize: "60px",
            padding: "40px",
          }}
        >
          <b>SMPS PLUS technology :</b>
        </h3>

        <p style={{ textAlign: "justify", color: "#000" }}>
          Tyent is a well-known manufacturer of high-quality water ionizers that
          use advanced technology to deliver the best possible hydrogen-rich
          alkaline water production. Tyent's patented SMPS Plus over the
          transformer and SMPS are key features that make their water ionizers
          stand out from the competition.
        </p>
        <br />
        <p style={{ textAlign: "justify", color: "#000" }}>
          The SMPS Plus technology is designed to provide consistent power to
          the ionizer's plates, ensuring optimal ionization performance at all
          times. With 55 adjustable power settings, Tyent's SMPS Plus allows you
          to customize your alkaline water based on your source water. The power
          and efficiency of the SMPS Plus enable Tyent ionizers to create strong
          alkaline and acidic water without using chemicals, providing a longer
          lifespan. Compared to linear power supplies used by some competitors,
          Tyent's SMPS Plus offers higher efficiency and better performance.
        </p>
        <br />
        <p style={{ textAlign: "justify", color: "#000" }}>
          Tyent's SMPS Plus over transformer is another proprietary technology
          that enhances the efficiency and performance of their SMPS. It
          converts the power from the electrical outlet into a stable and
          consistent current ideal for ionization, preventing fluctuations in
          power and ensuring the ionization process is always running at its
          best. The SMPS Plus over transformer is compact and energy-efficient,
          making Tyent's water ionizers effective and environmentally friendly.
        </p>
        <br />
        <p style={{ textAlign: "justify", color: "#000" }}>
          In addition to the SMPS Plus over the transformer and SMPS, Tyent's
          water ionizers also feature their patented Solid Hybrid Mesh plates,
          which are designed for durability and longevity. These plates resist
          corrosion and scale buildup, requiring less maintenance over time, and
          have a larger surface area, resulting in more efficient ionization.
          The SmartChip technology used by Tyent monitors the plates'
          performance and adjusts the power to ensure optimal ionization
          consistently.
        </p>
        <br />
        <p style={{ textAlign: "justify", color: "#000" }}>
          Overall, Tyent's water ionizers are designed to deliver high-quality
          and sustainable hydrogen-rich alkaline water with more antioxidants
          for better health and well-being. With advanced technologies like the
          SMPS Plus over the transformer and SMPS, Tyent's water ionizers are
          efficient and effective, providing a long-lasting and eco-friendly
          solution for your hydration needs.
        </p>
      </Container>
      <Footer />
    </div>
  );
};

export default Smps;
