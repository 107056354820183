import React, { useEffect } from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { Container } from '@material-ui/core';
import Aos from "aos";
import '../style/Homepage.css';
import{
  FaThumbsUp,
  FaThumbsDown
} from 'react-icons/fa';
import '../style/index.css'




const StyledTableCell = withStyles((theme) => ({

  
 
  body: {
    backgroundColor:'transparent',
    fontSize: 19,
    padding:'10px',
    '&:nth-of-type(3)': {
      backgroundColor: theme.palette.action.hover,
      padding: 0,
      fontSize: 20,
      margin: 0
    },
    "@media (max-width: 1200px)": {
      fontSize: 12,
      '&:nth-of-type(3)': {
        backgroundColor: theme.palette.action.hover,
        padding: 0,
        fontSize: 12,
        margin: 0
      },
      
    }, 
       
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
    
    },
  },
}))(TableRow);



function createData(check, calories, fat, wrong, carbs  ) {

  return {check, calories, fat, wrong, carbs};
}




const rows = [
  createData('',<div className='d-flex justify-content-center'>
              <center>
              <img id='timgs' src={require("../asserts/products/tyent.png")} alt=""/>
              </center>
              </div>,

            <div className='flex'><img src={require("../asserts/vs.png")} width="40%" alt=""/>
            <br/>
             <p1 id='po' data-aos="fade-left"  style={{color:'#000', fontWeight:'500'}}>Think Once</p1>
             <br/>
             <p1 id='po' style={{color:'#000',   fontWeight:'500'}}>before</p1>
             <br/>
             <p2 id='po1' style={{color:'#0082bc', fontWeight:'600'}}>buying</p2>
            </div>, '',

            <div>
              <center>
              <img id='timgs' style={{float:'left'}} src={require("../asserts/products/kangen.png")} alt=""/>
              </center>
              </div>),  

  createData(<FaThumbsUp style={{color:'#23c552'}}/>,'pH range: 11.5 - 2.5*.', 'pH Range', <FaThumbsDown style={{color:'#F84F31'}}/>,'pH range: 11.5 - 2.5*.'),
  createData(<FaThumbsUp style={{color:'#23c552'}}/>,'7 types of water levels', 'Generates',<FaThumbsDown style={{color:'#F84F31'}}/>,'5 types of water levels.'),
  createData(<FaThumbsUp style={{color:'#23c552'}}/>,'Uses advanced Solid hybrid mesh plates with Japanese technology which are Strong, has more surface area with more electrical conductivity.','Plate Material',<FaThumbsDown style={{color:'#F84F31'}}/>,'Uses old outdated Solid plates. Which are Strong, but has less surface area with less electrical conductivity'),
  createData(<FaThumbsUp style={{color:'#23c552'}}/>,'Uses advanced patented SMPS PLUS technology which is more flexible and programmable.','Electronic power supply',<FaThumbsDown style={{color:'#F84F31'}}/>,' Uses outdated Old transformer which causes lot of heat & which is unstable.'),
  createData(<FaThumbsUp style={{color:'#23c552'}}/>,'Has Turbo level to make Super strong 11.5pH & 2.5pH with out using any Enhancers, salts or chemicals. Which is totally safe.','Chemical Free',<FaThumbsDown style={{color:'#F84F31'}}/>,'Uses artificial chemical enhancer to produce Super Strong 11.5pH & 2.5pH. The resultant water is nothing but chemical solution of beach & salt.'),
  createData(<FaThumbsUp style={{color:'#23c552'}}/>,'Built in hydrogen boost feature to produce More concentration of anti-oxidant molecular hydrogen at drinking levels.','More anti-oxidant',<FaThumbsDown style={{color:'#F84F31'}}/>,'No special feature to produce more molecular hydrogen/anti-oxidants at drinking water.'),
  createData(<FaThumbsUp style={{color:'#23c552'}}/>,'Negative ORP Range upto -1250* (Anti-oxidants/Molecular hydrogen).','ORP range',<FaThumbsDown style={{color:'#F84F31'}}/>,'Negative ORP Range upto -850* (Anti-oxidants/Molecular hydrogen).* only.'),
  createData(<FaThumbsUp style={{color:'#23c552'}}/>,'Sales through traditional marketing. (No MLM & No commission involved)','Scale Mode',<FaThumbsDown style={{color:'#F84F31'}}/>,'Sales through MLM or network marketing, Where 49% of the machine price is distributed as commission.'),
  createData(<FaThumbsUp style={{color:'#23c552'}}/>,'3 years Warranty on ionizer & 15 years warranty on plates.','Warranty',<FaThumbsDown style={{color:'#F84F31'}}/>,'5 years limited warranty on ionizer & on plates.'),
  createData(<FaThumbsUp style={{color:'#23c552'}}/>,'Doorstep service is available through out the PAN India .','Service',<FaThumbsDown style={{color:'#F84F31'}}/>,'NO doorstep service.'),
];

const useStyles = makeStyles({
  table: {
    padding: 0,
  },
});


export default function CustomizedTables() {
  const classes = useStyles();
  useEffect(() => {
    Aos.init({
      debounceDelay: 50, 
      duration:2000,
      easing: 'ease',
      once:false,
    });
  }, []);

  return (
    <Container maxWidth="lg">
    <TableContainer component={Paper}>
      <Table className={classes.table} aria-label="customized table">
        <TableHead>
          
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <StyledTableRow >
              <StyledTableCell >{row.check}</StyledTableCell>
              <StyledTableCell style={{width: '30%'}} >{row.calories}</StyledTableCell>
              <StyledTableCell align="center" style={{width: '30%'}}><b>{row.fat}</b></StyledTableCell>
              <StyledTableCell >{row.wrong}</StyledTableCell>
              <StyledTableCell style={{width: '30%'}}>{row.carbs}</StyledTableCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
    </Container>
  );
}
