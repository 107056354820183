import React, { useEffect } from "react";
import Navbar from "../components/Navbar";
import "../style/Testmonials.css";
import ReactPlayer from "react-player";
import Footer from "../components/Footer.js";
import Formd from "../components/Formd";
import { useLocation } from "react-router-dom";
import ReactGA from "react-ga";

const TestmonialsPage = () => {
  useEffect(() => {
    ReactGA.initialize("G-FP7N65QWQ6");
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return (
    <div>
      <div id="gridt">
        <div>
          <div className="cardt">
            <img
              src={require("../asserts/Testmonials/t1.jpeg")}
              alt=""
              className="timg"
            />
          </div>
        </div>
        <div>
          <div className="cardt">
            <img
              src={require("../asserts/Testmonials/t2.jpeg")}
              alt=""
              className="timg"
            />
          </div>
        </div>
        <div>
          <div className="cardt">
            <img
              src={require("../asserts/Testmonials/t3.jpeg")}
              alt=""
              className="timg"
            />
          </div>
        </div>
        <div>
          <div className="cardt">
            <img
              src={require("../asserts/Testmonials/t4.jpeg")}
              alt=""
              className="timg"
            />
          </div>
        </div>
        <div>
          <div className="cardt">
            <img
              src={require("../asserts/Testmonials/t5.jpeg")}
              alt=""
              className="timg"
            />
          </div>
        </div>
        <div>
          <div className="cardt">
            <img
              src={require("../asserts/Testmonials/t6.jpeg")}
              alt=""
              className="timg"
            />
          </div>
        </div>
        <div>
          <div className="cardt">
            <img
              src={require("../asserts/Testmonials/t7.jpeg")}
              alt=""
              className="timg"
            />
          </div>
        </div>
        <div>
          <div className="cardt">
            <img
              src={require("../asserts/Testmonials/t8.jpeg")}
              alt=""
              className="timg"
            />
          </div>
        </div>
        <div>
          <div className="cardt">
            <img
              src={require("../asserts/Testmonials/bs5.jpg")}
              alt=""
              className="timg"
            />
          </div>
        </div>
        <div>
          <div className="cardt">
            <img
              src={require("../asserts/Testmonials/bs7.jpg")}
              alt=""
              className="timg"
            />
          </div>
        </div>

        <div>
          <div className="cardt">
            <img
              src={require("../asserts/Testmonials/bs2.jpg")}
              alt=""
              className="timg"
            />
          </div>
        </div>
        <div>
          <div className="cardt">
            <img
              src={require("../asserts/Testmonials/bs3.jpg")}
              alt=""
              className="timg"
            />
          </div>
        </div>
        <div>
          <div className="cardt">
            <img
              src={require("../asserts/Testmonials/bs4.jpg")}
              alt=""
              className="timg"
            />
          </div>
        </div>
        <div>
          <div className="cardt">
            <img
              src={require("../asserts/Testmonials/bs10.jpg")}
              alt=""
              className="timg"
            />
          </div>
        </div>

        <div>
          <div className="cardt">
            <img
              src={require("../asserts/Testmonials/bs6.jpg")}
              alt=""
              className="timg"
            />
          </div>
        </div>
        <div>
          <div className="cardt">
            <img
              src={require("../asserts/Testmonials/bs1.jpg")}
              alt=""
              className="timg"
            />
          </div>
        </div>

        <div>
          <div className="cardt">
            <img
              src={require("../asserts/Testmonials/bs9.jpg")}
              alt=""
              className="timg"
            />
          </div>
        </div>
        <div>
          <div className="cardt">
            <img
              src={require("../asserts/Testmonials/bs12.jpg")}
              alt=""
              className="timg"
            />
          </div>
        </div>
        <div>
          <div className="cardt">
            <img
              src={require("../asserts/Testmonials/bs8.jpg")}
              alt=""
              className="timg"
            />
          </div>
        </div>

        <div>
          <div className="cardt">
            <img
              src={require("../asserts/Testmonials/bs11.jpg")}
              alt=""
              className="timg"
            />
          </div>
        </div>
      </div>

      <center>
        <br />
        <br />

        <div>
          <p style={{ fontSize: "45px", fontWeight: "999", color: "#0082bc" }}>
            Our elite customers
          </p>
          <br />
          <div id="gridt">
            <div>
              <div>
                <p>
                  Dr. Md. Abdul khuddus, MD, DM, PH.D, ACLS (USA), FNR (
                  glasgow), FCR (Edin), Acute med (London)Senior
                  consultant Neurologist
                </p>
              </div>
            </div>
            <div>
              <div>
                <p>SV Mahija, MBBS DGO, Vijaya laxmi hospitals, Nizamabad.</p>
              </div>
            </div>
            <div>
              <div>
                <p>
                  Dr Sivarama Gandhi Kandregula MBBS DNB ( Medicine ) DNB (
                  Neurology) Consultant Neurologist, Apollo Hospitals, Kakinada.
                </p>
              </div>
            </div>
            <div>
              <div>
                <p>
                  Surya Narayan Reddy, MBBS, DCH, Lakshmi hospital,
                  Madinaguda, Hyd.
                </p>
              </div>
            </div>
            <div>
              <div>
                <p>
                  Dr. Rajaya Lakshmi,MBBS, DGO Gynecologist, Obstetrician, Laxmi
                  hospital, Madinaguda.
                </p>
              </div>
            </div>
            <div>
              <div>
                <p>
                  Dr. Rajashekar, MBBS, Anesthesia, Srikara hospitals, LB Nagar
                </p>
              </div>
            </div>
            <div>
              <div>
                <p>
                  Dr Manojna battina, MBBS MD Pediatrics, Fellowship in
                  pediatric neurology, Consultant pediatric neurologist,Dhanwin
                  child Neuro care, Kakinada
                </p>
              </div>
            </div>
            <div>
              <div>
                <p>
                  Dr Sunitha,MBBS, Gynaecologist Ramakotaiah Childrens
                  Hospital, Guntur
                </p>
              </div>
            </div>
            <div>
              <div>
                <p>Dr Shivasai krishna Neurophysician, Jimper</p>
              </div>
            </div>
            <div>
              <div>
                <p>Dr Naveen bhaskar, Urologist, Brundavanam</p>
              </div>
            </div>
            <div>
              <div>
                <p>Dr Sujatha,MD DGO Vijaya laxmi hospital</p>
              </div>
            </div>
            <div>
              <div>
                <p>Dr Naresh, MDS, Naresh dental clinics</p>
              </div>
            </div>
            <div>
              <div>
                <p>Dr. Balineni sri harsha, Markapuram</p>
              </div>
            </div>
            <div>
              <div>
                <p>
                  Dr. Madhavarao, pediatrician, Madhu Children's
                  Hospital, Markapuram
                </p>
              </div>
            </div>
            <div>
              <div>
                <p>Dr. Ramakrishna Reddy, Sevita nursing home,  Ongole</p>
              </div>
            </div>
            <div>
              <div>
                <p>Dr. Ashok, Jabili hospital, Nandyala.</p>
              </div>
            </div>
            <div>
              <div>
                <p>Actor padmaja Lanka, Bengaluru</p>
              </div>
            </div>
            <div>
              <div>
                <p>Ramalinga Reddy, Transport minister of Karnataka</p>
              </div>
            </div>
          </div>
        </div>

        <br />
        <br />

        <div id="gridv">
          <div>
            <div className="cont">
              <ReactPlayer
                url="https://www.youtube.com/embed/e0D9QnFPMw4"
                width="100%"
                height="302px"
                className="video"
              />
            </div>
          </div>
          <div>
            <div className="cont">
              <ReactPlayer
                url="https://www.youtube.com/embed/LIAhiz4Epyk"
                width="100%"
                height="302px"
                className="video"
              />
            </div>
          </div>
          <div>
            <div className="cont">
              <ReactPlayer
                url="https://www.youtube.com/embed/hcSywpiWZy0"
                width="100%"
                height="302px"
                className="video"
              />
            </div>
          </div>
          <div>
            <div className="cont">
              <ReactPlayer
                url="https://www.youtube.com/embed/5RRNOMSUUtY"
                width="100%"
                height="302px"
                className="video"
              />
            </div>
          </div>
          <div>
            <div className="cont">
              <ReactPlayer
                url="https://www.youtube.com/embed/APr_Z3jhi7g"
                width="100%"
                height="302px"
                className="video"
              />
            </div>
          </div>
          <div>
            <div className="cont">
              <ReactPlayer
                url="https://www.youtube.com/embed/-xXUDPQVQfY"
                width="100%"
                height="302px"
                className="video"
              />
            </div>
          </div>
          <div>
            <div className="cont">
              <ReactPlayer
                url="https://www.youtube.com/embed/7ZFd3HTvDfE"
                width="100%"
                height="302px"
                className="video"
              />
            </div>
          </div>
          <div>
            <div className="cont">
              <ReactPlayer
                url="https://www.youtube.com/embed/EHOzAr4H3nU"
                width="100%"
                height="302px"
                className="video"
              />
            </div>
          </div>
          <div>
            <div className="cont">
              <ReactPlayer
                url="https://www.youtube.com/embed/7Bfbb4uh49s"
                width="100%"
                height="302px"
                className="video"
              />
            </div>
          </div>
        </div>
      </center>
      <br />

      <Formd />
      <Footer />
    </div>
  );
};

export default TestmonialsPage;
