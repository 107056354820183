import React, { useEffect } from "react";
import Footer from "../components/Footer.js";
import Navbar from "../components/Navbar.js";
import "../style/Story.css";
import Formd from "../components/Formd.js";
import { Row, Col } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import ReactGA from "react-ga";

const StoryPage = () => {
  useEffect(() => {
    ReactGA.initialize("G-FP7N65QWQ6");
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);
  return (
    <div>
      <Row>
        <Col md={8} style={{ padding: "30px" }}>
          <div>
            <br />
            <br />
            <h1>
              <span
                style={{
                  fontSize: "45px",
                  fontWeight: "999",
                  color: "#0082bc",
                }}
              >
                <b>Our story</b>
              </span>
            </h1>
            <br />
            <br />
            <p
              style={{
                textIndent: "50px",
                textAlign: "justify",
                lineHeight: 1.4,
                wordSpacing: "5px",
                fontSize: "20px",
                fontWeight: "200",
              }}
            >
              Medilight Pvt Ltd was established in 2017 by Dr. Srinivas Kandula,
              a highly esteemed and well-renowned doctor in Hyderabad. Dr.
              Kandula, known for his exceptional decision-making skills and
              dedication to serving others, founded Medilight Pvt Ltd to promote
              natural health through innovative water solutions.
              <br />
              <br />
              Medilight Pvt Ltd is committed to excellence in the health and
              medical equipment sector. With a strong focus on innovation and
              technology, our company is based in Hyderabad and operates over
              36+ centers across India. Leveraging years of industry expertise,
              our team consists of highly motivated specialists dedicated to
              advancing the field of hydrogen-rich alkaline water ionizers.
              Medilight stands out as a leader in this space, driven by our
              mission to provide customized, high-quality products that foster
              strong, profitable relationships.
            </p>

            <br />

            <h1>
              <span
                style={{
                  fontSize: "45px",
                  fontWeight: "999",
                  color: "#0082bc",
                }}
              >
                <b>Vision:</b>
              </span>
            </h1>
            <p
              style={{
                textIndent: "50px",
                textAlign: "justify",
                lineHeight: 1.4,
                wordSpacing: "5px",
                fontSize: "20px",
                fontWeight: "10",
              }}
            >
              <br />
              Our vision at Medilight Pvt Ltd is to become a leader in the water
              ionizer industry, recognized for our innovative technology and
              commitment to enhancing health naturally. We aspire to improve the
              quality of life for individuals worldwide by providing superior
              hydrogen-rich alkaline water solutions, fostering a healthier and
              more sustainable future.
              <br />
              <br />
              <div className="lines">
                <div></div>
                <div></div>
              </div>
              <br />
            </p>
            <h1>
              <span
                style={{
                  fontSize: "45px",
                  fontWeight: "999",
                  color: "#0082bc",
                }}
              >
                <b>Mission:</b>
              </span>
            </h1>
            <p
              style={{
                textIndent: "50px",
                textAlign: "justify",
                lineHeight: 1.4,
                wordSpacing: "5px",
                fontSize: "20px",
                fontWeight: "10",
              }}
            >
              <br />
              Our mission is to deliver prompt service to each and every
              customer and to provide Hydrogen rich Alklaine water ionizer's
              through the use of advanced Japanese technology and highly
              qualified staff, ensuring the well-being of our customers.
            </p>
          </div>
        </Col>
        <Col md={4} className="justify-content-center">
          <center>
            <img
              src={require("../asserts/s3.png")}
              alt=""
              style={{ width: "70%" }}
            />
          </center>
        </Col>
      </Row>

      <br />

      <Formd />

      <Footer />
    </div>
  );
};

export default StoryPage;
