import React, { useEffect } from "react";
import "../style/ContactForm.css";
import {
  FaClock,
  FaMapMarkerAlt,
  FaMobileAlt,
  FaEnvelopeOpenText,
} from "react-icons/fa";
import Footer from "../components/Footer";
import Formd from "../components/Formd";
import Navbar from "../components/Navbar";
import { useLocation } from "react-router-dom";
import ReactGA from "react-ga";
import { Helmet } from "react-helmet";

const ContactPage = () => {
  useEffect(() => {
    ReactGA.initialize("G-FP7N65QWQ6");
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);
  return (
    <div>
      <Helmet>
        <title>
          {" "}
          Kangen Water Machines Vs Tyent Contact Today for Free Demo
        </title>
        <meta
          name="description"
          content="Kangen Water Machines Vs Tyent Contact Today for Free Demo"
        />
      </Helmet>
      <div>
        <div className="contact-bg">
          <h3>Get in Touch with Us</h3>
          <h2>contact us</h2>
          <div className="line">
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>

        <div className="contact-body">
          <div className="contact-info">
            <div>
              <span>
                <FaMobileAlt className="fas" />
              </span>
              <span>Phone No.</span>
              <span
                className="text"
                style={{ padding: "25px", fontWeight: "900" }}
              >
                <b>+91 91824 14181</b>
              </span>
            </div>
            <div>
              <span>
                <FaEnvelopeOpenText className="fas" />
              </span>
              <span>E-mail</span>
              <span
                className="text"
                style={{ padding: "25px", fontWeight: "900" }}
              >
                Contact@medilightindia.com
              </span>
            </div>
            <div>
              <span>
                <FaMapMarkerAlt className="fas" />
              </span>
              <span>Address</span>
              <span
                className="text"
                style={{
                  textAlign: "justify",
                  fontSize: "15px",
                  fontWeight: "900",
                }}
              >
                Medilight Private Limited, 8-277/45, UBI Colony, Banjara Hills
                Rd Number 3, Hyderabad - 500034.
              </span>
            </div>
            <div>
              <span>
                <FaClock className="fas" />
              </span>
              <span>Opening Hours</span>
              <span
                className="text"
                style={{ padding: "20px", fontWeight: "900" }}
              >
                Monday - Friday (9:00 AM to 5:00 PM)
              </span>
            </div>
          </div>
          <p style={{ fontSize: "20px", textAlign: "center" }}>
            <b>Note :-</b> In Case of machine complaints & service contact care
            Number : <b> 912-12-12-555</b>
          </p>
        </div>

        <Formd />
        <Footer />
      </div>
    </div>
  );
};

export default ContactPage;
