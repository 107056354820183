import React, { useEffect } from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { Container } from '@material-ui/core';
import Aos from "aos";
import "aos/dist/aos.css";


const StyledTableCell = withStyles((theme) => ({
 
  body: {
    fontSize: 18,
    padding:'10px',
    
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
    
    },
  },
}))(TableRow);



function createData(check, calories, fat, wrong, carbs  ) {


  return {check, calories, fat, wrong, carbs};
}



const rows = [  
  createData('No','Name', 'No', 'Name'),
  createData(
           <div style={{backgroundColor:'#008ac7',color:'white',  borderRadius: '50%', width:'30%', alignItems:'center', textAlign:'center', fontSize: '20px'}}>01</div>,
           'Turbo water (11.5pH)',
           <div style={{backgroundColor:'#008ac7',color:'white',  borderRadius: '50%', width:'30%', alignItems:'center', textAlign:'center', fontSize: '20px'}}>11</div>, 'Voltage/Flow/Time'),
  createData(<div style={{backgroundColor:'#008ac7',color:'white',  borderRadius: '50%', width:'30%', alignItems:'center', textAlign:'center', fontSize: '20px'}}>02</div>,
          'Acidic level 2 (5 - 5.5 pH)',     
          <div style={{backgroundColor:'#008ac7',color:'white',  borderRadius: '50%', width:'30%', alignItems:'center', textAlign:'center', fontSize: '20px'}}>12</div>, 'Remaining time'),
  createData(
             <div style={{backgroundColor:'#008ac7',color:'white',  borderRadius: '50%', width:'30%', alignItems:'center', textAlign:'center', fontSize: '20px'}}>03</div>,
             'Acidic level 1 (4 - 5pH)',     
             <div style={{backgroundColor:'#008ac7',color:'white',  borderRadius: '50%', width:'30%', alignItems:'center', textAlign:'center', fontSize: '20px'}}>13</div>, 'Door open'),
  createData(
             <div style={{backgroundColor:'#008ac7',color:'white',  borderRadius: '50%', width:'30%', alignItems:'center', textAlign:'center', fontSize: '20px'}}>04</div>,
             'Purified water (7pH)',      
             <div style={{backgroundColor:'#008ac7',color:'white',  borderRadius: '50%', width:'30%', alignItems:'center', textAlign:'center', fontSize: '20px'}}>14</div>, 'Temperatura Error'),
  createData(
            <div style={{backgroundColor:'#008ac7',color:'white',  borderRadius: '50%', width:'30%', alignItems:'center', textAlign:'center', fontSize: '20px'}}>05</div>,
            'Alkaline  level 1 (8.5pH)', 
            <div style={{backgroundColor:'#008ac7',color:'white',  borderRadius: '50%', width:'30%', alignItems:'center', textAlign:'center', fontSize: '20px'}}>15</div>, 'Fixed qty discharge'),
  createData(
            <div style={{backgroundColor:'#008ac7',color:'white',  borderRadius: '50%', width:'30%', alignItems:'center', textAlign:'center', fontSize: '20px'}}>06</div>,
            'Alkaline  level 2 (9 pH)',
            <div style={{backgroundColor:'#008ac7',color:'white',  borderRadius: '50%', width:'30%', alignItems:'center', textAlign:'center', fontSize: '20px'}}>16</div>, 'System setup'),
  createData(
            <div style={{backgroundColor:'#008ac7',color:'white',  borderRadius: '50%', width:'30%', alignItems:'center', textAlign:'center', fontSize: '20px'}}>07 </div>,
            'Alkaline  level 3 (9.5 pH)',       
            <div style={{backgroundColor:'#008ac7',color:'white',  borderRadius: '50%', width:'30%', alignItems:'center', textAlign:'center', fontSize: '20px'}}>17</div>, 'Manual Cleaning'),
  createData(
    <div style={{backgroundColor:'#008ac7',color:'white',  borderRadius: '50%', width:'30%', alignItems:'center', textAlign:'center', fontSize: '20px'}}>08</div>,
    'Time',       
    <div style={{backgroundColor:'#008ac7',color:'white',  borderRadius: '50%', width:'30%', alignItems:'center', textAlign:'center', fontSize: '20px'}}>18</div>, 'Voice ON/OFF'),
  
    createData(
      <div style={{backgroundColor:'#008ac7',color:'white',  borderRadius: '50%', width:'30%', alignItems:'center', textAlign:'center', fontSize: '20px'}}>09</div>,
      'pH',       
      <div style={{backgroundColor:'#008ac7',color:'white',  borderRadius: '50%', width:'30%', alignItems:'center', textAlign:'center', fontSize: '20px'}}>19</div>, '1st Filter indicator & reset'),
  
      createData(
        <div style={{backgroundColor:'#008ac7',color:'white',  borderRadius: '50%', width:'30%', alignItems:'center', textAlign:'center', fontSize: '20px'}}>10</div>,
        'Water Flowrate',       
        <div style={{backgroundColor:'#008ac7',color:'white',  borderRadius: '50%', width:'30%', alignItems:'center', textAlign:'center', fontSize: '20px'}}>16</div>, '2nd Filter indicator & reset'),
      
];

const useStyles = makeStyles({
  table: {
    minWidth: 700,
    padding: 0,
  },
});


export default function Ucetable() {
  const classes = useStyles();
  useEffect(() => {
    Aos.init({
      debounceDelay: 50, 
      duration:2000,
      easing: 'ease',
      once:false,
    });
  }, []);

  return (
    <Container>
    <TableContainer component={Paper}>
      <Table className={classes.table} aria-label="customized table">
        <TableHead>
          
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <StyledTableRow >
              <StyledTableCell >{row.check}</StyledTableCell>
              <StyledTableCell >{row.calories}</StyledTableCell>
              <StyledTableCell >{row.fat}</StyledTableCell>
              <StyledTableCell >{row.wrong}</StyledTableCell>
              <StyledTableCell >{row.carbs}</StyledTableCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
    </Container>
  );
}
