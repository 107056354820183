import React, { useState } from "react";
import "../style/form.css";
import { Row, Col } from "react-grid-system";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import SendIcon from "@mui/icons-material/Send";

const Formd = () => {
  const [name, setName] = useState(" ");
  const [email, setEmail] = useState(" ");
  const [number, setNumber] = useState(" ");
  const [location, setLocation] = useState(" ");
  const [messageBox, setMessageBox] = useState(" ");

  const sendHandler = async () => {
    let datasend = {
      name: name,
      email: email,
      number: number,
      location: location,
      messageBox: messageBox,
    };

    const res = await fetch(`http://localhost:8000/email/sendEmail`, {
      method: "POST",
      body: JSON.stringify(datasend),
      headers: {
        Accept: "application/json",
        "content-Type": "application/json",
      },
    }).then((res) => {
      console.log(res);
      if (res.status > 199 && res.status < 300) {
        alert("send Scuucesfully !");
      }
    });
  };

  return (
    <div>
      <p
        style={{
          color: "#0082bc",
          textAlign: "center",
          fontSize: "30px",
          fontWeight: "300",
        }}
      >
        Fill the form - We will get in touch
      </p>
      <Row style={{ width: "100%", backgroundColor: "#FFF" }}>
        <Col
          md={6}
          className="d-flex align-items-center justify-content-center text-center"
          style={{ padding: "40px" }}
        >
          <div className="leftside">
            <img src={require("../asserts/reopen.webp")} width="100%" alt="" />
          </div>
        </Col>

        <Col md={6}>
          <div className="rightside d-flex justify-content-center align-items-center">
            <iframe
              src="/form.html"
              width="100%"
              height="580px"
              title="form daat"
            />
          </div>
        </Col>
      </Row>
    </div>
  );
};
export default Formd;
