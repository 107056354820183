import React from "react";
import { Row, Col } from "react-grid-system";
import Navbar from "../components/Navbar.js";
import "../style/Nmp5.css";
import { Button, Card, CardContent } from "@material-ui/core";
import { FaPhoneAlt } from "react-icons/fa";
import Footer from "../components/Footer";
import Ucetable from "../components/Ucetable.js";
import { Container } from "react-bootstrap";
import Smartfeatures from "../components/Smartfeatures";

import { FaTint } from "react-icons/fa";
import { Helmet } from "react-helmet";

class nmp5 extends React.Component {
  state = {
    products: [
      {
        src: [
          "https://firebasestorage.googleapis.com/v0/b/tyent-fa17c.appspot.com/o/nmp%2FPRODUCTNMP1.webp?alt=media&token=522f47f3-2aef-47ef-9ddb-c426ac468fa5",
          "https://firebasestorage.googleapis.com/v0/b/tyent-fa17c.appspot.com/o/nmp%2FPRODUCTIMAGESNMP3.webp?alt=media&token=a492cd28-e40d-4a0a-a569-2cc647a89eb8",
          "https://firebasestorage.googleapis.com/v0/b/tyent-fa17c.appspot.com/o/nmp%2FPRODUCTIMAGESNMP2.webp?alt=media&token=b032deee-d922-4b11-946e-81b555e14e56",
        ],
      },
    ],
    index: 0,
  };

  myRef = React.createRef();

  handleTab = (index) => {
    this.setState({ index: index });
    const image = this.myRef.current.children;
    for (let i = 0; i < image.length; i++) {
      image[i].className = image[i].className.replace("active", "");
    }
    image[index].className = "active";
  };

  componentDidMount() {
    const { index } = this.state;
    this.myRef.current.children[index].className = "active";
  }

  render() {
    const { products, index } = this.state;
    return (
      <div>
        <Helmet>
          <title>NMP5 Plates Water Ionizer-Solid Hybrid Mesh Plates</title>
          <meta
            name="description"
            content="NMP5 Plates Water Ionizer-Solid Hybrid Mesh Plates"
          />
        </Helmet>
        <div className="home__image">
          <img src={require("../asserts/b2.webp")} alt="" width="100%" />
        </div>
        <Container>
          <Row
            className="d-flex justify-content-center"
            style={{ backgroundColor: "#FFF", borderRadius: "8px" }}
          >
            <Col
              md={6}
              className="d-flex justify-content-center align-items-center"
            >
              <br />
              <div
                style={{ width: "100%", margin: "30px", position: "relative" }}
              >
                {products.map((item) => (
                  <div className="details" key={item.src}>
                    <div className="d-flex justify-content-center align-items-center">
                      <img
                        src={item.src[index]}
                        alt=""
                        width="100%"
                        className="pi"
                      />
                    </div>
                    <div
                      className="thumb justify-content-center align-items-center"
                      ref={this.myRef}
                    >
                      {item.src.map((img, index) => (
                        <img
                          src={img}
                          alt=""
                          key={index}
                          onClick={() => this.handleTab(index)}
                        />
                      ))}
                    </div>
                  </div>
                ))}
              </div>
              <br />
            </Col>
            <Col md={6} className="d-flex" style={{ backgroundColor: "#FFF" }}>
              <div>
                <br />
                <h2
                  style={{
                    color: "#0082bc",
                    textAlign: "left",
                    fontSize: "50px",
                    justifyContent: "center",
                  }}
                >
                  <b>Tyent NMP-5 </b>
                </h2>
                <h3
                  style={{
                    color: "#000",
                    textAlign: "left",
                    fontSize: "40px",
                    justifyContent: "center",
                  }}
                >
                  M.R.P :{" "}
                  <span style={{ color: "#ff0000" }}> ₹ 1,68,000/-</span>
                </h3>
                <br />
                <table id="customers">
                  <tbody style={{ textAlign: "left" }}>
                    <tr>
                      <td>
                        <b>Electrode plates</b>{" "}
                      </td>
                      <td></td>
                      <td>:&nbsp;5</td>
                    </tr>
                    <tr>
                      <td>
                        <b>pH range</b>{" "}
                      </td>
                      <td></td>
                      <td>:&nbsp;2.5 - 11.5*</td>
                    </tr>
                    <tr>
                      <td>
                        <b>ORP Range</b>{" "}
                      </td>
                      <td></td>
                      <td>:&nbsp;up to - 850*</td>
                    </tr>
                    <tr>
                      <td>
                        <b>Generates</b>{" "}
                      </td>
                      <td></td>
                      <td>:&nbsp;7 types of water levels.</td>
                    </tr>
                    <tr>
                      <td>
                        <b>Surface area</b>{" "}
                      </td>
                      <td></td>
                      <td>:&nbsp;52.5*5 = 264.5 sq inches</td>
                    </tr>
                    <tr>
                      <td>
                        <b>Warranty</b>{" "}
                      </td>
                      <td></td>
                      <td>:&nbsp;3 Years on machine & 15 years on plates</td>
                    </tr>
                    <tr>
                      <td colSpan="3">
                        <h2
                          style={{
                            color: "#000",
                            textAlign: "left",
                            fontSize: "15px",
                          }}
                        >
                          <b>*Depends on input water quality and Flow rate</b>
                        </h2>
                      </td>
                    </tr>
                    <tr>
                      <td colSpan="3">
                        <h2
                          style={{
                            color: "#000",
                            textAlign: "left",
                            fontSize: "15px",
                          }}
                        >
                          <b>
                            (No use of external chemicals, salts, or artificial
                            enhancers)
                          </b>
                        </h2>
                      </td>
                    </tr>
                  </tbody>
                </table>

                <Button
                  variant="contained"
                  style={{
                    color: "#FFF",
                    float: "right",
                    backgroundColor: "#0082bc",
                    textTransform: "none",
                    margin: "20px",
                  }}
                >
                  <FaPhoneAlt />
                  &nbsp;&nbsp;Call To Order
                </Button>
              </div>
            </Col>
          </Row>
        </Container>
        <div>
          <hr
            style={{
              height: "1px",
              width: "100%",
              borderWidth: "0",
              backgroundColor: "#0082bc",
            }}
          />

          <Row style={{ backgroundColor: "#FFF" }}>
            <Col
              md={6}
              className="d-flex align-items-center justify-content-center"
            >
              <img
                src={require("../asserts/nmp/MechineFile.png")}
                alt=""
                width="70%"
              />
            </Col>
            <Col
              md={6}
              className="nmpt d-flex align-items-center justify-content-center"
              style={{ backgroundColor: "#FFF" }}
            >
              <div id="fa">
                <div className="oneT">
                  <hr
                    style={{
                      height: "12vh",
                      width: ".5vw",
                      borderWidth: "0",
                      backgroundColor: "#0082bc",
                    }}
                  />
                  <p style={{ margin: "20px", fontSize: "21px" }}>
                    <span
                      style={{
                        color: "#0082bc",
                        fontSize: "40px",
                        textAlign: "justify",
                        fontWeight: "600",
                      }}
                    >
                      ONE TOUCH sytem{" "}
                    </span>{" "}
                    <br />
                    Each function selection is ionized, and you can outflow and
                    stop the sesired water with one touch button
                  </p>
                </div>
                <br />

                <div className="oneT">
                  <hr
                    style={{
                      height: "12vh",
                      width: ".4vw",
                      borderWidth: "0",
                      backgroundColor: "#0082bc",
                    }}
                  />
                  <p style={{ margin: "20px", fontSize: "21px" }}>
                    <span
                      style={{
                        color: "#0082bc",
                        fontSize: "40px",
                        fontWeight: "600",
                      }}
                    >
                      Colour back light Function
                    </span>{" "}
                    <br />
                    it distinguishes alkaline water and acid water with the
                    colour of back light
                  </p>
                </div>
              </div>
            </Col>
          </Row>
          <hr
            style={{
              height: "1px",
              width: "100%",
              borderWidth: "0",
              backgroundColor: "#0082bc",
            }}
          />
          <center>
            <div>
              <p id="color">
                <strong>
                  <b>Colour Full Display</b>
                </strong>
              </p>
            </div>
            <br />

            <Row>
              <Col
                md={6}
                className="d-flex justify-content-center align-items-center"
              >
                <div>
                  <Row style={{ backgroundColor: "#f9faf8" }}>
                    <Col md={2}>
                      <p id="displayc">
                        <span style={{ fontSize: "30px", fontWeight: "600" }}>
                          Alkaline{" "}
                        </span>
                        <br /> level 3 - 9.5 pH
                      </p>
                    </Col>
                    <Col md={10}>
                      <img
                        src={require("../asserts/nmp/pro_06.png")}
                        alt=""
                        style={{
                          width: "100%",
                          border: "10px solid #000",
                          borderRadius: "10px",
                        }}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col md={2}>
                      <p id="displayc">
                        <span style={{ fontSize: "30px", fontWeight: "600" }}>
                          Alkaline{" "}
                        </span>
                        <br /> level 2 - 9.0 pH
                      </p>
                    </Col>
                    <Col md={10}>
                      <img
                        src={require("../asserts/nmp/pro_07.png")}
                        alt=""
                        style={{
                          width: "100%",
                          border: "10px solid #000",
                          borderRadius: "10px",
                        }}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col md={2}>
                      <p id="displayc">
                        <span style={{ fontSize: "30px", fontWeight: "600" }}>
                          Alkaline{" "}
                        </span>
                        <br />
                        level 1 - 8.5 pH
                      </p>
                    </Col>
                    <Col md={10}>
                      <img
                        src={require("../asserts/nmp/pro_08.png")}
                        alt=""
                        style={{
                          width: "100%",
                          border: "10px solid #000",
                          borderRadius: "10px",
                        }}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col md={2}>
                      <p id="displayc">
                        <span style={{ fontSize: "30px", fontWeight: "600" }}>
                          Purified{" "}
                        </span>
                        <br />
                        water - 7.0 pH
                      </p>
                    </Col>
                    <Col md={10}>
                      <img
                        src={require("../asserts/nmp/pro_09.png")}
                        alt=""
                        style={{
                          width: "100%",
                          border: "10px solid #000",
                          borderRadius: "10px",
                        }}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col md={2}>
                      <p id="displayc">
                        <span style={{ fontSize: "40px", fontWeight: "600" }}>
                          Acidic{" "}
                        </span>
                        <br />
                        level 1 - 5.0 pH
                      </p>
                    </Col>
                    <Col md={10}>
                      <img
                        src={require("../asserts/nmp/pro_10.png")}
                        alt=""
                        style={{
                          width: "100%",
                          border: "10px solid #000",
                          borderRadius: "10px",
                        }}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col md={2}>
                      <p id="displayc">
                        <span style={{ fontSize: "40px", fontWeight: "600" }}>
                          Acidic{" "}
                        </span>
                        <br />
                        level 2 - 4 pH
                      </p>
                    </Col>
                    <Col md={10}>
                      <img
                        src={require("../asserts/nmp/pro_11.png")}
                        alt=""
                        style={{
                          width: "100%",
                          border: "10px solid #000",
                          borderRadius: "10px",
                        }}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col md={2}>
                      <p id="displayc">
                        <span style={{ fontSize: "40px", fontWeight: "600" }}>
                          Turbo{" "}
                        </span>{" "}
                        <br />
                        water 11.5 pH
                      </p>
                    </Col>
                    <Col md={10}>
                      <img
                        src={require("../asserts/nmp/pro_12.png")}
                        alt=""
                        style={{
                          width: "100%",
                          border: "10px solid #000",
                          borderRadius: "10px",
                        }}
                      />
                    </Col>
                  </Row>
                </div>
              </Col>

              <Col md={6}>
                <img
                  src={require("../asserts/edge.webp")}
                  alt=""
                  width="100%"
                />
              </Col>
              <hr
                style={{
                  height: "1px",
                  width: "100%",
                  borderWidth: "0",
                  backgroundColor: "#0082bc",
                }}
              />
            </Row>
          </center>
          <Row>
            <Col
              md={6}
              className="d-flex justify-content-center align-items-center"
              style={{ backgroundColor: "#FFF" }}
            >
              <img
                src={require("../asserts/nmp/NMP5MeshPlates.webp")}
                alt=""
                width="60%"
              />
            </Col>
            <Col md={6} className="d-flex" style={{ backgroundColor: "#FFF" }}>
              <div className="oneT">
                <hr
                  style={{
                    height: "45vh",
                    width: ".5vw",
                    borderWidth: "0",
                    backgroundColor: "#0082bc",
                  }}
                />
                <p
                  style={{
                    margin: "20px",
                    fontSize: "21px",
                    textAlign: "justify",
                  }}
                >
                  <span
                    style={{
                      color: "#0082bc",
                      fontSize: "40px",
                      fontWeight: "600",
                    }}
                  >
                    Solid hybrid mesh plates.
                  </span>{" "}
                  <br />
                  Looking for a high-performance water ionizer with the most
                  advanced technology? Look no further than Tyent's solid hybrid
                  mesh plates! These plates are a game-changer, combining the
                  strength and durability of solid plates with the high surface
                  area and performance of mesh plates. Tyent's solid hybrid mesh
                  plates with greater surface area offers superior ionization,
                  and more anti-oxidants (-ORP) capabilities, making them the
                  perfect choice for those seeking the purest, healthiest, and
                  most refreshing water. With Tyent's solid hybrid mesh plates,
                  you can enjoy the best-tasting water with all the health
                  benefits you expect from a top-quality water ionizer. Trust
                  Tyent to provide you with the highest quality and most
                  advanced water ionization technology available.
                </p>
              </div>
            </Col>
          </Row>
          <center>
            <hr
              style={{
                height: "1px",
                width: "100%",
                borderWidth: "0",
                backgroundColor: "#0082bc",
              }}
            />

            <Row>
              <Col
                md={6}
                className="d-flex justify-content-center align-items-center"
                style={{ backgroundColor: "#FFF" }}
              >
                <img
                  src={require("../asserts/nmp/NMP5SMPS.webp")}
                  alt=""
                  width="60%"
                />
              </Col>
              <Col
                md={6}
                className="d-flex"
                style={{ backgroundColor: "#FFF" }}
              >
                <div className="oneT">
                  <hr
                    style={{
                      height: "45vh",
                      width: ".5vw",
                      borderWidth: "0",
                      backgroundColor: "#0082bc",
                    }}
                  />
                  <p
                    style={{
                      margin: "20px",
                      fontSize: "21px",
                      textAlign: "justify",
                    }}
                  >
                    <span
                      style={{
                        color: "#0082bc",
                        fontSize: "40px",
                        fontWeight: "600",
                      }}
                    >
                      Patented SMPS PLUS power supply.
                    </span>{" "}
                    <br />
                    Tyent's SMPS PLUS innovative power supply system delivers
                    consistent and reliable power to your ionizer, ensuring that
                    you get the best possible water quality every time. Tyent
                    engineers went back to the drawing board and created their
                    own PSU called the SMPS Plus. They took a traditional SMPS
                    and enhanced it to work specifically in a water ionizer.
                    Tyent's SMPS Plus gives you 55 adjustable power settings so
                    you can create the perfect glass of alkaline water based on
                    your source water. The power and efficiency of SMPS Plus are
                    what enable Tyent ionizers to create strong alkaline and
                    acidic water without using chemicals, providing a longer
                    lifespan. With all of the advantages of Tyent’s SMPS Plus,
                    you have to wonder why some companies still choose the
                    cheaper, less efficient linear power supply in their
                    water ionizer's.
                  </p>
                </div>
              </Col>
            </Row>
            <hr
              style={{
                height: "1px",
                width: "100%",
                borderWidth: "0",
                backgroundColor: "#0082bc",
              }}
            />

            <Row style={{ backgroundColor: "#FFF" }}>
              <Col
                md={6}
                className="d-flex align-items-center justify-content-center"
              >
                <img
                  src={require("../asserts/nmp/rtr.webp")}
                  alt=""
                  width="60%"
                />
              </Col>

              <Col
                md={6}
                className="d-flex jusyify-content-center align-items-center"
                style={{ backgroundColor: "#FFF" }}
              >
                <div className="oneT">
                  <hr
                    style={{
                      height: "35vh",
                      width: ".5vw",
                      borderWidth: "0",
                      backgroundColor: "#0082bc",
                    }}
                  />
                  <p
                    style={{
                      padding: "20px",
                      textAlign: "justify",
                      fontSize: "20px",
                    }}
                  >
                    <span
                      style={{
                        color: "#0082bc",
                        fontSize: "40px",
                        fontWeight: "600",
                      }}
                    >
                      PAST cleaning technology
                    </span>{" "}
                    <br />
                    Tyent's PAST (Polarity Anti Scaling Technology) cleaning
                    technology is a cutting-edge and powerful cleaning solution.
                    With PAST cleaning technology, you can easily eliminate
                    mineral scaling on the electrodes, resulting in improved
                    plate performance & water quality. This innovative
                    technology is not only highly effective at keeping your
                    ionizer in top condition, but it also safeguards your
                    investment, ensuring that you get years of healthy water
                    from your ionizer. Trust Tyent's PAST cleaning technology to
                    keep your water ionizer functioning at its best. <br />
                  </p>
                </div>
              </Col>
            </Row>
            <hr
              style={{
                height: "1px",
                width: "100%",
                borderWidth: "0",
                backgroundColor: "#0082bc",
              }}
            />

            <Row style={{ backgroundColor: "#FFF" }}>
              <Col
                md={6}
                className="d-flex justify-content-center align-items-center"
                style={{ backgroundColor: "#FFF" }}
              >
                <img
                  src={require("../asserts/nmp/NMP5ECOMODE.webp")}
                  alt=""
                  width="60%"
                />
              </Col>
              <Col
                md={6}
                className="d-flex justify-content-left align-items-center"
              >
                <div className="oneT">
                  <hr
                    style={{
                      height: "22vh",
                      width: ".5vw",
                      borderWidth: "0",
                      backgroundColor: "#0082bc",
                    }}
                  />
                  <p
                    style={{
                      textAlign: "justify",
                      fontSize: "20px",
                      padding: "20px",
                    }}
                  >
                    <span
                      style={{
                        color: "#0082bc",
                        fontSize: "40px",
                        fontWeight: "800",
                      }}
                    >
                      ECO Mode
                    </span>
                    <br />
                    Eco mode, also referred to as "economy mode," is a unique
                    operating mode offered by Tyent water ionizers that enhances
                    energy efficiency. By enabling Eco mode, Tyent water
                    ionizers can achieve up to 99% efficiency and significant
                    power savings. Additionally, the Eco mode automatically
                    turns off the LCD backlight, conserving electricity. Choose
                    an Eco mode for energy-efficient UPS performance.
                  </p>
                </div>
              </Col>
            </Row>

            <hr
              style={{
                height: "1px",
                width: "100%",
                borderWidth: "0",
                backgroundColor: "#0082bc",
              }}
            />

            <Row style={{ backgroundColor: "#FFF" }}>
              <Col
                md={6}
                className="d-flex justify-content-center align-items-center"
              >
                <img
                  src={require("../asserts/nmp/turbo.png")}
                  alt=""
                  width="60%"
                />
              </Col>
              <Col
                md={6}
                className="d-flex justify-content-left align-items-center"
                style={{ backgroundColor: "#FFF" }}
              >
                <div className="oneT">
                  <hr
                    style={{
                      height: "20vh",
                      width: ".5vw",
                      borderWidth: "0",
                      backgroundColor: "#0082bc",
                    }}
                  />
                  <p
                    style={{
                      fontSize: "20px",
                      textAlign: "justify",
                      padding: "20px",
                    }}
                  >
                    <span
                      style={{
                        color: "#0082bc",
                        fontSize: "45px",
                        fontWeight: "600",
                      }}
                    >
                      {" "}
                      Turbo Mode{" "}
                    </span>{" "}
                    <br />
                    Turbo mode in Tyent is used to produce strong alkaline water
                    & strong acidic water of 11.5 & 2.5 pH. Tyent with updated &
                    patented SMPS PLUS & solid hybrid mesh plates is the only
                    water ionizer that produces 11.5 & 2.5 pH water without
                    using any chemical, enhancer or salt in the entire
                    ionizer industry.
                  </p>
                </div>
              </Col>
            </Row>
            <hr
              style={{
                height: "1px",
                width: "100%",
                borderWidth: "0",
                backgroundColor: "#0082bc",
              }}
            />
            <Row style={{ backgroundColor: "#FFF" }}>
              <Col
                md={6}
                className="d-flex align-items-center justify-content-center"
              >
                <img
                  src={require("../asserts/nmp/NMPFixedQuantity.webp")}
                  alt=""
                  width="60%"
                />
              </Col>
              <Col
                md={6}
                className="d-flex jusyify-content-left align-items-center"
                style={{ backgroundColor: "#FFF" }}
              >
                <div className="oneT">
                  <hr
                    style={{
                      height: "27vh",
                      width: ".5vw",
                      borderWidth: "0",
                      backgroundColor: "#0082bc",
                    }}
                  />
                  <p style={{ padding: "20px", fontSize: "21px" }}>
                    <span
                      style={{
                        color: "#0082bc",
                        fontSize: "45px",
                        fontWeight: "600",
                      }}
                    >
                      Fixed quantity discharge function
                    </span>{" "}
                    <br />
                    <Row>
                      <Col className="d-flex justify-content-center align-items-center">
                        <div className="box1">
                          <p
                            style={{
                              fontSize: "30px",
                              fontWeight: "600",
                              textAlign: "center",
                              alignItems: "center",
                            }}
                          >
                            0.5L
                          </p>
                        </div>
                      </Col>
                      <Col className="d-flex justify-content-center align-items-center">
                        <div className="box1">
                          <p style={{ fontSize: "30px", fontWeight: "600" }}>
                            1.0L
                          </p>
                        </div>
                      </Col>
                      <Col className="d-flex justify-content-center align-items-center">
                        <div className="box1">
                          <p style={{ fontSize: "30px", fontWeight: "600" }}>
                            1.5L
                          </p>
                        </div>
                      </Col>
                    </Row>
                    You can drink the fixed amount of water which uses a lot in
                    our everyday <br />
                    lives such as 0.5L, 1.0L and 1.5L via Fixed quantity
                    discharge function.
                  </p>
                </div>
              </Col>
              <hr
                style={{
                  height: "1px",
                  width: "100%",
                  borderWidth: "0",
                  backgroundColor: "#0082bc",
                }}
              />
            </Row>
            <Row style={{ backgroundColor: "#FFF" }}>
              <Col
                md={6}
                className="d-flex align-items-center justify-content-center"
              >
                <img
                  src={require("../asserts/nmp/NMP5FilterUsageIndication.webp")}
                  alt=""
                  width="60%"
                />
              </Col>
              <Col
                md={6}
                className="d-flex jusyify-content-left align-items-center"
                style={{ backgroundColor: "#FFF" }}
              >
                <div className="oneT">
                  <hr
                    style={{
                      height: "15vh",
                      width: ".5vw",
                      borderWidth: "0",
                      backgroundColor: "#0082bc",
                    }}
                  />
                  <p
                    style={{
                      textAlign: "left",
                      padding: "10px",
                      fontSize: "20px",
                    }}
                  >
                    <span
                      style={{
                        color: "#0082bc",
                        fontSize: "40px",
                        fontWeight: "600",
                      }}
                    >
                      Real time Filter Usage Indication
                    </span>
                    <br />
                    The display indicates the filter usage on the LCD screen
                    allowing <br />
                    you to Know exactly when it's time to change your filters!
                  </p>
                </div>
              </Col>
              <hr
                style={{
                  height: "1px",
                  width: "100%",
                  borderWidth: "0",
                  backgroundColor: "#0082bc",
                }}
              />
            </Row>
            <Row style={{ backgroundColor: "#FFF" }}>
              <Col md={6}>
                <img
                  src={require("../asserts/nmp/drain.png")}
                  alt=""
                  width="70%"
                />
              </Col>

              <Col
                md={6}
                className="d-flex"
                style={{ backgroundColor: "#FFF" }}
              >
                <div className="oneT">
                  <hr
                    style={{
                      height: "15vh",
                      width: ".5vw",
                      borderWidth: "0",
                      backgroundColor: "#0082bc",
                    }}
                  />
                  <p
                    style={{
                      margin: "20px",
                      fontSize: "21px",
                      textAlign: "left",
                    }}
                  >
                    <span
                      style={{
                        color: "#0082bc",
                        fontSize: "40px",
                        fontWeight: "600",
                      }}
                    >
                      Drain Faucet
                    </span>{" "}
                    <br />
                    It comes with Drain faucet that can be easily installed to
                    anywhere on the sink
                  </p>
                </div>
              </Col>
            </Row>
            <hr
              style={{
                height: "1px",
                width: "100%",
                borderWidth: "0",
                backgroundColor: "#0082bc",
              }}
            />
          </center>

          <br />
          <p
            style={{
              fontSize: "50px",
              fontWeight: "600",
              textAlign: "center",
              padding: "10px",
              color: "#0082bc",
            }}
          >
            LCD Display and Touch Pad Overview
          </p>
          <img
            src={require("../asserts/nmp/spec.png")}
            alt=""
            style={{
              display: "block",
              marginLeft: "auto",
              marginRight: "auto",
            }}
            width="80%"
          />
          <br />
          <Ucetable />

          <br />
          <hr
            style={{
              height: "1px",
              width: "100%",
              borderWidth: "0",
              backgroundColor: "#0082bc",
            }}
          />
          <Smartfeatures />
          <hr
            style={{
              height: "1px",
              width: "100%",
              borderWidth: "0",
              backgroundColor: "#0082bc",
            }}
          />

          <Container>
            <p
              style={{
                fontSize: "50px",
                fontWeight: "600",
                textAlign: "center",
                padding: "10px",
                color: "#0082bc",
              }}
            >
              Tyent NMP-5 Specification's
            </p>
            <div className="d-flex justify-content-center align-iteams-center">
              <table className="spec" style={{ color: "#0082bc" }}>
                <tr>
                  <td>
                    <b>Model</b>{" "}
                  </td>
                  <td>Tyent NMP-5.</td>
                </tr>
                <tr>
                  <td>
                    <b>Electrode/plates</b>{" "}
                  </td>
                  <td>:&nbsp;5.</td>
                </tr>
                <tr>
                  <td>
                    <b>pH Range</b>{" "}
                  </td>
                  <td>
                    :&nbsp;2.5 - 11.5.<sup>*</sup>
                  </td>
                </tr>
                <tr>
                  <td>
                    <b>ORP Range</b>{" "}
                  </td>
                  <td>
                    :&nbsp;up to - 850.<sup>*</sup>
                  </td>
                </tr>
                <tr>
                  <td>
                    <b>Generates</b>{" "}
                  </td>
                  <td>:&nbsp;7 types of water levels.</td>
                </tr>
                <tr>
                  <td>
                    <b>pH Levels</b>{" "}
                  </td>
                  <td>
                    :&nbsp;3 alkaline, 2 Acidic, 1 neutral, & 1 Turbo (Strong
                    alkaline 11.5pH).
                  </td>
                </tr>
                <tr>
                  <td>
                    <b> plate Surface area</b>{" "}
                  </td>
                  <td>:&nbsp;52.9*5 = 264.5 sq inches.</td>
                </tr>
                <tr>
                  <td>
                    <b>
                      Water Generating
                      Capacity&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </b>{" "}
                  </td>
                  <td>:&nbsp;1.5-3.0L/min.</td>
                </tr>
                <tr>
                  <td>
                    <b>Filters Structure</b>{" "}
                  </td>
                  <td>:&nbsp;2 filters( .01 micron NSF & ANSI certified).</td>
                </tr>
                <tr>
                  <td>
                    <b>Filter Materials</b>{" "}
                  </td>
                  <td>
                    :&nbsp;Carbon Block, UF, TM ceramics, Membrance & Calcium
                    sulfite.
                  </td>
                </tr>
                <tr>
                  <td>
                    <b>Filter Capacity</b>{" "}
                  </td>
                  <td>:&nbsp;8000L.</td>
                </tr>
                <tr>
                  <td>
                    <b>Dimension(mm)</b>{" "}
                  </td>
                  <td>:&nbsp;300(W) * 135(D) * 355(H).</td>
                </tr>
                <tr>
                  <td>
                    <b>Weight(kg)</b>{" "}
                  </td>
                  <td>:&nbsp;5.2</td>
                </tr>
                <tr>
                  <td>
                    <b>Cleaning type</b>{" "}
                  </td>
                  <td>:&nbsp;Automatic (PAST technology).</td>
                </tr>

                <tr>
                  <td>
                    <b>Display Type</b>{" "}
                  </td>
                  <td>:&nbsp;One-touch LCD.</td>
                </tr>

                <tr>
                  <td>
                    <b>power Supply</b>{" "}
                  </td>
                  <td>:&nbsp;Patented SMPS PLUS power supply.</td>
                </tr>

                <tr>
                  <td>
                    <b>Power consumption</b>{" "}
                  </td>
                  <td>:&nbsp; Max 220W.</td>
                </tr>

                <tr>
                  <td>
                    <b>Operating Voltage Range</b>{" "}
                  </td>
                  <td>:&nbsp; 200 - 240V, 50-60HZ.</td>
                </tr>

                <tr>
                  <td>
                    <b>Voice Confirmation</b>{" "}
                  </td>
                  <td>:&nbsp; Yes</td>
                </tr>

                <tr>
                  <td>
                    <b>Input water parameters required</b>
                  </td>
                  <td>
                    {" "}
                    &nbsp; Input water TDS: 90 - 160.
                    <br /> : Input water pH: 7pH. <br /> &nbsp; Input water
                    Pressure: 5-7L/Min.{" "}
                  </td>
                </tr>

                <tr>
                  <td>
                    <b>Warranty</b>{" "}
                  </td>
                  <td>
                    :&nbsp;3 Years on ionizer & 15 years on Electrodes/plates.
                  </td>
                </tr>
                <tr>
                  <td>
                    <b>General Services</b>{" "}
                  </td>
                  <td>
                    :&nbsp;3 Services per year up to 3 years in Warranty peroid
                    (PAN INDIA)
                  </td>
                </tr>
                <tr>
                  <td>
                    <b>Safety Function</b>{" "}
                  </td>
                  <td>
                    :&nbsp;Water temperature sensor, Supplying water sensor,
                    Overheating protection sensor & Error indication sensor.
                  </td>
                </tr>
              </table>
            </div>
          </Container>
          <br />

          <br />
          <hr
            style={{
              height: "1px",
              width: "100%",
              borderWidth: "0",
              backgroundColor: "#0082bc",
            }}
          />
          <br />
          <p
            style={{
              fontSize: "35px",
              textAlign: "center",
              fontWeight: "600",
              color: "#0082bc",
            }}
          >
            Tyent's NSF & ANSI certified Extreme .01 Micron filtration
          </p>

          <Row>
            <Col
              md={6}
              className="d-flex justify-content-center align-iteams-center"
            >
              <img src={require("../asserts/filter.png")} alt="" width="65%" />
            </Col>
            <Col md={6} className="d-flex">
              <div className="oneT">
                <div>
                  <p style={{ fontSize: "25px" }}>
                    <FaTint size={30} color="#0082bc" />
                    &nbsp;Tyent filters are EPA & ANSI Certified.
                    <br />
                    <br />
                    <FaTint size={30} color="#0082bc" />
                    &nbsp;Tyent Extreme .01 micron filters remove even the
                    toughest <br />{" "}
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;contaminants such
                    as arsenic, chromium VI.etc
                    <br />
                    <br />
                    <FaTint size={30} color="#0082bc" />
                    &nbsp;Tyent filters can remove heavy metals,
                    pharmaceuticals, heavy
                    <br />{" "}
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;contamination,
                    Micro-organisms, Bacteria, Viruses, algae, fungi.
                  </p>
                </div>
              </div>
            </Col>
          </Row>

          <Row>
            <Col md={6}>
              <Container>
                <div className="cardfil">
                  <p style={{ fontSize: "20px", textAlign: "justify" }}>
                    <span
                      style={{
                        fontSize: "40px",
                        fontWeight: "600",
                        color: "#0082bc",
                      }}
                    >
                      Carbon block{" "}
                    </span>
                    <br />
                    Activated carbon was made into a block form has a larger
                    surfer area than partice -type activated carbon. it has a
                    very high adsorption rate. it works to filter residual
                    chlorine, organic compounds and heavy metals by activated
                    adsorption
                  </p>
                </div>

                <br />
                <div className="cardfil">
                  <p style={{ fontSize: "20px", textAlign: "justify" }}>
                    <span
                      style={{
                        fontSize: "40px",
                        fontWeight: "600",
                        color: "#0082bc",
                      }}
                    >
                      Membrane{" "}
                    </span>
                    <br />
                    Pretretment filtration function for filtering various
                    precipitates contained in raw water
                  </p>
                </div>

                <br />
                <div className="cardfil">
                  <p style={{ fontSize: "20px", textAlign: "justify" }}>
                    <span
                      style={{
                        fontSize: "40px",
                        fontWeight: "600",
                        color: "#0082bc",
                      }}
                    >
                      Calcium sulfite
                    </span>{" "}
                    <br />
                    Removes residual chlorine completely by oxidation reduction
                    reaction
                  </p>
                </div>
              </Container>
            </Col>
            <Col md={6}>
              <Container>
                <div className="cardfil">
                  <p
                    style={{
                      fontSize: "20px",
                      textAlign: "justify",
                      margin: "20px",
                    }}
                  >
                    <span
                      style={{
                        fontSize: "40px",
                        fontWeight: "600",
                        color: "#0082bc",
                      }}
                    >
                      TM Ceramic
                    </span>
                    <br />
                    The ceramic were made by mixing the high-emissivity far
                    infrarred minerals containing potassium(k), sodium(Na) and
                    calcium(Ca) with TM and TM - X stock solutions. These multi
                    functional ceramic have antioxidants, antimicrobial and far
                    infared Radiation
                  </p>
                </div>

                <br />
                <div className="cardfil">
                  <p
                    style={{
                      fontSize: "20px",
                      textAlign: "justify",
                      margin: "20px",
                    }}
                  >
                    <span
                      style={{
                        fontSize: "40px",
                        fontWeight: "600",
                        color: "#0082bc",
                      }}
                    >
                      UF
                    </span>
                    <br />
                    Many Fine Pores on thr filter surface remove containment
                    from the water . I removes bad elements such as bacteria
                    viruses and particulates but does not remove substance that
                    are benefical to our body, such as minerals
                  </p>
                </div>
              </Container>
            </Col>
          </Row>
          <hr
            style={{
              height: "1px",
              width: "100%",
              borderWidth: "0",
              backgroundColor: "#0082bc",
            }}
          />

          <p
            id="piah"
            style={{
              textAlign: "center",
              color: "#0082bc",
              lineHeight: 1.4,
              fontWeight: "600",
              wordSpacing: "5px",
            }}
          >
            <span>
              {" "}
              Tyent water ionizer has more than 60+ International patents{" "}
            </span>
            <br />
            <span style={{ fontWeight: "600" }}>
              and certified by all International quality certification
              authorities.
            </span>
          </p>
          <br />
          <Container>
            <center>
              <Row className="justify-content-around  text-align-center space-evenly">
                <div id="gridcert">
                  <div>
                    <img
                      src={require("../asserts/1.png")}
                      width="90%"
                      alt="map"
                    />
                  </div>
                  <div>
                    <img
                      src={require("../asserts/2.png")}
                      width="90%"
                      alt="map2"
                    />
                  </div>
                  <div>
                    <img
                      src={require("../asserts/3.png")}
                      width="90%"
                      alt="map3"
                    />
                  </div>
                  <div>
                    <img
                      src={require("../asserts/4.png")}
                      width="80%"
                      alt="map4"
                    />
                  </div>
                  <div>
                    <img
                      src={require("../asserts/5.png")}
                      width="80%"
                      alt="map5"
                    />
                  </div>
                  <div>
                    <img
                      src={require("../asserts/ce.png")}
                      width="70%"
                      alt="map6"
                    />
                  </div>
                  <div>
                    <img
                      src={require("../asserts/ul.png")}
                      width="100%"
                      alt="map6"
                    />
                  </div>
                  <div>
                    <img
                      src={require("../asserts/fda.png")}
                      width="80%"
                      alt="map6"
                    />
                  </div>
                  <div>
                    <img
                      src={require("../asserts/food.png")}
                      width="100%"
                      alt="map6"
                    />
                  </div>
                  <div>
                    <img
                      src={require("../asserts/B+.png")}
                      width="100%"
                      alt="map3"
                    />
                  </div>
                </div>
              </Row>
              <p style={{ color: "#0082bc", fontSize: "18px" }}>
                Click here to view our Prestigious certification
              </p>
              <br />
            </center>
          </Container>
        </div>
        <Footer />
      </div>
    );
  }
}

export default nmp5;
