import React from "react";
import { Button, Row, Col, Container } from "react-bootstrap";
import { FaPhoneAlt } from "react-icons/fa";

class soap extends React.Component {
  state = {
    products: [
      {
        src: [
          "https://firebasestorage.googleapis.com/v0/b/tyent-fa17c.appspot.com/o/soap%2Ftm-x-beauty-soap.jpg?alt=media&token=323b6a21-4fb3-4809-8858-fdcbc4e9b4c1",
        ],
      },
    ],
    index: 0,
  };

  myRef = React.createRef();

  handleTab = (index) => {
    this.setState({ index: index });
    const image = this.myRef.current.children;
    for (let i = 0; i < image.length; i++) {
      image[i].className = image[i].className.replace("active", "");
    }
    image[index].className = "active";
  };

  componentDidMount() {
    const { index } = this.state;
    this.myRef.current.children[index].className = "active";
  }

  render() {
    const { products, index } = this.state;

    return (
      <div>
        <div>
          <img
            src={require("../asserts/products/soap/tyentSoapBG.jpg")}
            alt="TM-X_Beauty_Soap"
            width={"100%"}
          />
        </div>

        <Container>
          <Row
            className="d-flex justify-content-center"
            style={{ backgroundColor: "#FFF", borderRadius: "8px" }}
          >
            <Col
              md={6}
              className="d-flex justify-content-center align-items-center"
            >
              <br />
              <div
                style={{ width: "100%", margin: "30px", position: "relative" }}
              >
                {products.map((item) => (
                  <div className="details" key={item.src}>
                    <div className="d-flex justify-content-center align-items-center">
                      <img
                        src={item.src[index]}
                        alt=""
                        width="100%"
                        className="pi"
                      />
                    </div>
                    <div
                      className="thumb justify-content-center align-items-center"
                      ref={this.myRef}
                    >
                      {item.src.map((img, index) => (
                        <img
                          src={img}
                          alt=""
                          key={index}
                          onClick={() => this.handleTab(index)}
                        />
                      ))}
                    </div>
                  </div>
                ))}
              </div>
              <br />
            </Col>
            <Col md={6} className="d-flex" style={{ backgroundColor: "#FFF" }}>
              <div>
                <br />
                <h2
                  style={{
                    color: "#000",
                    textAlign: "left",
                    fontSize: "50px",
                    justifyContent: "center",
                  }}
                >
                  <b>Tyent TM-X Beauty Soap </b>
                </h2>
                <h3
                  style={{
                    color: "#000",
                    textAlign: "left",
                    fontSize: "40px",
                    justifyContent: "center",
                  }}
                >
                  M.R.P : <span style={{ color: "#ff0000" }}> ₹ 999/- </span>
                </h3>
                <br />
                <table id="customers">
                  <tbody style={{ textAlign: "left" }}>
                    <tr>
                      <td>
                        <b>Name</b>{" "}
                      </td>
                      <td></td>
                      <td>:&nbsp;soap</td>
                    </tr>
                    <tr>
                      <td>
                        <b>Description</b>{" "}
                      </td>
                      <td></td>
                      <td style={{ textAlign: "justify" }}>
                        Tyent TM-X Beauty Soap is made best ingredients which
                        can maintain the delicate balance of oil and moisture
                        that is required for healthy skin. This is made possible
                        through a 600-hours TM fermentation method, Resulting in
                        a premium cleansing soap that is truly natural.
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <b>Weight</b>{" "}
                      </td>
                      <td></td>
                      <td>:&nbsp;80g</td>
                    </tr>
                  </tbody>
                </table>

                <Button
                  variant="contained"
                  style={{
                    color: "#FFF",
                    float: "right",
                    backgroundColor: "#0082bc",
                    textTransform: "none",
                    margin: "20px",
                  }}
                >
                  <FaPhoneAlt />
                  &nbsp;&nbsp;Call To Order
                </Button>
              </div>
            </Col>
          </Row>
        </Container>

        <img
          src={require("../asserts/products/soap/TM-X_Beauty_Soap_Details_Page_01.jpg")}
          alt="TM-X_Beauty_Soap_Details_Page_01"
          width={"100%"}
        />
        <img
          src={require("../asserts/products/soap/TM-X_Beauty_Soap_Details_Page_02.jpg")}
          alt="TM-X_Beauty_Soap_Details_Page_02"
          width={"100%"}
        />
        <img
          src={require("../asserts/products/soap/TM-X_Beauty_Soap_Details_Page_03.jpg")}
          alt="TM-X_Beauty_Soap_Details_Page_03"
          width="100%"
        />
        <img
          src={require("../asserts/products/soap/TM-X_Beauty_Soap_Details_Page_04.jpg")}
          alt="TM-X_Beauty_Soap_Details_Page_04"
          width="100%"
        />
        <img
          src={require("../asserts/products/soap/TM-X_Beauty_Soap_Details_Page_05.jpg")}
          alt="TM-X_Beauty_Soap_Details_Page_05"
          width="100%"
        />
        <img
          src={require("../asserts/products/soap/TM-X_Beauty_Soap_Details_Page_06.jpg")}
          alt="TM-X_Beauty_Soap_Details_Page_06"
          width="100%"
        />
        <img
          src={require("../asserts/products/soap/TM-X_Beauty_Soap_Details_Page_07.jpg")}
          alt="TM-X_Beauty_Soap_Details_Page_07"
          width="100%"
        />
        <img
          src={require("../asserts/products/soap/TM-X_Beauty_Soap_Details_Page_08.jpg")}
          alt="TM-X_Beauty_Soap_Details_Page_08"
          width="100%"
        />
        <img
          src={require("../asserts/products/soap/TM-X_Beauty_Soap_Details_Page_09.jpg")}
          alt="TM-X_Beauty_Soap_Details_Page_09"
          width="100%"
        />
        <img
          src={require("../asserts/products/soap/TM-X_Beauty_Soap_Details_Page_10.jpg")}
          alt="TM-X_Beauty_Soap_Details_Page_10"
          width="100%"
        />
        <img
          src={require("../asserts/products/soap/TM-X_Beauty_Soap_Details_Page_11.jpg")}
          alt="TM-X_Beauty_Soap_Details_Page_11"
          width="100%"
        />
        <img
          src={require("../asserts/products/soap/TM-X_Beauty_Soap_Details_Page_12.jpg")}
          alt="TM-X_Beauty_Soap_Details_Page_12"
          width="100%"
        />
        <img
          src={require("../asserts/products/soap/TM-X_Beauty_Soap_Details_Page_13.jpg")}
          alt="TM-X_Beauty_Soap_Details_Page_13"
          width="100%"
        />
      </div>
    );
  }
}

export default soap;
