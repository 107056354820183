import React from "react";
import { Col, Row, Table } from "react-bootstrap";

const PriceComp = () => {
  return (
    <div>
      <h1 style={{ color: "#0082bc", textAlign: "center" }}>
        TYENT{" "}
        <span
          style={{
            fontSize: "50px",
            fontWeight: "800",
            textShadow: "4px 4px #000",
            transform: "rotate(-90deg)",
          }}
        >
          VS
        </span>{" "}
        KANGEN PRICE COMPARSION
      </h1>
      <Row>
        <Col
          xs={6}
          className="d-flex align-items-center justify-content-center text-center"
        >
          <Table
            bordered
            hover
            style={{
              borderCollapse: "collapse",
              borderSpacing: "0",
              width: "100%",
              border: "1px solid #ddd",
              backgroundColor: "#FFF",
              color: "#000",
            }}
          >
            <thead>
              <tr>
                <th
                  id="priceth"
                  style={{
                    textAlign: "center",
                    backgroundColor: "#c3e6fa",
                    color: "#008ac7",
                    fontSize: "20px",
                  }}
                  colSpan="2"
                >
                  <b>TYENT NMP-5</b>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr style={{ border: "1px solid #ddd" }}>
                <td id="pricetd">Price:</td>
                <td id="pricetd">160,000/-</td>
              </tr>
              <tr style={{ border: "1px solid #ddd" }}>
                <td id="pricetd">Generates:</td>
                <td id="pricetd">7 Water types</td>
              </tr>
              <tr style={{ border: "1px solid #ddd" }}>
                <td id="pricetd">Plates:</td>
                <td id="pricetd">5</td>
              </tr>
              <tr style={{ border: "1px solid #ddd" }}>
                <td id="pricetd">ORP:</td>
                <td id="pricetd">-850</td>
              </tr>
              <tr style={{ border: "1px solid #ddd" }}>
                <td id="pricetd">Warranty:</td>
                <td id="pricetd">3 yr & 15 yr on plates</td>
              </tr>
            </tbody>
          </Table>
        </Col>
        <Col
          xs={6}
          className="d-flex align-items-center justify-content-center text-center"
        >
          <Table
            bordered
            hover
            style={{
              borderCollapse: "collapse",
              borderSpacing: "0",
              width: "100%",
              border: "1px solid #ddd",
              backgroundColor: "#FFF",
              color: "#000",
            }}
          >
            <thead>
              <tr>
                <th
                  id="priceth"
                  style={{
                    textAlign: "center",
                    backgroundColor: "#d9dbda",
                    color: "#000",
                    fontSize: "20px",
                  }}
                  colSpan="2"
                >
                  <b>ENAGIC KANGEN Jr4</b>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr style={{ border: "1px solid #ddd" }}>
                <td id="pricetd">Price:</td>
                <td id="pricetd">218,000/-</td>
              </tr>
              <tr style={{ border: "1px solid #ddd" }}>
                <td id="pricetd">Generates:</td>
                <td id="pricetd">5 Water types</td>
              </tr>
              <tr style={{ border: "1px solid #ddd" }}>
                <td id="pricetd">Plates:</td>
                <td id="pricetd">4</td>
              </tr>
              <tr style={{ border: "1px solid #ddd" }}>
                <td id="pricetd">ORP:</td>
                <td id="pricetd">-450</td>
              </tr>
              <tr style={{ border: "1px solid #ddd" }}>
                <td id="pricetd">Warranty:</td>
                <td id="pricetd">3 years.</td>
              </tr>
            </tbody>
          </Table>
        </Col>
      </Row>

      <Row>
        <Col
          xs={6}
          className="d-flex align-items-center justify-content-center text-center"
        >
          <Table
            bordered
            hover
            style={{
              borderCollapse: "collapse",
              borderSpacing: "0",
              width: "100%",
              border: "1px solid #ddd",
              backgroundColor: "#FFF",
              color: "#000",
            }}
          >
            <thead>
              <tr>
                <th
                  id="priceth"
                  style={{
                    textAlign: "center",
                    backgroundColor: "#c3e6fa",
                    color: "#008ac7",
                    fontSize: "20px",
                  }}
                  colSpan="2"
                >
                  <b>TYENT NMP-7</b>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr style={{ border: "1px solid #ddd" }}>
                <td id="pricetd">Price:</td>
                <td id="pricetd">195,000/-</td>
              </tr>
              <tr style={{ border: "1px solid #ddd" }}>
                <td id="pricetd">Generates:</td>
                <td id="pricetd">7 Water types</td>
              </tr>
              <tr style={{ border: "1px solid #ddd" }}>
                <td id="pricetd">Plates:</td>
                <td id="pricetd">7</td>
              </tr>
              <tr style={{ border: "1px solid #ddd" }}>
                <td id="pricetd">ORP:</td>
                <td id="pricetd">-950</td>
              </tr>
              <tr style={{ border: "1px solid #ddd" }}>
                <td id="pricetd">Warranty:</td>
                <td id="pricetd">3 yr & 15 yr on plates</td>
              </tr>
            </tbody>
          </Table>
        </Col>
        <Col
          xs={6}
          className="d-flex align-items-center justify-content-center text-center"
        >
          <Table
            bordered
            hover
            style={{
              borderCollapse: "collapse",
              borderSpacing: "0",
              width: "100%",
              border: "1px solid #ddd",
              backgroundColor: "#FFF",
              color: "#000",
            }}
          >
            <thead>
              <tr>
                <th
                  id="priceth"
                  style={{
                    textAlign: "center",
                    backgroundColor: "#d9dbda",
                    color: "#000",
                    fontSize: "20px",
                  }}
                  colSpan="2"
                >
                  <b>ENAGIC KANGEN SD501</b>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr style={{ border: "1px solid #ddd" }}>
                <td id="pricetd">Price:</td>
                <td id="pricetd">277,000/-</td>
              </tr>
              <tr style={{ border: "1px solid #ddd" }}>
                <td id="pricetd">Generates:</td>
                <td id="pricetd">5 Water types</td>
              </tr>
              <tr style={{ border: "1px solid #ddd" }}>
                <td id="pricetd">Plates:</td>
                <td id="pricetd">7</td>
              </tr>
              <tr style={{ border: "1px solid #ddd" }}>
                <td id="pricetd">ORP:</td>
                <td id="pricetd">-631</td>
              </tr>
              <tr style={{ border: "1px solid #ddd" }}>
                <td id="pricetd">Warranty:</td>
                <td id="pricetd">5 years.</td>
              </tr>
            </tbody>
          </Table>
        </Col>
      </Row>

      <Row>
        <Col
          xs={6}
          className="d-flex align-items-center justify-content-center text-center"
        >
          <Table
            bordered
            hover
            style={{
              borderCollapse: "collapse",
              borderSpacing: "0",
              width: "100%",
              border: "1px solid #ddd",
              backgroundColor: "#FFF",
              color: "#000",
            }}
          >
            <thead>
              <tr>
                <th
                  id="priceth"
                  style={{
                    textAlign: "center",
                    backgroundColor: "#c3e6fa",
                    color: "#008ac7",
                    fontSize: "20px",
                  }}
                  colSpan="2"
                >
                  <b>TYENT NMP-9</b>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr style={{ border: "1px solid #ddd" }}>
                <td id="pricetd">Price:</td>
                <td id="pricetd">227,000/-</td>
              </tr>
              <tr style={{ border: "1px solid #ddd" }}>
                <td id="pricetd">Generates:</td>
                <td id="pricetd">7 Water types</td>
              </tr>
              <tr style={{ border: "1px solid #ddd" }}>
                <td id="pricetd">Plates:</td>
                <td id="pricetd">9</td>
              </tr>
              <tr style={{ border: "1px solid #ddd" }}>
                <td id="pricetd">ORP:</td>
                <td id="pricetd">-1050</td>
              </tr>
              <tr style={{ border: "1px solid #ddd" }}>
                <td id="pricetd">Warranty:</td>
                <td id="pricetd">3 yr & 15 yr on plates</td>
              </tr>
            </tbody>
          </Table>
        </Col>
        <Col
          xs={6}
          className="d-flex align-items-center justify-content-center text-center"
        >
          <Table
            bordered
            hover
            style={{
              borderCollapse: "collapse",
              borderSpacing: "0",
              width: "100%",
              border: "1px solid #ddd",
              backgroundColor: "#FFF",
              color: "#000",
            }}
          >
            <thead>
              <tr>
                <th
                  id="priceth"
                  style={{
                    textAlign: "center",
                    backgroundColor: "#d9dbda",
                    color: "#000",
                    fontSize: "20px",
                  }}
                  colSpan="2"
                >
                  <b>ENAGIC KANGEN k8</b>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr style={{ border: "1px solid #ddd" }}>
                <td id="pricetd">Price:</td>
                <td id="pricetd">3,45,000/-</td>
              </tr>
              <tr style={{ border: "1px solid #ddd" }}>
                <td id="pricetd">Generates:</td>
                <td id="pricetd">5 Water types</td>
              </tr>
              <tr style={{ border: "1px solid #ddd" }}>
                <td id="pricetd">Plates:</td>
                <td id="pricetd">8</td>
              </tr>
              <tr style={{ border: "1px solid #ddd" }}>
                <td id="pricetd">ORP:</td>
                <td id="pricetd">-722</td>
              </tr>
              <tr style={{ border: "1px solid #ddd" }}>
                <td id="pricetd">Warranty:</td>
                <td id="pricetd">5 years.</td>
              </tr>
            </tbody>
          </Table>
        </Col>
      </Row>
      <Row>
        <Col
          xs={6}
          className="d-flex align-items-center justify-content-center text-center"
        >
          <Table
            bordered
            hover
            style={{
              borderCollapse: "collapse",
              borderSpacing: "0",
              width: "100%",
              border: "1px solid #ddd",
              backgroundColor: "#FFF",
              color: "#000",
            }}
          >
            <thead>
              <tr>
                <th
                  id="priceth"
                  style={{
                    textAlign: "center",
                    backgroundColor: "#c3e6fa",
                    color: "#008ac7",
                    fontSize: "20px",
                  }}
                  colSpan="2"
                >
                  <b>TYENT NMP-11</b>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr style={{ border: "1px solid #ddd" }}>
                <td id="pricetd">Price:</td>
                <td id="pricetd">260,000/-</td>
              </tr>
              <tr style={{ border: "1px solid #ddd" }}>
                <td id="pricetd">Generates:</td>
                <td id="pricetd">7 Water types</td>
              </tr>
              <tr style={{ border: "1px solid #ddd" }}>
                <td id="pricetd">Plates:</td>
                <td id="pricetd">11</td>
              </tr>
              <tr style={{ border: "1px solid #ddd" }}>
                <td id="pricetd">ORP:</td>
                <td id="pricetd">-1200</td>
              </tr>
              <tr style={{ border: "1px solid #ddd" }}>
                <td id="pricetd">Warranty:</td>
                <td id="pricetd">3 yr & 15 yr on plates</td>
              </tr>
            </tbody>
          </Table>
        </Col>
        <Col
          xs={6}
          className="d-flex align-items-center justify-content-center text-center"
        >
          <Table
            bordered
            hover
            style={{
              borderCollapse: "collapse",
              borderSpacing: "0",
              width: "100%",
              border: "1px solid #ddd",
              backgroundColor: "#FFF",
              color: "#000",
            }}
          >
            <thead>
              <tr>
                <th
                  id="priceth"
                  style={{
                    textAlign: "center",
                    backgroundColor: "#d9dbda",
                    color: "#000",
                    fontSize: "20px",
                  }}
                  colSpan="2"
                >
                  <b>ENAGIC KANGEN SUPER SD501</b>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr style={{ border: "1px solid #ddd" }}>
                <td id="pricetd">Price:</td>
                <td id="pricetd">378,000/-</td>
              </tr>
              <tr style={{ border: "1px solid #ddd" }}>
                <td id="pricetd">Generates:</td>
                <td id="pricetd">5 Water types</td>
              </tr>
              <tr style={{ border: "1px solid #ddd" }}>
                <td id="pricetd">Plates:</td>
                <td id="pricetd">12</td>
              </tr>
              <tr style={{ border: "1px solid #ddd" }}>
                <td id="pricetd">ORP:</td>
                <td id="pricetd">-800</td>
              </tr>
              <tr style={{ border: "1px solid #ddd" }}>
                <td id="pricetd">Warranty:</td>
                <td id="pricetd">3 years.</td>
              </tr>
            </tbody>
          </Table>
        </Col>
      </Row>
    </div>
  );
};

export default PriceComp;
