import React, { useEffect } from "react";
import Navbar from "../components/Navbar";
import "../style/BenfitsPage.css";
import Footer from "../components/Footer";
import { Col, Container, Row } from "react-bootstrap";
import { FaCheckCircle } from "react-icons/fa";
import Formd from "../components/Formd";
import { useLocation } from "react-router-dom";
import ReactGA from "react-ga";
import { Helmet } from "react-helmet";

const BenfitsPage = () => {
  const location = useLocation();

  useEffect(() => {
    ReactGA.initialize("G-FP7N65QWQ6");
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return (
    <div style={{ backgroundColor: "#ebecf1" }}>
      <Helmet>
        <title>Drink Tyent Ionizer Water to Improve Your Health.</title>
        <meta
          name="description"
          content="Drink Tyent Ionizer Water to Improve Your Health."
        />
      </Helmet>
      <center>
        <div
          className="container-fluid"
          style={{ justifyContent: "center", textAlign: "center" }}
        >
          <br />
          <div className="hero" style={{ padding: "20px" }}>
            <h1>
              Drinking{" "}
              <span style={{ fontSize: "40px" }}>
                <b>TYENT</b>
              </span>{" "}
              water can improve your health in many ways.
            </h1>
            <p1 style={{ color: "#000", fontSize: "20px" }}>
              Here is a list of ways you and your family can benefits from Tyent
              ionizer
            </p1>
          </div>

          <div className="container">
            <Row>
              <Col md={6} style={{ align: "center", float: "center" }}>
                <center>
                  <div>
                    <div className="gridbenfits">
                      <div className="boxbenfits">
                        <p className="textbenfits">
                          <FaCheckCircle /> Anti-oxidant.
                        </p>
                      </div>
                      <div className="boxbenfits">
                        <p className="textbenfits">
                          <FaCheckCircle /> Anti-Inflammatory.
                        </p>
                      </div>
                      <div className="boxbenfits">
                        <p className="textbenfits">
                          <FaCheckCircle /> Anti-allergic.
                        </p>
                      </div>
                      <div className="boxbenfits">
                        <p className="textbenfits">
                          <FaCheckCircle /> Anti-apoptotic.
                        </p>
                      </div>
                      <div className="boxbenfits">
                        <p className="textbenfits">
                          <FaCheckCircle /> Micro - clurstreing.
                        </p>
                      </div>
                      <div className="boxbenfits">
                        <p className="textbenfits">
                          <FaCheckCircle /> Anti-aging.
                        </p>
                      </div>
                      <div className="boxbenfits">
                        <p className="textbenfits">
                          <FaCheckCircle /> Micro - clurstreing.{" "}
                        </p>
                      </div>
                      <div className="boxbenfits">
                        <p className="textbenfits">
                          <FaCheckCircle /> Colon cleansing.
                        </p>
                      </div>
                      <div className="boxbenfits">
                        <p className="textbenfits">
                          <FaCheckCircle /> Boosts immunity.
                        </p>
                      </div>
                      <div className="boxbenfits">
                        <p className="textbenfits">
                          <FaCheckCircle /> Improved hydration.
                        </p>
                      </div>
                      <div className="boxbenfits">
                        <p className="textbenfits">
                          <FaCheckCircle /> Promotes weight loss.
                        </p>
                      </div>
                      <div className="boxbenfits">
                        <p className="textbenfits">
                          <FaCheckCircle /> Anti- Cancer properties.
                        </p>
                      </div>
                    </div>
                  </div>
                </center>
              </Col>
              <Col
                md={6}
                className="d-flex align-items-center justify-content-center"
              >
                <img
                  src={require("../asserts/benfitsbanner.webp")}
                  width="100%"
                  alt="Loading...."
                />
              </Col>
            </Row>
          </div>
          <br />
          <br />
          <p style={{ color: "#0082bc", fontSize: "30px", fontWeight: "500" }}>
            Discover the amazing health benifits of TYENT water like
          </p>

          <Container>
            <div className="gridb">
              <div className="boxb">
                <img
                  class="center"
                  src={require("../asserts/benifits1.jpg")}
                  alt=""
                  style={{
                    width: "100%",
                    borderRadius: "3%",
                    display: "block",
                  }}
                />
              </div>
              <div className="boxb">
                <img
                  src={require("../asserts/benifits2.jpg")}
                  alt=""
                  style={{ width: "100%", borderRadius: "3%" }}
                />
              </div>
              <div className="boxb">
                <img
                  src={require("../asserts/benifits3.jpg")}
                  alt=""
                  style={{ width: "100%", borderRadius: "3%" }}
                />
              </div>
              <div className="boxb">
                <img
                  src={require("../asserts/benifits4.jpg")}
                  alt=""
                  style={{ width: "100%", borderRadius: "3%" }}
                />
              </div>
              <div className="boxb">
                <img
                  src={require("../asserts/benifits5.jpg")}
                  alt=""
                  style={{ width: "100%", borderRadius: "3%" }}
                />
              </div>
              <div className="boxb">
                <img
                  src={require("../asserts/benifits6.jpg")}
                  alt=""
                  style={{ width: "100%", borderRadius: "3%" }}
                />
              </div>
              <div className="boxb">
                <img
                  src={require("../asserts/benifits7.jpg")}
                  alt=""
                  style={{ width: "100%", borderRadius: "3%" }}
                />
              </div>
              <div className="boxb">
                <img
                  src={require("../asserts/benifits8.jpg")}
                  alt=""
                  style={{ width: "100%", borderRadius: "3%" }}
                />
              </div>
              <div className="boxb">
                <img
                  src={require("../asserts/benifits9.jpg")}
                  alt=""
                  style={{ width: "100%", borderRadius: "3%" }}
                />
              </div>
              <div className="boxb">
                <img
                  src={require("../asserts/benifits10.jpg")}
                  alt=""
                  style={{ width: "100%", borderRadius: "3%" }}
                />
              </div>
              <div className="boxb">
                <img
                  src={require("../asserts/benifits11.jpg")}
                  alt=""
                  style={{ width: "100%", borderRadius: "3%" }}
                />
              </div>
              <div className="boxb">
                <img
                  src={require("../asserts/benifits12.jpg")}
                  alt=""
                  style={{ width: "100%", borderRadius: "3%" }}
                />
              </div>
            </div>
          </Container>
        </div>
      </center>

      <br />

      <Container>
        <Row className="d-flex align-items-center justify-content-center">
          <Col md={4} className="align-items-center justify-content-center">
            <div>
              <p
                style={{
                  color: "#0082bc",
                  fontSize: "30px",
                  fontWeight: "600",
                  textAlign: "left",
                  padding: "0px",
                  margin: "0px",
                }}
              >
                Lifestyle Diseases
              </p>
              <p style={{ fontSize: "20px", padding: "0px", margin: "0px" }}>
                Controls
              </p>
              <ul style={{ fontSize: "20px", margin: "0px" }}>
                <li style={{ padding: "0px", margin: "0px" }}>
                  Type 2 Diabetes
                </li>
                <li style={{ padding: "0px", margin: "0px" }}>
                  obesity & Body Weight
                </li>
                <li style={{ padding: "0px", margin: "0px" }}>Hypertension</li>
              </ul>
              <br />
            </div>
          </Col>

          <Col md={4} className="align-items-center justify-content-center">
            <div>
              <p
                style={{
                  color: "#0082bc",
                  fontSize: "30px",
                  fontWeight: "600",
                  textAlign: "left",
                  padding: "0px",
                  margin: "0px",
                }}
              >
                Skin Defence
              </p>
              <p style={{ fontSize: "20px", padding: "0px", margin: "0px" }}>
                Treats
              </p>
              <ul style={{ fontSize: "20px" }}>
                <li>Eczema</li>
                <li>Skin infections - Psoriasis</li>
                <li>Skin disease realted to - Diabetes</li>
              </ul>
              <br />
            </div>
          </Col>
          <Col md={4} className="align-items-center justify-content-center">
            <div>
              <p
                style={{
                  color: "#0082bc",
                  fontSize: "30px",
                  fontWeight: "600",
                  textAlign: "left",
                  padding: "0px",
                  margin: "0px",
                }}
              >
                Beauty Care
              </p>
              <p style={{ fontSize: "20px", padding: "0px", margin: "0px" }}>
                Anti
              </p>
              <ul style={{ fontSize: "20px" }}>
                <li>Wrinkle Formation</li>
                <li>Allergies (Rash, Acne)</li>
                <li>Ageing (Increase Longevity)</li>
              </ul>
              <br />
            </div>
          </Col>
        </Row>
        <Row>
          <Col md={4} className="align-items-center justify-content-center">
            <div>
              <p
                style={{
                  color: "#0082bc",
                  fontSize: "30px",
                  fontWeight: "600",
                  textAlign: "left",
                  padding: "0px",
                  margin: "0px",
                }}
              >
                Physical Fitness
              </p>
              <p style={{ fontSize: "20px", padding: "0px", margin: "0px" }}>
                Improves
              </p>
              <ul style={{ fontSize: "20px" }}>
                <li>Overall Energy Levels</li>
                <li>Hydration in athletes</li>
                <li>Muscle Fatigue after acute exercise</li>
              </ul>
            </div>
          </Col>
          <Col md={4} className="align-items-center justify-content-center">
            <div>
              <p
                style={{
                  color: "#0082bc",
                  fontSize: "30px",
                  fontWeight: "600",
                  textAlign: "left",
                  padding: "0px",
                  margin: "0px",
                }}
              >
                Digestive Health
              </p>
              <p style={{ fontSize: "20px", padding: "0px", margin: "0px" }}>
                Relieves
              </p>
              <ul style={{ fontSize: "20px" }}>
                <li>Acid Reflux Disease</li>
                <li>Irritable Bowel Syndrome</li>
                <li>Constipation</li>
                <li>General Acidity</li>
              </ul>
            </div>
          </Col>
          <Col md={4} className="align-items-center justify-content-center">
            <div>
              <p
                style={{
                  color: "#0082bc",
                  fontSize: "30px",
                  fontWeight: "600",
                  textAlign: "left",
                  padding: "0px",
                  margin: "0px",
                }}
              >
                General Wellness
              </p>
              <p style={{ fontSize: "20px", padding: "0px", margin: "0px" }}>
                Used For
              </p>
              <ul style={{ fontSize: "20px" }}>
                <li>Oral Care</li>
                <li>Boosting Immunity</li>
                <li>Reducing effects of Radiation / Chemotherapy</li>
                <li>Regaining muscle strength in old people</li>
                <li>Rheumatoid Arthritis</li>
              </ul>
            </div>
          </Col>
        </Row>
      </Container>

      <Formd />
      <Footer />
    </div>
  );
};

export default BenfitsPage;
