import { Container } from "react-bootstrap";
import Footer from "../components/Footer";
import React, { useEffect } from "react";
import ReactGA from "react-ga";
import { useLocation } from "react-router-dom";

const Plates = () => {
  useEffect(() => {
    ReactGA.initialize("G-FP7N65QWQ6");
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);


  
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return (
    <div>
      <img
        src={require("../asserts/SOLIDHYBRIDMESHPLATES.webp")}
        alt="plates banner"
        width={"100%"}
      />
      <Container>
        <h3 style={{ color: "#0082bc", paddingTop: "20px" }}>
          <b>Electrodes/Plates: The Heart of the Ionizer</b>
        </h3>
        <br />
        <p style={{ textAlign: "justify" }}>
          Electrodes or plates are one of the most important components of a
          water ionizer. These plates are typically made of metals like titanium
          and are coated with a specialized material like platinum, which helps
          to facilitate the ionization process. When water passes over the
          plates, an electric current is passed through the water, creating a
          separation of positive and negative ions.
        </p>
        <br />
        <p style={{ textAlign: "justify" }}>
          The number and quality of the plates used in a water ionizer can
          greatly impact the effectiveness and efficiency of the ionization
          process. More plates typically mean a higher ionization potential,
          which can lead to a more effective hydrogen-rich alkaline water
          production. Additionally, the quality of the materials used to make
          the plates can affect their durability and performance over time.
        </p>
        <br />
        <p style={{ textAlign: "justify" }}>
          Therefore, it's essential to choose a water ionizer that features
          high-quality electrodes or plates to ensure the best possible
          hydrogen-rich alkaline water production. This will not only lead to
          better health benefits but also long-term cost savings by reducing the
          need for expensive bottled water.
        </p>
        <br />
        <h3 style={{ color: "#0082bc", paddingTop: "20px" }}>
          <b>Tyent water ionizer plates:</b>
        </h3>
        <br />
        <p style={{ textAlign: "justify" }}>
          Tyent is a leading manufacturer of water ionizers known for its
          advanced electrode or plate technology. Tyent ionizers feature Solid
          hybrid Mesh plates, which combine the best of both worlds to deliver
          exceptional performance. These plates are made of medical-grade
          titanium and are coated with platinum to enhance their conductivity.
        </p>
        <br />
        <p style={{ textAlign: "justify" }}>
          Tyent uses advanced Japanese technology which is the latest & most
          advanced in the entire water ionizer industry. Tyent gets its plates
          from Permelec, a Japanese company known for producing high-quality
          electrodes for various health, industrial, and scientific
          applications.
        </p>
        <br />
        <p style={{ textAlign: "justify" }}>
          Permelec has a reputation for producing some of the best electrodes in
          the world, thanks to its cutting-edge technology and strict quality
          control processes. Their electrodes are made from high-grade materials
          like titanium and platinum, which are known for their superior
          conductivity and resistance to corrosion.
        </p>
        <br />
        <p style={{ textAlign: "justify" }}>
          By sourcing their plates from a reputable manufacturer like Permelec,
          Tyent is able to ensure that their ionizers feature some of the
          highest-quality plates available on the market. This not only leads to
          better hydrogen-rich alkaline water production with more anti-oxidants
          but also ensures that their ionizers are built to last and require
          minimal maintenance over time. Tyent is the only ionizer in the Indian
          market which is offering 15 years warranty on plates.
        </p>
        <br />
        <p style={{ textAlign: "justify" }}>
          Tyent's Solid/Mesh Hybrid plates are known for their durability and
          longevity. They are designed to resist corrosion and scale buildup,
          ensuring that they last longer and require less maintenance over time.
          Additionally, the design of these plates allows for a larger surface
          area, which means that more water comes into contact with the plates
          and is ionized more efficiently.
        </p>
        <p style={{ textAlign: "justify" }}>
          Another key feature of Tyent's plates is their proprietary SmartChip
          technology. This technology monitors the performance of the plates and
          adjusts the power to ensure optimal ionization at all times. This
          means that users can enjoy the benefits of Hydrogen-rich alkaline
          water consistently, without worrying about fluctuations in
          performance. Overall, Tyent's plates are designed to deliver the best
          possible Hydrogen-rich alkaline water production, with exceptional
          durability and long-term performance. When compared to the other
          competitor’s Tyent water ionizer stands unique in its updated solid
          hybrid mesh plate technology with more surface area which resulting
          hydrogen-rich alkaline water with more anti-oxidants.
        </p>
      </Container>
      <Footer />
    </div>
  );
};

export default Plates;
