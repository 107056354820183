import React, {Component} from 'react'
import "../style/SlickSlider.css"
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";



export default class SlickSlider extends Component {


    render() {
      const settings = {
        dots: false,
        className: "center",
        centerMode: true,
        infinite: true,
        centerPadding: "60px",
        arrows: false,
        slidesToShow: 5,
        slidesToScroll: 1,
        autoplay: true,
        speed: 2000,
        autoplaySpeed: 2000,
        cssEase: "linear"
      };
      return (
        <div>
          

          <Slider {...settings} >
            <div className='sliderp'>
              <img src={require('../asserts/logos/1.png')} alt="" width='60%' />
            </div>
            <div className='sliderp'>
            <img src={require('../asserts/logos/2.png')} alt="" width='60%'/>
            </div>
            <div className='sliderp'>
            <img src={require('../asserts/logos/3.png')} alt="" width='60%'/>
            </div>
            <div className='sliderp'>
            <img src={require('../asserts/logos/4.png')} alt="" width='60%'/>
            </div>
            <div className='sliderp'>
            <img src={require('../asserts/logos/5.png')} alt="" width='60%'/>
            </div>
            <div className='sliderp'>
            <img src={require('../asserts/logos/6.png')} alt="" width='60%'/>
            </div>
            <div className='sliderp'>
            <img src={require('../asserts/logos/7.png')} alt="" width='60%'/>
            </div>
            <div className='sliderp'>
            <img src={require('../asserts/logos/8.png')} alt="" width='60%'/>
            </div>
          </Slider>
        </div>
      );
    }
  }
