import React from "react";
import { Row, Col } from "react-grid-system";
import "../style/Uce.css";
import Navbar from "../components/Navbar";
import { Button, Container } from "@material-ui/core";
import { FaPhoneAlt } from "react-icons/fa";
import Footer from "../components/Footer";
import Ucetable from "../components/Ucetable.js";
import Smartfeatures from "../components/Smartfeatures";
import { FaTint } from "react-icons/fa";

class uce11 extends React.Component {
  state = {
    products: [
      {
        src: [
          "https://firebasestorage.googleapis.com/v0/b/tyent-fa17c.appspot.com/o/uce%2FWhatsApp%20Image%202023-05-09%20at%2001.25.53.webp?alt=media&token=0e1f17b2-013c-4fa3-ba3a-96c11177b740",
          "https://firebasestorage.googleapis.com/v0/b/tyent-fa17c.appspot.com/o/uce%2FWhatsApp%20Image%202023-05-13%20at%2010.05.26.webp?alt=media&token=69fe99c8-1125-4a2f-9a0a-1b640548b3a5",
        ],
      },
    ],
    index: 0,
  };

  myRef = React.createRef();

  handleTab = (index) => {
    this.setState({ index: index });
    const image = this.myRef.current.children;
    for (let i = 0; i < image.length; i++) {
      image[i].className = image[i].className.replace("active", "");
    }
    image[index].className = "active";
  };

  componentDidMount() {
    const { index } = this.state;
    this.myRef.current.children[index].className = "active";
  }

  render() {
    const { products, index } = this.state;

    return (
      <div>
        <div className="home__image">
          <img src={require("../asserts/uce/pro_01.png")} alt="" width="100%" />
        </div>

        <Container>
          <Row>
            <Col md={6} className="align-items-center justify-content-center">
              <br />

              <div
                style={{ width: "100%", margin: "30px", position: "relative" }}
              >
                {products.map((item) => (
                  <div className="details" key={item.src}>
                    <div className="big-img">
                      <img src={item.src[index]} alt="" width="75%" />
                    </div>
                    <div className="thumb" ref={this.myRef}>
                      {item.src.map((img, index) => (
                        <img
                          src={img}
                          alt=""
                          key={index}
                          onClick={() => this.handleTab(index)}
                        />
                      ))}
                    </div>
                  </div>
                ))}
              </div>
              <br />
            </Col>
            <Col
              md={6}
              className="d-flex justify-content-center align-items-center"
            >
              <div>
                <br />
                <h2
                  style={{
                    color: "#0082bc",
                    textAlign: "left",
                    fontSize: "45px",
                    justifyContent: "center",
                  }}
                >
                  <b>Tyent UCE 11 plus </b>
                </h2>
                <h3
                  style={{
                    color: "#000",
                    textAlign: "left",
                    fontSize: "40px",
                    justifyContent: "center",
                  }}
                >
                  M.R.P :{" "}
                  <span style={{ color: "#ff0000" }}> ₹ 3,46,000/-</span>
                </h3>
                <br />
                <table id="customers">
                  <tbody style={{ textAlign: "left" }}>
                    <tr>
                      <td>Electrode plates </td>
                      <td></td>
                      <td>:&nbsp;11</td>
                    </tr>
                    <tr>
                      <td>pH range </td>
                      <td></td>
                      <td>:&nbsp;2.5 - 11.5*</td>
                    </tr>
                    <tr>
                      <td>ORP Range </td>
                      <td></td>
                      <td>:&nbsp;up to - 1200*</td>
                    </tr>
                    <tr>
                      <td>Generates </td>
                      <td></td>
                      <td>:&nbsp;8 types of water levels.</td>
                    </tr>
                    <tr>
                      <td>Surface area </td>
                      <td></td>
                      <td>:&nbsp;52.9*11 = 581.9 sq inches</td>
                    </tr>
                    <tr>
                      <td>Warranty </td>
                      <td></td>
                      <td>:&nbsp;3 Years on machine & 15 years on plates</td>
                    </tr>
                    <tr>
                      <td colSpan="3">
                        <h2
                          style={{
                            color: "#000",
                            textAlign: "left",
                            fontSize: "15px",
                          }}
                        >
                          <b>*Depends on input water quality and Flow rate</b>
                        </h2>
                      </td>
                    </tr>
                    <tr>
                      <td colSpan="3">
                        <h2
                          style={{
                            color: "#000",
                            textAlign: "left",
                            fontSize: "15px",
                          }}
                        >
                          <b>
                            (No use of external chemicals, salts, or artificial
                            enhancers)
                          </b>
                        </h2>
                      </td>
                    </tr>
                  </tbody>
                </table>

                <Button
                  variant="contained"
                  style={{
                    color: "#FFF",
                    float: "right",
                    backgroundColor: "#0082bc",
                    textTransform: "none",
                    margin: "20px",
                  }}
                >
                  <FaPhoneAlt />
                  &nbsp;&nbsp;Call To Order
                </Button>
              </div>
            </Col>
          </Row>
        </Container>
        <div>
          <center>
            <br />
            <br />
            <center>
              <p style={{ fontSize: "30px", color: "#0082bc" }}>Introducing</p>
              <br />
              <hr
                style={{
                  height: ".3vh",
                  width: "15vw",
                  borderWidth: "0",
                  backgroundColor: "#0082bc",
                }}
              />
              <br />
              <p id="coloru">Tyent's Luxury Show Room Collection</p>
              <img
                src={require("../asserts/uce/Intro.png")}
                alt=""
                width="40%"
              />
            </center>

            <p
              style={{
                fontSize: "50px",
                fontWeight: "600",
                padding: "20px",
                color: "#000",
              }}
            >
              Your Water, Your Way
            </p>
            <img src={require("../asserts/uce/way.jpg")} alt="" width="80%" />
            <p
              style={{
                fontSize: "40px",
                fontWeight: "600",
                padding: "20px",
                color: "#000",
              }}
            >
              2 WAY Control TOUCH SCREEN or JOG Dial
            </p>
            <p style={{ fontSize: "20px" }}>
              Smart-Phone inspired touch screen interface and jog dial provide
              <br />
              Various operational methods according to the User’s Choice
            </p>
            <br />
            <br />
            <br />
            <br />
            <img src={require("../asserts/uce/cust.jpg")} alt="" width="80%" />
            <p
              style={{
                fontSize: "40px",
                fontWeight: "600",
                padding: "20px",
                color: "#000",
              }}
            >
              First CUSTOMIZABLE Design Display in a water ionizer
            </p>
            <p style={{ fontSize: "20px" }}>
              Choose between Full-Color or Night-Mode displays.
            </p>
            <br />
            <br />
            <br />
            <br />

            <img src={require("../asserts/uce/qty.jpg")} alt="" width="80%" />
            <p
              style={{
                fontSize: "40px",
                fontWeight: "600",
                padding: "20px",
                color: "#000",
              }}
            >
              Quantity selection function
            </p>
            <p style={{ fontSize: "20px" }}>
              It will fill your favorite capacity with one touch according to my
              lifestyle.
              <br />
              You can freely set from a minimum of 0.2L to maximum of 5L by
              turning the Jog dial.
            </p>
            <br />
            <br />
            <br />
            <br />

            <Row>
              <Col
                md={8}
                className="d-flex justify-content-center align-items-center"
              >
                <img
                  src={require("../asserts/uce/EcoLuxary.png")}
                  alt=""
                  width="80%"
                />
              </Col>
              <Col
                md={4}
                className="d-flex justify-content-center align-items-center"
              >
                <div className="oneT">
                  <hr
                    style={{
                      height: "15vh",
                      width: ".5vw",
                      borderWidth: "0",
                      backgroundColor: "#0082bc",
                    }}
                  />
                  <p
                    style={{
                      color: "#000",
                      fontSize: "20px",
                      textAlign: "left",
                      padding: "10px",
                    }}
                  >
                    <span
                      style={{
                        fontSize: "40px",
                        color: "#60B45A",
                        fontWeight: "600",
                      }}
                    >
                      ECO MODE SETTING <br />
                    </span>
                    Save energy by enabling Eco Mode on Your ionizer to only
                    display the current time after non-use!
                  </p>
                </div>
              </Col>
            </Row>
            <Row>
              <Col
                md={8}
                className="d-flex justify-content-center align-items-center"
              >
                <img
                  src={require("../asserts/uce/TurboScreen.png")}
                  alt=""
                  width="80%"
                />
              </Col>
              <Col
                md={4}
                className="d-flex justify-content-center align-items-center"
              >
                <div className="oneT">
                  <hr
                    style={{
                      height: "15vh",
                      width: ".5vw",
                      borderWidth: "0",
                      backgroundColor: "#0082bc",
                    }}
                  />
                  <p
                    style={{
                      color: "#000",
                      fontSize: "20px",
                      textAlign: "justify",
                      padding: "10px",
                    }}
                  >
                    <span id="turbo">
                      TURBO MODE
                      <br />
                    </span>
                    Turbo mode in Tyent is used to produce strong alkaline water
                    & strong acidic water of 11.5 & 2.5 pH. Supports Natural
                    Cleaning for Your Home and Food Safety
                  </p>
                </div>
              </Col>
            </Row>

            <Container>
              <Row>
                <Col
                  md={6}
                  className="d-flex justify-content-center align-items-center"
                >
                  <img
                    src={require("../asserts/uce/pro_03.png")}
                    alt=""
                    width="100%"
                  />
                </Col>
                <Col
                  md={6}
                  className="d-flex justify-content-center align-items-center"
                >
                  <div className="oneT">
                    <hr
                      style={{
                        height: "15vh",
                        width: ".5vw",
                        borderWidth: "0",
                        backgroundColor: "#0082bc",
                      }}
                    />
                    <p
                      style={{
                        color: "#000",
                        fontSize: "20px",
                        textAlign: "justify",
                        padding: "10px",
                      }}
                    >
                      <span
                        style={{
                          color: "#0082bc",
                          fontSize: "40px",
                          fontWeight: "600",
                        }}
                      >
                        Ultimate convenience
                        <br />
                        Rotates 180 and more!
                        <br />
                      </span>
                      Designed for multi-directional polygonal rotation <br />{" "}
                      operation. It can be conveniently used anywhere, and
                      anyhow.
                    </p>
                  </div>
                </Col>
              </Row>
            </Container>

            <Container>
              <Row>
                <Col
                  md={6}
                  className="d-flex justify-content-center align-items-center"
                >
                  <img
                    className="d-flex"
                    src={require("../asserts/uce/pro_4.png")}
                    alt=""
                    width="100%"
                  />
                </Col>
                <Col
                  md={6}
                  className="d-flex justify-content-center align-items-center"
                >
                  <div className="oneT">
                    <hr
                      style={{
                        height: "15vh",
                        width: ".5vw",
                        borderWidth: "0",
                        backgroundColor: "#0082bc",
                      }}
                    />
                    <p
                      style={{
                        color: "#000",
                        fontSize: "20px",
                        textAlign: "justify",
                        padding: "10px",
                      }}
                    >
                      <span
                        style={{
                          color: "#0082bc",
                          fontSize: "40px",
                          fontWeight: "600",
                        }}
                      >
                        Real-time filter usage
                        <br />
                      </span>
                      It is designed to make it easy to know when time to change
                      the filter
                    </p>
                  </div>
                </Col>
              </Row>
            </Container>

            <Container>
              <Row>
                <Col
                  md={6}
                  className="d-flex justify-content-center align-items-center"
                >
                  <img
                    className="d-flex"
                    src={require("../asserts/uce/pro_5.png")}
                    alt=""
                    width="80%"
                  />
                </Col>
                <Col
                  md={6}
                  className="d-flex justify-content-center align-items-center"
                >
                  <div className="oneT">
                    <hr
                      style={{
                        height: "15vh",
                        width: ".5vw",
                        borderWidth: "0",
                        backgroundColor: "#0082bc",
                      }}
                    />
                    <p
                      style={{
                        color: "#000",
                        fontSize: "20px",
                        textAlign: "justify",
                        padding: "10px",
                      }}
                    >
                      <span
                        style={{
                          color: "#0082bc",
                          fontSize: "40px",
                          fontWeight: "600",
                        }}
                      >
                        Automatic Electrolysis Tank Cleaning Function
                        <br />
                      </span>
                      The interior of the Electrolysis Tank is automatically
                      cleaned on a regular basis, ensuring stable electrolysis
                      operating conditions.
                    </p>
                  </div>
                </Col>
              </Row>
            </Container>

            <br />
            <br />
            <Container className="d-flex align-items-center justify-content-center text-center">
              <p>
                <span
                  style={{
                    fontSize: "50px",
                    fontWeight: "600",
                    textAlign: "center",
                    alignItems: "center",
                  }}
                >
                  Easy and Convenient user Interface
                </span>
                <br />
                2-way control methods are provided easy and convenient for
                anyone to use.
                <br />
                <br />
              </p>
            </Container>

            <Container>
              <div id="gridu">
                <div>
                  <img
                    src={require("../asserts/uce/d1.png")}
                    alt=""
                    className="timgu"
                  />
                </div>
                <div>
                  <img
                    src={require("../asserts/uce/d2.png")}
                    alt=""
                    className="timgu"
                  />
                </div>
                <div>
                  <img
                    src={require("../asserts/uce/d3.png")}
                    alt=""
                    className="timgu"
                  />
                </div>
                <div>
                  <img
                    src={require("../asserts/uce/d4.png")}
                    alt=""
                    className="timgu"
                  />
                </div>
                <div>
                  <img
                    src={require("../asserts/uce/d5.png")}
                    alt=""
                    className="timgu"
                  />
                </div>
                <div>
                  <img
                    src={require("../asserts/uce/d6.png")}
                    alt=""
                    className="timgu"
                  />
                </div>
                <div>
                  <img
                    src={require("../asserts/uce/d7.png")}
                    alt=""
                    className="timgu"
                  />
                </div>
                <div>
                  <img
                    src={require("../asserts/uce/d8.png")}
                    alt=""
                    className="timgu"
                  />
                </div>
                <div>
                  <img
                    src={require("../asserts/uce/d9.png")}
                    alt=""
                    className="timgu"
                  />
                </div>
                <div>
                  <img
                    src={require("../asserts/uce/d10.png")}
                    alt=""
                    className="timgu"
                  />
                </div>
                <div>
                  <img
                    src={require("../asserts/uce/d11.png")}
                    alt=""
                    className="timgu"
                  />
                </div>
                <div>
                  <img
                    src={require("../asserts/uce/d12.png")}
                    alt=""
                    className="timgu"
                  />
                </div>
                <div>
                  <img
                    src={require("../asserts/uce/d13.png")}
                    alt=""
                    className="timgu"
                  />
                </div>
                <div>
                  <img
                    src={require("../asserts/uce/d14.png")}
                    alt=""
                    className="timgu"
                  />
                </div>
              </div>
            </Container>

            <br />
            <img
              src={require("../asserts/uce/pro_7.png")}
              alt=""
              style={{
                display: "block",
                marginLeft: "auto",
                marginRight: "auto",
              }}
              width="40%"
            />
            <br />
            <Ucetable />
            <br />

            <Smartfeatures />

            <div>
              <img
                src={require("../asserts/uce/pro_16.png")}
                alt=""
                width="100%"
              />
            </div>

            <br />
            <br />
            <p>
              <span
                style={{
                  fontSize: "50px",
                  fontWeight: "600",
                  textAlign: "center",
                  alignItems: "center",
                  margin: "20px",
                }}
              >
                High-Quality Components
              </span>
            </p>

            <img
              src={require("../asserts/uce/pro_0.png")}
              alt=""
              width="100%"
            />

            <Container>
              <p
                style={{
                  fontSize: "50px",
                  fontWeight: "600",
                  textAlign: "center",
                  padding: "10px",
                  color: "#0082bc",
                }}
              >
                Tyent UCE-11 Specification's
              </p>
              <div className="d-flex justify-content-center align-iteams-center">
                <table className="spec" style={{ color: "#0082bc" }}>
                  <tr>
                    <td>
                      <b>Model</b>{" "}
                    </td>
                    <td>Tyent UCE-11.</td>
                  </tr>
                  <tr>
                    <td>
                      <b>Electrode/plates</b>{" "}
                    </td>
                    <td>:&nbsp;11.</td>
                  </tr>
                  <tr>
                    <td>
                      <b>pH Range</b>{" "}
                    </td>
                    <td>
                      :&nbsp;2.5 - 11.5.<sup>*</sup>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <b>ORP Range</b>{" "}
                    </td>
                    <td>
                      :&nbsp;up to - 1200.<sup>*</sup>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <b>Generates</b>{" "}
                    </td>
                    <td>:&nbsp;8 types of water levels.</td>
                  </tr>
                  <tr>
                    <td>
                      <b>pH Levels</b>{" "}
                    </td>
                    <td>
                      :&nbsp;3 alkaline, 2 Acidic, 1 neutral, & 1 Turbo (Strong
                      alkaline 11.5pH).
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <b> plate Surface area</b>{" "}
                    </td>
                    <td>:&nbsp;52.9*11 = 581.9 sq inches.</td>
                  </tr>
                  <tr>
                    <td>
                      <b>
                        Water Generating
                        Capacity&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      </b>{" "}
                    </td>
                    <td>:&nbsp;2-2.5L/min.</td>
                  </tr>
                  <tr>
                    <td>
                      <b>Filters Structure</b>{" "}
                    </td>
                    <td>:&nbsp;2 filters( .01 micron NSF & ANSI certified).</td>
                  </tr>
                  <tr>
                    <td>
                      <b>Filter Materials</b>{" "}
                    </td>
                    <td>
                      :&nbsp;Carbon Block, UF, TM ceramics, Membrance & Calcium
                      sulfite.
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <b>Filter Capacity</b>{" "}
                    </td>
                    <td>:&nbsp;8000L.</td>
                  </tr>
                  <tr>
                    <td>
                      <b>Dimension(mm)</b>{" "}
                    </td>
                    <td>
                      :&nbsp;360(W) * 132(D) * 355(H).
                      <br />
                      &nbsp; Faucet:&nbsp;50(W) * 343(D)
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <b>Weight(kg)</b>{" "}
                    </td>
                    <td>:&nbsp;6.5</td>
                  </tr>
                  <tr>
                    <td>
                      <b>Cleaning type</b>{" "}
                    </td>
                    <td>:&nbsp;Automatic (PAST technology).</td>
                  </tr>

                  <tr>
                    <td>
                      <b>Display Type</b>{" "}
                    </td>
                    <td>:&nbsp;One-touch LCD.</td>
                  </tr>

                  <tr>
                    <td>
                      <b>power Supply</b>{" "}
                    </td>
                    <td>:&nbsp;Patented SMPS PLUS power supply.</td>
                  </tr>

                  <tr>
                    <td>
                      <b>Power consumption</b>{" "}
                    </td>
                    <td>:&nbsp; Max 220W.</td>
                  </tr>

                  <tr>
                    <td>
                      <b>Operating Voltage Range</b>{" "}
                    </td>
                    <td>:&nbsp; 200 - 240V, 50-60HZ.</td>
                  </tr>

                  <tr>
                    <td>
                      <b>Voice Confirmation</b>{" "}
                    </td>
                    <td>:&nbsp; Yes</td>
                  </tr>

                  <tr>
                    <td>
                      <b>Input water parameters required</b>
                    </td>
                    <td>
                      {" "}
                      &nbsp; Input water TDS: 90 - 160.
                      <br /> : Input water pH: 7pH. <br /> &nbsp; Input water
                      Pressure: 5-7L/Min.{" "}
                    </td>
                  </tr>

                  <tr>
                    <td>
                      <b>Warranty</b>{" "}
                    </td>
                    <td>
                      :&nbsp;3 Years on ionizer & 15 years on Electrodes/plates.
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <b>General Services</b>{" "}
                    </td>
                    <td>
                      :&nbsp;3 Services per year up to 3 years in Warranty
                      peroid (PAN INDIA)
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <b>Safety Function</b>{" "}
                    </td>
                    <td>
                      :&nbsp;Water temperature sensor, Supplying water sensor,
                      Overheating protection sensor & Error indication sensor.
                    </td>
                  </tr>
                </table>
              </div>
            </Container>

            <hr
              style={{
                height: "1px",
                width: "100%",
                borderWidth: "0",
                backgroundColor: "#0082bc",
              }}
            />
            <br />
            <p
              style={{
                fontSize: "35px",
                textAlign: "center",
                fontWeight: "600",
                color: "#0082bc",
              }}
            >
              Tyent's NSF & ANSI certified Extreme .01 Micron filtration
            </p>

            <Row>
              <Col
                md={6}
                className="d-flex justify-content-center align-iteams-center"
              >
                <img
                  src={require("../asserts/filter.png")}
                  alt=""
                  width="65%"
                />
              </Col>
              <Col md={6} className="d-flex">
                <div className="oneT justify-content-left">
                  <div>
                    <p style={{ fontSize: "25px", textAlign: "left" }}>
                      <FaTint size={30} color="#0082bc" />
                      &nbsp;Tyent filters are EPA & NSF Certified.
                      <br />
                      <br />
                      <FaTint size={30} color="#0082bc" />
                      &nbsp;Tyent Extreme .01 micron filters remove even the
                      toughest <br />{" "}
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;contaminants
                      such as arsenic, chromium VI.etc
                      <br />
                      <br />
                      <FaTint size={30} color="#0082bc" />
                      &nbsp;Tyent filters can remove heavy metals,
                      pharmaceuticals, heavy
                      <br />{" "}
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;contamination,
                      Micro-organisms, Bacteria, Viruses, algae, fungi.
                    </p>
                  </div>
                </div>
              </Col>
            </Row>

            <Row>
              <Col md={6}>
                <Container>
                  <div className="cardfil">
                    <p style={{ fontSize: "20px", textAlign: "justify" }}>
                      <span
                        style={{
                          fontSize: "40px",
                          fontWeight: "600",
                          color: "#0082bc",
                        }}
                      >
                        Carbon block{" "}
                      </span>
                      <br />
                      Activated carbon was made into a block form has a larger
                      surfer area than partice -type activated carbon. it has a
                      very high adsorption rate. it works to filter residual
                      chlorine, organic compounds and heavy metals by activated
                      adsorption
                    </p>
                  </div>

                  <br />
                  <div className="cardfil">
                    <p style={{ fontSize: "20px", textAlign: "justify" }}>
                      <span
                        style={{
                          fontSize: "40px",
                          fontWeight: "600",
                          color: "#0082bc",
                        }}
                      >
                        Membrane{" "}
                      </span>
                      <br />
                      Pretretment filtration function for filtering various
                      precipitates contained in raw water
                    </p>
                  </div>

                  <br />
                  <div className="cardfil">
                    <p style={{ fontSize: "20px", textAlign: "justify" }}>
                      <span
                        style={{
                          fontSize: "40px",
                          fontWeight: "600",
                          color: "#0082bc",
                        }}
                      >
                        Calcium sulfite
                      </span>{" "}
                      <br />
                      Removes residual chlorine completely by oxidation
                      reduction reaction
                    </p>
                  </div>
                </Container>
              </Col>
              <Col md={6}>
                <Container>
                  <div className="cardfil">
                    <p
                      style={{
                        fontSize: "20px",
                        textAlign: "justify",
                        margin: "20px",
                      }}
                    >
                      <span
                        style={{
                          fontSize: "40px",
                          fontWeight: "600",
                          color: "#0082bc",
                        }}
                      >
                        TM Ceramic
                      </span>
                      <br />
                      The ceramic were made by mixing the high-emissivity far
                      infrarred minerals containing potassium(k), sodium(Na) and
                      calcium(Ca) with TM and TM - X stock solutions. These
                      multi functional ceramic have antioxidants, antimicrobial
                      and far infared Radiation
                    </p>
                  </div>

                  <br />
                  <div className="cardfil">
                    <p
                      style={{
                        fontSize: "20px",
                        textAlign: "justify",
                        margin: "20px",
                      }}
                    >
                      <span
                        style={{
                          fontSize: "40px",
                          fontWeight: "600",
                          color: "#0082bc",
                        }}
                      >
                        UF
                      </span>
                      <br />
                      Many Fine Pores on thr filter surface remove containment
                      from the water . I removes bad elements such as bacteria
                      viruses and particulates but does not remove substance
                      that are benefical to our body, such as minerals
                    </p>
                  </div>
                </Container>
              </Col>
            </Row>
          </center>

          <br />
          <br />
        </div>
        <Footer />
      </div>
    );
  }
}
export default uce11;
