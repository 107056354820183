import React from "react";
import { Row, Col } from "react-grid-system";
import Navbar from "../components/Navbar.js";
import "../style/Nmp5.css";
import { Button } from "@material-ui/core";
import { FaPhoneAlt } from "react-icons/fa";
import Footer from "../components/Footer";
import Acetable from "../components/Acetable.js";
import Smartfeatures from "../components/Smartfeatures";
import { Container } from "react-bootstrap";
import { FaTint } from "react-icons/fa";

class ace extends React.Component {
  state = {
    products: [
      {
        src: [
          "https://firebasestorage.googleapis.com/v0/b/tyent-fa17c.appspot.com/o/Ace%2Face1.webp?alt=media&token=99890c6b-afce-4751-aa3f-48367cb2f069",
          "https://firebasestorage.googleapis.com/v0/b/tyent-fa17c.appspot.com/o/Ace%2Face2.webp?alt=media&token=6746847b-83f1-4460-a07b-7ca0f9cf58aa",
          "https://firebasestorage.googleapis.com/v0/b/tyent-fa17c.appspot.com/o/Ace%2Face3.webp?alt=media&token=1282d462-337b-4890-8984-461164d724d0",
        ],
      },
    ],
    index: 0,
  };

  myRef = React.createRef();

  handleTab = (index) => {
    this.setState({ index: index });
    const image = this.myRef.current.children;
    for (let i = 0; i < image.length; i++) {
      image[i].className = image[i].className.replace("active", "");
    }
    image[index].className = "active";
  };

  componentDidMount() {
    const { index } = this.state;
    this.myRef.current.children[index].className = "active";
  }

  render() {
    const { products, index } = this.state;

    return (
      <div>
        <div className="home__image">
          <img src={require("../asserts/b3.webp")} alt="" width="100%" />
        </div>

        <Container>
          <Row
            className="d-flex justify-content-center"
            style={{ backgroundColor: "#FFF", borderRadius: "8px" }}
          >
            <Col
              md={6}
              className="d-flex justify-content-center align-items-center"
            >
              <br />

              <div
                style={{ width: "100%", margin: "30px", position: "relative" }}
              >
                {products.map((item) => (
                  <div className="details" key={item.src}>
                    <div className="d-flex justify-content-center align-items-center">
                      <img
                        src={item.src[index]}
                        alt=""
                        width="100%"
                        className="pi"
                      />
                    </div>
                    <div
                      className="thumb justify-content-center align-items-center"
                      ref={this.myRef}
                    >
                      {item.src.map((img, index) => (
                        <img
                          src={img}
                          alt=""
                          key={index}
                          onClick={() => this.handleTab(index)}
                        />
                      ))}
                    </div>
                  </div>
                ))}
              </div>
              <br />
            </Col>
            <Col md={6} className="d-flex">
              <div>
                <br />
                <h2
                  style={{
                    color: "#0082bc",
                    textAlign: "left",
                    fontSize: "50px",
                    justifyContent: "center",
                  }}
                >
                  <b>Tyent ACE 13 </b>
                </h2>
                <h3
                  style={{
                    color: "#000",
                    textAlign: "left",
                    fontSize: "40px",
                    justifyContent: "center",
                  }}
                >
                  M.R.P :{" "}
                  <span style={{ color: "#ff0000" }}> ₹ 3,67,999/-</span>
                </h3>
                <br />
                <table id="customers">
                  <tbody style={{ textAlign: "left" }}>
                    <tr>
                      <td>
                        <b>Electrode plates</b>{" "}
                      </td>
                      <td></td>
                      <td>:&nbsp;13</td>
                    </tr>
                    <tr>
                      <td>
                        <b>pH range</b>{" "}
                      </td>
                      <td></td>
                      <td>:&nbsp;2.5 - 11.5*</td>
                    </tr>
                    <tr>
                      <td>
                        <b>ORP Range</b>{" "}
                      </td>
                      <td></td>
                      <td>:&nbsp;up to - 1250*</td>
                    </tr>
                    <tr>
                      <td>
                        <b>Generates</b>{" "}
                      </td>
                      <td></td>
                      <td>:&nbsp;7 types of water levels.</td>
                    </tr>
                    <tr>
                      <td>
                        <b>Surface area</b>{" "}
                      </td>
                      <td></td>
                      <td>:&nbsp;52.9*13 = 687.7 sq inches</td>
                    </tr>
                    <tr>
                      <td>
                        <b>Warranty </b>
                      </td>
                      <td></td>
                      <td>:&nbsp;3 Years on machine & 15 years on plates</td>
                    </tr>
                    <tr>
                      <td colSpan="3">
                        <h2
                          style={{
                            color: "#000",
                            textAlign: "left",
                            fontSize: "15px",
                          }}
                        >
                          <b>*Depends on input water quality and Flow rate</b>
                        </h2>
                      </td>
                    </tr>
                    <tr>
                      <td colSpan="3">
                        <h2
                          style={{
                            color: "#000",
                            textAlign: "left",
                            fontSize: "15px",
                          }}
                        >
                          <b>
                            (No use of external chemicals, salts, or artificial
                            enhancers)
                          </b>
                        </h2>
                      </td>
                    </tr>
                  </tbody>
                </table>

                <Button
                  variant="contained"
                  style={{
                    color: "#FFF",
                    float: "right",
                    backgroundColor: "#0082bc",
                    textTransform: "none",
                    margin: "20px",
                  }}
                >
                  <FaPhoneAlt />
                  &nbsp;&nbsp;Call To Order
                </Button>
              </div>
            </Col>
          </Row>
        </Container>
        <div style={{ margin: "20px" }}>
          <center>
            <div>
              <p id="color">
                <strong>
                  <b>Colour Full Display</b>
                </strong>
              </p>
            </div>
            <br />
            <br />
            <Container>
              <div className="gridha">
                <div className="boxh">
                  <img
                    src={require("../asserts/hybrid/1.png")}
                    alt=""
                    width="100%"
                  />
                </div>
                <div className="boxh">
                  <img
                    src={require("../asserts/hybrid/2.png")}
                    alt=""
                    width="100%"
                  />
                </div>
                <div className="boxh">
                  <img
                    src={require("../asserts/hybrid/3.png")}
                    alt=""
                    width="100%"
                  />
                </div>
                <div className="boxh">
                  <img
                    src={require("../asserts/hybrid/4.png")}
                    alt=""
                    width="100%"
                  />
                </div>
                <div className="boxh">
                  <img
                    src={require("../asserts/hybrid/5.png")}
                    alt=""
                    width="100%"
                  />
                </div>
                <div className="boxh">
                  <img
                    src={require("../asserts/hybrid/6.png")}
                    alt=""
                    width="100%"
                  />
                </div>
                <div className="boxh">
                  <img
                    src={require("../asserts/hybrid/7.png")}
                    alt=""
                    width="100%"
                  />
                </div>
                <div className="boxh">
                  <img
                    src={require("../asserts/hybrid/8.png")}
                    alt=""
                    width="100%"
                  />
                </div>
              </div>
            </Container>
            <br />
            <p
              style={{
                textAlign: "center",
                fontSize: "40px",
                fontWeight: "600",
                color: "#0082bc",
              }}
            >
              Combination of Technology & Nature
            </p>
            <Row>
              <Col md={6}>
                <img
                  src={require("../asserts/ace/aceN.png")}
                  alt=""
                  width="100%"
                />
              </Col>
              <Col md={6}>
                <img
                  src={require("../asserts/ace/aceT.png")}
                  alt=""
                  width="100%"
                />
              </Col>
            </Row>

            <br />
            <center>
              <Container>
                <img
                  src={require("../asserts/ace/eco.png")}
                  alt=""
                  width="100%"
                  style={{ borderRadius: "8px" }}
                />
                <p
                  style={{
                    fontSize: "40px",
                    fontWeight: "600",
                    padding: "20px",
                    color: "#000",
                  }}
                >
                  The getting smarter the more convenient!
                </p>
                <p style={{ fontSize: "20px" }}>
                  The wider screen, 4-inch TFT, LCD & The large CPU capacity
                  <br />
                  The rich color of 262,000 gives you more information and
                  convenience.
                </p>
                <br />
                <br />
                <img
                  src={require("../asserts/ace/pro_04.png")}
                  alt=""
                  width="100%"
                  style={{ borderRadius: "8px" }}
                />
                <br />
                <br />
                <p
                  style={{
                    fontSize: "40px",
                    fontWeight: "600",
                    padding: "20px",
                    color: "#000",
                  }}
                >
                  Exclusive, Fully Programmable,
                  <br />
                  Motion Activated Flow Sensor
                </p>
                <p style={{ fontSize: "20px" }}>
                  Proximity sensor function for automatic water outﬂow
                </p>
                <br />
                <br />
                <img
                  src={require("../asserts/ace/pro_05.png")}
                  alt=""
                  width="100%"
                  style={{ borderRadius: "8px" }}
                />
                <br />
                <br />
                <p
                  style={{
                    fontSize: "40px",
                    fontWeight: "600",
                    padding: "20px",
                    color: "#000",
                  }}
                >
                  Auto Fill Quantity Selector & Jog Dial Control
                </p>
                <p style={{ fontSize: "20px" }}>
                  Fixed quantity discharge function
                </p>
                <br />
                <br />
                <img
                  src={require("../asserts/ace/pro_06.png")}
                  alt=""
                  width="100%"
                  style={{ borderRadius: "8px" }}
                />
                <br />
                <br />
                <p
                  style={{
                    fontSize: "40px",
                    fontWeight: "600",
                    padding: "20px",
                    color: "#000",
                  }}
                >
                  Accustomed way of use like a Smart phone
                </p>
                <p style={{ fontSize: "20px" }}>
                  Updated Screen Layout Display
                </p>
                <br />
                <br />
                <img
                  src={require("../asserts/ace/pro_07.png")}
                  alt=""
                  width="80%"
                  style={{ borderRadius: "8px" }}
                />
                <br />
                <br />
                <p
                  style={{
                    fontSize: "40px",
                    fontWeight: "600",
                    padding: "20px",
                    color: "#000",
                  }}
                >
                  Automatic Cleaning function Setting
                </p>
                <p style={{ fontSize: "20px" }}>
                  Customize your Water Ionizer automatic cleaning cycle
                  <br />
                  based on your use and source water
                </p>
                <br />
                <br />
                <img
                  src={require("../asserts/ace/pro_09.png")}
                  alt=""
                  width="80%"
                  style={{ borderRadius: "8px" }}
                />
                <br />
                <br />
              </Container>
              <br />
              <br />
              <br />

              <Row>
                <Col md={6}>
                  <img
                    src={require("../asserts/nmp/drain.png")}
                    alt=""
                    width="100%"
                  />
                </Col>
                <Col md={6} className="d-flex">
                  <div className="oneT">
                    <hr
                      style={{
                        height: "15vh",
                        width: ".5vw",
                        borderWidth: "0",
                        backgroundColor: "#0082bc",
                      }}
                    />
                    <p
                      style={{
                        margin: "20px",
                        fontSize: "21px",
                        textAlign: "left",
                      }}
                    >
                      <span
                        style={{
                          color: "#0082bc",
                          fontSize: "40px",
                          fontWeight: "600",
                        }}
                      >
                        Drain Faucet
                      </span>{" "}
                      <br />
                      It comes with Drain faucet that can be easily installed to
                      anywhere on the sink
                    </p>
                  </div>
                </Col>
              </Row>
            </center>

            <Row>
              <Col
                md={6}
                className="d-flex justify-content-center align-items-center"
              >
                <img
                  src={require("../asserts/ace/industry.png")}
                  alt=""
                  width="50%"
                />{" "}
              </Col>
              <Col
                md={6}
                className="d-flex justify-content-center align-items-center"
              >
                <div className="oneT">
                  <hr
                    style={{
                      height: "15vh",
                      width: ".5vw",
                      borderWidth: "0",
                      backgroundColor: "#0082bc",
                    }}
                  />
                  <p
                    style={{
                      margin: "20px",
                      fontSize: "21px",
                      textAlign: "justify",
                    }}
                  >
                    <span
                      style={{
                        color: "#0082bc",
                        fontSize: "40px",
                        fontWeight: "600",
                      }}
                    >
                      INDUSTRY'S FIRST
                    </span>{" "}
                    <br />
                    <b>Hands-Free Motion: </b>Proximity Sensor Function For
                    Automatic Water Outflow Set the proximity sensor to your
                    favorite and most commonly used water setting.
                  </p>
                </div>
              </Col>
            </Row>

            <Row>
              <Col
                md={6}
                className="d-flex justify-content-center text-align-center"
              >
                <img
                  src={require("../asserts/ace/pro_11.png")}
                  alt=""
                  width="70%"
                />
              </Col>
              <Col
                md={6}
                className="d-flex justify-content-center text-align-center"
              >
                <div className="oneT">
                  <hr
                    style={{
                      height: "25vh",
                      width: ".5vw",
                      borderWidth: "0",
                      backgroundColor: "#0082bc",
                    }}
                  />

                  <p
                    style={{
                      fontSize: "32px",
                      margin: "20px",
                      textAlign: "left",
                    }}
                  >
                    Filter usage memory function
                    <br />
                    Filter Usage Indication
                    <br />
                    and Replacement Time Guide
                    <br />
                    Super Simple Filter Replacement System
                  </p>
                </div>
              </Col>
            </Row>
            <br />
            <br />

            <Row>
              <Col
                md={6}
                className="d-flex justify-content-center text-align-center"
              >
                <img
                  src={require("../asserts/ace/pro_12.png")}
                  alt=""
                  width="70%"
                />
              </Col>
              <Col
                md={6}
                className="d-flex justify-content-center text-align-center"
              >
                <div className="oneT">
                  <hr
                    style={{
                      height: "20vh",
                      width: ".5vw",
                      borderWidth: "0",
                      backgroundColor: "#0082bc",
                    }}
                  />

                  <p
                    style={{
                      fontSize: "32px",
                      margin: "20px",
                      textAlign: "left",
                    }}
                  >
                    Automatic Stop Function
                    <br />
                    Sensing function of supplied raw water
                    <br />
                    Temperature sensor
                    <br />
                    Detects if filter door is open
                    <br />
                  </p>
                </div>
              </Col>
            </Row>

            <br />
            <Row>
              <Col md={4}>
                <img
                  src={require("../asserts/ace/spec.png")}
                  alt=""
                  style={{
                    display: "block",
                    marginLeft: "auto",
                    marginRight: "auto",
                  }}
                  width="60%"
                />
              </Col>
              <Col md={8}>
                <div style={{ margin: "20px" }}>
                  <Acetable />
                </div>
              </Col>
            </Row>

            <Container>
              <p
                style={{
                  fontSize: "50px",
                  fontWeight: "600",
                  textAlign: "center",
                  padding: "10px",
                  color: "#0082bc",
                }}
              >
                Tyent ACE-13 Specification's
              </p>
              <div className="d-flex justify-content-center align-iteams-center">
                <table className="spec" style={{ color: "#0082bc" }}>
                  <tr>
                    <td>
                      <b>Model</b>{" "}
                    </td>
                    <td>Tyent ACE-13.</td>
                  </tr>
                  <tr>
                    <td>
                      <b>Electrode/plates</b>{" "}
                    </td>
                    <td>:&nbsp;13.</td>
                  </tr>
                  <tr>
                    <td>
                      <b>pH Range</b>{" "}
                    </td>
                    <td>
                      :&nbsp;2.5 - 11.5.<sup>*</sup>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <b>ORP Range</b>{" "}
                    </td>
                    <td>
                      :&nbsp;up to - 1250.<sup>*</sup>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <b>Generates</b>{" "}
                    </td>
                    <td>:&nbsp;8 types of water levels.</td>
                  </tr>
                  <tr>
                    <td>
                      <b>pH Levels</b>{" "}
                    </td>
                    <td>
                      :&nbsp;3 alkaline, 2 Acidic, 1 neutral, & 1 Turbo (Strong
                      alkaline 11.5pH).
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <b> plate Surface area</b>{" "}
                    </td>
                    <td>:&nbsp;52.9*13 = 687.7 sq inches.</td>
                  </tr>
                  <tr>
                    <td>
                      <b>
                        Water Generating
                        Capacity&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      </b>{" "}
                    </td>
                    <td>:&nbsp;1.5-3.0L/min.</td>
                  </tr>
                  <tr>
                    <td>
                      <b>Filters Structure</b>{" "}
                    </td>
                    <td>:&nbsp;2 filters( .01 micron NSF & ANSI certified).</td>
                  </tr>
                  <tr>
                    <td>
                      <b>Filter Materials</b>{" "}
                    </td>
                    <td>
                      :&nbsp;Carbon Block, UF, TM ceramics, Membrance & Calcium
                      sulfite.
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <b>Filter Capacity</b>{" "}
                    </td>
                    <td>:&nbsp;8000L.</td>
                  </tr>
                  <tr>
                    <td>
                      <b>Dimension(mm)</b>{" "}
                    </td>
                    <td>:&nbsp;300(W) * 135(D) * 355(H).</td>
                  </tr>
                  <tr>
                    <td>
                      <b>Weight(kg)</b>{" "}
                    </td>
                    <td>:&nbsp;5.2</td>
                  </tr>
                  <tr>
                    <td>
                      <b>Cleaning type</b>{" "}
                    </td>
                    <td>:&nbsp;Automatic (PAST technology).</td>
                  </tr>

                  <tr>
                    <td>
                      <b>Display Type</b>{" "}
                    </td>
                    <td>:&nbsp;One-touch LCD.</td>
                  </tr>

                  <tr>
                    <td>
                      <b>power Supply</b>{" "}
                    </td>
                    <td>:&nbsp;Patented SMPS PLUS power supply.</td>
                  </tr>

                  <tr>
                    <td>
                      <b>Power consumption</b>{" "}
                    </td>
                    <td>:&nbsp; Max 220W.</td>
                  </tr>

                  <tr>
                    <td>
                      <b>Operating Voltage Range</b>{" "}
                    </td>
                    <td>:&nbsp; 200 - 240V, 50-60HZ.</td>
                  </tr>

                  <tr>
                    <td>
                      <b>Voice Confirmation</b>{" "}
                    </td>
                    <td>:&nbsp; Yes</td>
                  </tr>

                  <tr>
                    <td>
                      <b>Input water parameters required</b>
                    </td>
                    <td>
                      {" "}
                      &nbsp; Input water TDS: 90 - 160.
                      <br /> : Input water pH: 7pH. <br /> &nbsp; Input water
                      Pressure: 5-7L/Min.{" "}
                    </td>
                  </tr>

                  <tr>
                    <td>
                      <b>Warranty</b>{" "}
                    </td>
                    <td>
                      :&nbsp;3 Years on ionizer & 15 years on Electrodes/plates.
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <b>General Services</b>{" "}
                    </td>
                    <td>
                      :&nbsp;3 Services per year up to 3 years in Warranty
                      peroid (PAN INDIA)
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <b>Safety Function</b>{" "}
                    </td>
                    <td>
                      :&nbsp;Water temperature sensor, Supplying water sensor,
                      Overheating protection sensor & Error indication sensor.
                    </td>
                  </tr>
                </table>
              </div>
            </Container>

            <br />
            <Smartfeatures />

            <br />

            <hr
              style={{
                height: "1px",
                width: "100%",
                borderWidth: "0",
                backgroundColor: "#0082bc",
              }}
            />
            <br />
            <p
              style={{
                fontSize: "35px",
                textAlign: "center",
                fontWeight: "600",
                color: "#0082bc",
              }}
            >
              Tyent's NSF & EPA certified Extreme .01 Micron filtration
            </p>

            <Row>
              <Col
                md={6}
                className="d-flex justify-content-center align-iteams-center"
              >
                <img
                  src={require("../asserts/filter.png")}
                  alt=""
                  width="65%"
                />
              </Col>
              <Col md={6} className="d-flex">
                <div className="oneT">
                  <div>
                    <p style={{ fontSize: "25px", textAlign: "left" }}>
                      <FaTint size={30} color="#0082bc" />
                      &nbsp;Tyent filters are EPA & NSF Certified.
                      <br />
                      <br />
                      <FaTint size={30} color="#0082bc" />
                      &nbsp;Tyent Extreme .01 micron filters remove even the
                      toughest <br />{" "}
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;contaminants
                      such as arsenic, chromium VI.etc
                      <br />
                      <br />
                      <FaTint size={30} color="#0082bc" />
                      &nbsp;Tyent filters can remove heavy metals,
                      pharmaceuticals, heavy
                      <br />{" "}
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;contamination,
                      Micro-organisms, Bacteria, Viruses, algae, fungi.
                    </p>
                  </div>
                </div>
              </Col>
            </Row>

            <Row>
              <Col md={6}>
                <Container>
                  <div className="cardfil">
                    <p style={{ fontSize: "20px", textAlign: "justify" }}>
                      <span
                        style={{
                          fontSize: "40px",
                          fontWeight: "600",
                          color: "#0082bc",
                        }}
                      >
                        Carbon block{" "}
                      </span>
                      <br />
                      Activated carbon was made into a block form has a larger
                      surfer area than partice -type activated carbon. it has a
                      very high adsorption rate. it works to filter residual
                      chlorine, organic compounds and heavy metals by activated
                      adsorption
                    </p>
                  </div>

                  <br />
                  <div className="cardfil">
                    <p style={{ fontSize: "20px", textAlign: "justify" }}>
                      <span
                        style={{
                          fontSize: "40px",
                          fontWeight: "600",
                          color: "#0082bc",
                        }}
                      >
                        Membrane{" "}
                      </span>
                      <br />
                      Pretretment filtration function for filtering various
                      precipitates contained in raw water
                    </p>
                  </div>

                  <br />
                  <div className="cardfil">
                    <p style={{ fontSize: "20px", textAlign: "justify" }}>
                      <span
                        style={{
                          fontSize: "40px",
                          fontWeight: "600",
                          color: "#0082bc",
                        }}
                      >
                        Calcium sulfite
                      </span>{" "}
                      <br />
                      Removes residual chlorine completely by oxidation
                      reduction reaction
                    </p>
                  </div>
                </Container>
              </Col>
              <Col md={6}>
                <Container>
                  <div className="cardfil">
                    <p
                      style={{
                        fontSize: "20px",
                        textAlign: "justify",
                        margin: "20px",
                      }}
                    >
                      <span
                        style={{
                          fontSize: "40px",
                          fontWeight: "600",
                          color: "#0082bc",
                        }}
                      >
                        TM Ceramic
                      </span>
                      <br />
                      The ceramic were made by mixing the high-emissivity far
                      infrarred minerals containing potassium(k), sodium(Na) and
                      calcium(Ca) with TM and TM - X stock solutions. These
                      multi functional ceramic have antioxidants, antimicrobial
                      and far infared Radiation
                    </p>
                  </div>

                  <br />
                  <div className="cardfil">
                    <p
                      style={{
                        fontSize: "20px",
                        textAlign: "justify",
                        margin: "20px",
                      }}
                    >
                      <span
                        style={{
                          fontSize: "40px",
                          fontWeight: "600",
                          color: "#0082bc",
                        }}
                      >
                        UF
                      </span>
                      <br />
                      Many Fine Pores on thr filter surface remove containment
                      from the water . I removes bad elements such as bacteria
                      viruses and particulates but does not remove substance
                      that are benefical to our body, such as minerals
                    </p>
                  </div>
                </Container>
              </Col>
            </Row>
          </center>

          <br />
          <br />
        </div>
        <Footer />
      </div>
    );
  }
}

export default ace;
