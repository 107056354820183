import React, { useEffect } from "react";
import { Row, Col } from "react-grid-system";
import Navbar from "../components/Navbar";
import { makeStyles } from "@material-ui/core/styles";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Formd from "../components/Formd";
import Footer from "../components/Footer";
import "../style/Homepage.css";
import { useLocation } from "react-router-dom";
import ReactGA from "react-ga";
import { Helmet } from "react-helmet";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
}));

const WhytentPage = () => {
  useEffect(() => {
    ReactGA.initialize("G-FP7N65QWQ6");
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  const classes = useStyles();

  return (
    <div>
      <Helmet>
        <title>
          Tyent: Leading Water Ionizer in India. Higher pH than Kangen water and
          contains more health-boosting antioxidants
        </title>
        <meta
          name="description"
          content="Tyent: Leading Water Ionizer in India. Higher pH than Kangen water and contains more health-boosting antioxidants"
        />
      </Helmet>

      <Row>
        <Col
          md={4}
          className="d-flex align-items-center justify-content-center"
        >
          <aside className="sticky">
            <div className="leftside" style={{ justifyContent: "center" }}>
              <img
                src={require("../asserts/waterdrop.png")}
                alt=""
                style={{ width: "100%" }}
              />
            </div>
          </aside>
        </Col>
        <Col md={8} className="align-items-center justify-content-center w-100">
          <p
            style={{
              color: "#0082bc",
              fontSize: "35px",
              fontWeight: "500",
              align: "center",
              padding: "0px",
            }}
          >
            <span style={{ fontSize: "95px", fontWeight: "900" }}>
              13 Reasons{" "}
            </span>{" "}
            <br />
            &nbsp; Why{" "}
            <span style={{ fontWeight: "900", fontSize: "45px" }}>
              <strong>Tyent</strong>{" "}
            </span>{" "}
            Leads the Industry{" "}
          </p>
          <div
            className="rightside"
            style={{ display: "flex", justifyContent: "center" }}
          >
            <div
              className={classes.root}
              style={{ justifyContent: "center", padding: "20px" }}
            >
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography>
                    <span style={{ fontSize: "20px" }}>
                      1) Solid Hybrid Mesh Plate design
                    </span>
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    <Row>
                      <Col md={8}>
                        <p
                          style={{
                            textAlign: "justify",
                            textJustify: "inter-word",
                            fontSize: "18px",
                          }}
                        >
                          All Tyent Water Ionizers come with standard
                          medical-grade Solid Hybrid Mesh Plate technology.
                          Solid Hybrid Mesh Plate plates offer more strength,
                          durability & more surface area because of Solid Hybrid
                          Mesh Plate design. Our engineers begin the Tyent
                          plate-making process with the thickest and longest
                          plates in the industry, and then they incorporate
                          cutting-edge electrolysis distribution methods for
                          maximum electrical conductivity.
                          <br />
                          <br />
                          Most ionizers have plates that are either solid or
                          mesh. Tyent engineers were not satisfied with either
                          design. Here’s why: Solid plates offer strength but
                          lack premium electrical distribution. Since
                          electricity is drawn to edges (which is why a
                          lightning rod has a pointed edge) a solid plate’s
                          electrical current will only be transmitted around the
                          outer edge of the plate. The edges are key, which is
                          why mesh plates seem ideal; but since they lack
                          durability, they do not meet Tyent’s high standards.
                          Since neither of the available plate styles was
                          sufficient, our Tyent engineers developed the
                          cutting-edge Tyent Solid Hybrid Mesh Plates. The Tyent
                          Solid Hybrid Mesh Plate was engineered according to
                          Faraday’s electrolysis distribution methods.
                        </p>
                      </Col>
                      <Col md={4}>
                        <img
                          src={require("../asserts/img-1.webp")}
                          alt=""
                          style={{ width: "100%" }}
                        />
                      </Col>
                    </Row>
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel2a-content"
                  id="panel2a-header"
                >
                  <Typography>
                    {" "}
                    <span style={{ fontSize: "20px" }}>
                      2) Plates with more surface area in the Industry{" "}
                    </span>{" "}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    <Row>
                      <Col md={4}>
                        <img
                          src={require("../asserts/plate.jpg")}
                          alt=""
                          style={{ width: "100%" }}
                        />
                      </Col>
                      <Col md={8}>
                        <p
                          style={{
                            textAlign: "justify",
                            textJustify: "inter-word",
                            fontSize: "18px",
                          }}
                        >
                          All Tyent Water Ionizers come standard with
                          medical-grade Solid Hybrid Mesh Plate technology.
                          Solid Hybrid Mesh Plate plates offer the durability of
                          solid plates with the extensive surface area of mesh
                          plates. Our engineers begin the Tyent plate-making
                          process with the thickest and longest plates in the
                          industry, and then they incorporate cutting-edge
                          electrolysis distribution methods for maximum
                          electrical conductivity.
                          <br />
                          <br />
                          Most ionizers have plates that are either solid or
                          mesh. Tyent engineers were not satisfied with either
                          design. Here's why: Solid plates offer strength but
                          lack premium electrical distribution. Since
                          electricity is drawn to edges (which is why a
                          lightning rod has a pointed edge) a solid plate's
                          electrical current will only be transmitted around the
                          outer edge of the plate. The edges are key, which is
                          why mesh plates seem ideal; but since they lack
                          durability, they do not meet Tyent's high standards.
                          Since neither of the available plate styles was
                          sufficient, our Tyent engineers developed the
                          cutting-edge Tyent Solid Hybrid Mesh Plates. The Tyent
                          Solid Hybrid Mesh Plate was engineered according to
                          Faraday's electrolysis distribution methods.
                        </p>
                      </Col>
                    </Row>
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel2a-content"
                  id="panel2a-header"
                >
                  <Typography>
                    <span style={{ fontSize: "20px" }}>
                      3) Patented SMPS PLUS® Power{" "}
                    </span>
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    <Row>
                      <Col md={8}>
                        <p
                          style={{
                            textAlign: "justify",
                            textJustify: "inter-word",
                            fontSize: "18px",
                          }}
                        >
                          All Tyent Water Ionizers come standard with
                          medical-grade Solid Hybrid Mesh Plate technology.
                          Solid Hybrid Mesh Plate plates offer the durability of
                          solid plates with the extensive surface area of mesh
                          plates. Our engineers begin the Tyent plate-making
                          process with the thickest and longest plates in the
                          industry, and then they incorporate cutting-edge
                          electrolysis distribution methods for maximum
                          electrical conductivity.
                          <br />
                          <br />
                          Most ionizers have plates that are either solid or
                          mesh. Tyent engineers were not satisfied with either
                          design. Here’s why: Solid plates offer strength but
                          lack premium electrical distribution. Since
                          electricity is drawn to edges (which is why a
                          lightning rod has a pointed edge) a solid plate’s
                          electrical current will only be transmitted around the
                          outer edge of the plate. The edges are key, which is
                          why mesh plates seem ideal; but since they lack
                          durability, they do not meet Tyent’s high standards.
                          Since neither of the available plate styles was
                          sufficient, our Tyent engineers developed the
                          cutting-edge Tyent Solid Hybrid Mesh Plates. The Tyent
                          Solid Hybrid Mesh Plate was engineered according to
                          Faraday’s electrolysis distribution methods.
                        </p>
                      </Col>
                      <Col md={4}>
                        <img
                          src={require("../asserts/smps.jpg")}
                          alt=""
                          style={{ width: "100%" }}
                        />
                      </Col>
                    </Row>
                  </Typography>
                </AccordionDetails>
              </Accordion>

              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel2a-content"
                  id="panel2a-header"
                >
                  <Typography>
                    <span style={{ fontSize: "20px" }}>
                      4) Super Water Capability{" "}
                    </span>
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    <Row>
                      <Col md={4}>
                        <img
                          src={require("../asserts/3.jpg")}
                          alt=""
                          style={{ width: "100%" }}
                        />
                      </Col>
                      <Col md={8}>
                        <p
                          style={{
                            textAlign: "justify",
                            textJustify: "inter-word",
                            fontSize: "18px",
                          }}
                        >
                          Tyent coined the term “Super Water,” and we are still
                          the ONLY machine with the largest Solid Hybrid Mesh
                          plates and enough power to produce super water without
                          chemicals. Super Water means that Tyent ionizer's have
                          a range of 2.5 to 11.5* on the pH scale, while our
                          water ionizers have a range of 10.0 to 4.0* on the pH
                          scale.
                          <br />
                          <br />
                          On the low end of this scale, water with a pH of 2.5 -
                          3.5 works as an antiseptic and can be used as an
                          all-natural sanitizer on surfaces. On the high end of
                          this scale, water with a pH of 11 and higher is great
                          to use for removing oil-based herbicides, pesticides &
                          wax content from fruits and vegetables. It also can be
                          used for Stain Removal.
                          <br />
                          <br />
                          Tyent ionizers don't use any Chemical/Enhancer/Salt to
                          produce 2.5 to 11.5 pH but other ionizers in the
                          market use Chemical/Enhancer/Salts to produce the
                          strongest acid or strongest alkaline water.
                        </p>
                      </Col>
                    </Row>
                  </Typography>
                </AccordionDetails>
              </Accordion>

              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel2a-content"
                  id="panel2a-header"
                >
                  <Typography>
                    <span style={{ fontSize: "20px" }}>
                      5) Extreme Filtration Capacity
                    </span>{" "}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    <Row>
                      <Col md={8}>
                        <p
                          style={{
                            textAlign: "justify",
                            textJustify: "inter-word",
                            fontSize: "18px",
                          }}
                        >
                          Ionized hydrogen-rich alkaline water is very
                          beneficial, but its benefits depend on having the
                          purest drinking water possible. Tyent Water Ionizers
                          have two filters, offering a customized multi-stage,
                          sophisticated system designed to cleanse your tap
                          water of impurities.
                          <br />
                          <br />
                          Our Ultra filters offer a .01 micron filter media,
                          equal to those found in kidney dialysis machines. We
                          also have the largest physical filter in the industry.
                          The size of the filters increases the purity of Tyent
                          Water™. Tyent water ionizer removes all the
                          contamination, virus, bacteria & even Microorganisms.
                          <br />
                          <br />
                          Water passes lengthwise through the filters;
                          therefore, the longer the filter, the more media it
                          has to pass through. That means even purer water.
                          Tyent is the industry leader in filtration.
                        </p>
                      </Col>
                      <Col md={4}>
                        <img
                          src={require("../asserts/1.webp")}
                          alt=""
                          style={{ width: "100%" }}
                        />
                      </Col>
                    </Row>
                  </Typography>
                </AccordionDetails>
              </Accordion>

              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel2a-content"
                  id="panel2a-header"
                >
                  <Typography className={classes.heading}>
                    {" "}
                    <span style={{ fontSize: "20px" }}>
                      6) PAST Cleaning System{" "}
                    </span>
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    <Row>
                      <Col md={4}>
                        <img
                          src={require("../asserts/img-11.webp")}
                          alt=""
                          style={{ width: "100%" }}
                        />
                      </Col>
                      <Col md={8}>
                        <p
                          style={{
                            textAlign: "justify",
                            textJustify: "inter-word",
                            fontSize: "18px",
                          }}
                        >
                          Tyent Water Ionizers use an automated Polarity Anti
                          Scale Technology (PAST)- one of the most advanced
                          cleaning systems in the industry. This automated
                          cleaning cycle is triggered by both a timer and an
                          internal sensor to ensure minimal mineral scale
                          buildup on the electrodes for long-term performance.
                          Better cleaning means longer-lasting components and
                          healthier water.
                          <br />
                        </p>
                      </Col>
                    </Row>
                  </Typography>
                </AccordionDetails>
              </Accordion>

              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel2a-content"
                  id="panel2a-header"
                >
                  <Typography className={classes.heading}>
                    <span style={{ fontSize: "20px" }}>
                      7) International quality Certifications{" "}
                    </span>
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    <Row>
                      <Col md={8}>
                        <p
                          style={{
                            textAlign: "justify",
                            textJustify: "inter-word",
                            fontSize: "18px",
                          }}
                        >
                          Tyent is both ISO 9001, ISO 14001 & ISO 13485
                          certified. The International Standards Organization
                          (ISO), is responsible for certifying facilities for
                          quality and consistency in product manufacturing.
                          <br />
                          <br />
                          ISO certification ensures the lowest possible defect
                          rates and confirms that the manufacturing facility
                          operates in a manner that is safe for workers and the
                          environment.
                          <br />
                        </p>
                      </Col>
                      <Col md={4}>
                        <img
                          src={require("../asserts/img-7.webp")}
                          alt=""
                          style={{ width: "100%" }}
                        />
                      </Col>
                    </Row>
                  </Typography>
                </AccordionDetails>
              </Accordion>

              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel2a-content"
                  id="panel2a-header"
                >
                  <Typography className={classes.heading}>
                    {" "}
                    <span style={{ fontSize: "20px" }}>
                      8) Certified medical device{" "}
                    </span>
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    <Row>
                      <Col md={4}>
                        <img
                          src={require("../asserts/img-8.webp")}
                          alt=""
                          style={{ width: "100%" }}
                        />
                      </Col>
                      <Col md={8}>
                        <p
                          style={{
                            textAlign: "justify",
                            textJustify: "inter-word",
                            fontSize: "18px",
                          }}
                        >
                          Our machine is a certified medical device by the South
                          Korean Ministry of Food and Drug Safety (MFDS) from
                          Korea and is used by thousands of doctors across the
                          world to treat and prevent a wide variety of
                          illnesses.
                          <br />
                          <br />
                          Tyent is UL listed (E334893). The Underwriters
                          Laboratory (UL) is the highest standard in safety and
                          the most difficult safety listing to obtain. The
                          Canadian Standards Association (CSA) uses the same
                          standards as UL. These organizations certify devices
                          for electrical safety. Tyent USA products are both CSA
                          and UL approved for electrical safety.
                        </p>
                      </Col>
                    </Row>
                  </Typography>
                </AccordionDetails>
              </Accordion>

              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel2a-content"
                  id="panel2a-header"
                >
                  <Typography className={classes.heading}>
                    {" "}
                    <span style={{ fontSize: "20px" }}>
                      9) Better Business Bureau A+ Rating
                    </span>
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    <Row>
                      <Col md={10}>
                        <p
                          style={{
                            textAlign: "justify",
                            fontSize: "18px",
                            textJustify: "inter-word",
                          }}
                        >
                          An A+ rating from the Better Business Bureau (BBB) is
                          what companies strive for. The BBB's vision is to
                          create an ethical marketplace where buyers and sellers
                          can trust each other. The BBB is a well-recognized and
                          highly respected organization which rates companies
                          based on its Standards of Trust. Tyent USA has an A+
                          rating from the BBB.
                          <br />
                        </p>
                      </Col>
                      <Col md={2}>
                        <img
                          src={require("../asserts/bbb.webp")}
                          alt=""
                          style={{ width: "60%" }}
                        />
                      </Col>
                    </Row>
                  </Typography>
                </AccordionDetails>
              </Accordion>

              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel2a-content"
                  id="panel2a-header"
                >
                  <Typography className={classes.heading}>
                    <span style={{ fontSize: "20px" }}>
                      10) Highest Antioxidant Production Levels{" "}
                    </span>{" "}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Suspendisse malesuada lacus ex, sit amet blandit leo
                    lobortis eget.
                  </Typography>
                </AccordionDetails>
              </Accordion>

              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel2a-content"
                  id="panel2a-header"
                >
                  <Typography className={classes.heading}>
                    <span style={{ fontSize: "20px" }}>
                      11) One-Touch display Technology{" "}
                    </span>
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    <Row>
                      <Col md={8}>
                        <p
                          style={{
                            textAlign: "justify",
                            textJustify: "inter-word",
                            fontSize: "18px",
                          }}
                        >
                          Our machines are the only machines in the industry
                          that have true one-touch display technology. With only
                          one touch of any icon on the control panel, you can
                          have clean, healthy water anytime you want. Tyent
                          water ionizers are easy enough for children, seniors
                          and everyone in between to operate with a single
                          touch.
                          <br />
                          <br />
                          The water that flows from your faucet has specific
                          characteristics. Tyent’s advanced computerized control
                          system ensures optimal performance based on your
                          unique source water. Tyent’s adjustability option will
                          give you the flexibility to create great water, no
                          matter where you live.
                        </p>
                      </Col>
                      <Col md={4}>
                        <img
                          src={require("../asserts/content-6.webp")}
                          alt=""
                          style={{ width: "100%" }}
                        />
                      </Col>
                    </Row>
                  </Typography>
                </AccordionDetails>
              </Accordion>

              <Accordion style={{ backgroundColor: "#ffe260" }}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel2a-content"
                  id="panel2a-header"
                >
                  <Typography className={classes.heading}>
                    <span style={{ fontSize: "20px" }}>
                      12) Warranty & doorstep service{" "}
                    </span>{" "}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    <Row style={{ backgroundColor: "#ffe260" }}>
                      <Col md={4}>
                        <img
                          src={require("../asserts/img-6.webp")}
                          alt=""
                          style={{ width: "100%" }}
                        />
                      </Col>
                      <Col md={8}>
                        <p
                          style={{
                            textAlign: "justify",
                            textJustify: "inter-word",
                            fontSize: "18px",
                          }}
                        >
                          Every Tyent water ionizer in India carries 3 years
                          warranty on the ionizer and 15 years warranty on the
                          ionization chamber (Plates). In the warrenty period of
                          3 years, every customer receives 9 free doorstep
                          services which are completely free of cost.
                          <br />
                          <br />
                          Read our warranty carefully and then compare it to our
                          competitors’ warranties. Your investment is fully
                          covered, regardless of your water quality or the
                          quantity of water you produce.
                        </p>
                      </Col>
                    </Row>
                  </Typography>
                </AccordionDetails>
              </Accordion>

              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel2a-content"
                  id="panel2a-header"
                >
                  <Typography className={classes.heading}>
                    <span style={{ fontSize: "20px" }}>
                      13) Chemical and Lead-Free{" "}
                    </span>
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    <Row>
                      <Col md={4}>
                        <img
                          src={require("../asserts/5.jpg")}
                          alt=""
                          style={{ width: "100%" }}
                        />
                      </Col>
                      <Col md={8}>
                        <p
                          style={{
                            textAlign: "justify",
                            textJustify: "inter-word",
                            fontSize: "18px",
                          }}
                        >
                          Tyent’s large plates and adjustable power settings
                          enable our range of water ionizers to reach low acidic
                          pH levels and high alkaline pH levels without the use
                          of dangerous chemicals.
                          <br />
                          <br />
                          Tyent units are certified chemical and lead-free,
                          which is why so many health professionals and wellness
                          centers prefer Tyent over any other water ionizer
                          manufacturer in the industry.
                          <br />
                          <br />
                          Insist on a chemical-free machine. Sellers of
                          chemical-based ionizer machines will try to persuade
                          you that the chemicals they use are risk-free and
                          don’t reach your glass, but those chemicals travel
                          through the same water cell that your drinking water
                          comes from.
                          <br />
                          <br />
                          It’s implausible that every trace of the chemicals
                          will be eliminated – is that a risk you want to take?
                        </p>
                      </Col>
                    </Row>
                  </Typography>
                </AccordionDetails>
              </Accordion>
            </div>
          </div>
        </Col>
      </Row>

      <br />

      <Formd />
      <Footer />
    </div>
  );
};

export default WhytentPage;
