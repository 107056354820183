import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import '../style/Nmp5.css';

const smartfeatures = () => {
  return (
    <div>
        <br/>
        <center>
      <h1 style={{fontWeight:'600', textAlign:'center', fontSize:'50px',color:'#0082bc'}}>SMART safety Diagnosis function</h1>
          <br/>
          <Container>
            <div id='gridsmart'>
                <div><Row>
                <Col md={3} className="d-flex justify-content-center align-items-center"><img src={require('../asserts/products/icon_05.png')}  alt="" className='imgsmart'/></Col>

                    <Col md={9} className="d-flex justify-content-center align-items-center">
                        <p id='smartfeature' style={{textAlign:'justify', color:'#000',fontSize:'20px'}}><span style={{fontSize:'30px',fontWeight:'600'}}>Automatic Stop Function</span><br/>
                        Water outflow will stop to prevent overflow or flooding. 
                        there is no need to wait if the unit automatically shuts off.
                        You can resume its use immediately.
                        </p></Col>
                        </Row></div>

                        <div><Row>
                        <Col md={3} className="d-flex justify-content-center align-items-center"><img src={require('../asserts/products/icon_06.png')}  alt="" className='imgsmart'/></Col>
                    <Col md={9} className="d-flex justify-content-center align-items-center">
                        <p id='smartfeature' style={{textAlign:'justify',color:'#000',fontSize:'20px'}}><span style={{fontSize:'30px', fontWeight:'600'}}>Temperature sensor</span><br/>
                        This unit is equipped with a temperature sensor which prevents the accidental inflow of hot water.
                        </p></Col></Row></div>
                        <div><Row>
                        <Col md={3} className="d-flex justify-content-center align-items-center"><img src={require('../asserts/products/icon_07.png')}  alt="" className='imgsmart'/></Col>
                    <Col md={9} className="d-flex justify-content-center align-items-center">
                        <p id='smartfeature' style={{color:'#000',textAlign:'justify',fontSize:'20px'}}><span style={{fontSize:'26px', fontWeight:'600'}}>Sensing function of supplied raw water</span><br/>
                    The outflow of functional water stops automatically when raw water is not supplied, preventing the electrolytic cell and power
                    supply unit from being damaged due to current overflow, water from being wasted and accidental water leaks.
                        </p></Col></Row></div>
                        <div><Row>
                        <Col md={3} className="d-flex justify-content-center align-items-center"><img src={require('../asserts/products/icon_08.png')}  alt="" className='imgsmart'/></Col>
                    <Col md={9} className="d-flex justify-content-center align-items-center">
                        <p id='smartfeature' style={{textAlign:'justify', color:'#000',fontSize:'20px'}}><span style={{fontSize:'30px',fontWeight:'600'}}>Detects if filter door is open</span><br/>
                    When door is open, the water supply will be suspended for your safety.
                        </p></Col></Row></div>

                
            </div>
          </Container>
      </center>
    </div>
  )
}

export default smartfeatures
