import { ContactMail } from "@material-ui/icons";
import { Container } from "react-bootstrap";
import Footer from "../components/Footer";
import CustomizedTables from "../components/CustomizedTables.js";
import PriceComp from "../components/PriceComp";
import { fontSize } from "@mui/system";
import { FaRegThumbsUp, FaRegThumbsDown } from "react-icons/fa";
import ReactGA from "react-ga";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";

const TyentvsKangen = () => {
  useEffect(() => {
    ReactGA.initialize("G-FP7N65QWQ6");
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return (
    <div>
      <img src={require("../asserts/kagenvstyent.webp")} width={"100%"} />
      <Container>
        <h3
          style={{
            color: "#0082bc",
            paddingTop: "20px",
            fontSize: "70px",
            textAlign: "center",
          }}
        >
          <b>
            Tyent{" "}
            <span
              style={{
                fontSize: "50px",
                fontWeight: "800",
                textShadow: "4px 4px #000",
                transform: "rotate(-90deg)",
              }}
            >
              VS
            </span>{" "}
            Kangen
          </b>
        </h3>
        <p style={{ fontSize: "20px", textAlign: "justify" }}>
          Tyent and Enagic Kangen are two popular companies specializing in
          manufacturing Medical grade water ionizers. These high-quality water
          ionizers produce ionized hydrogen-rich alkaline water which as various
          health benefits. Enagic Kangen is known for its Kangen Water Machine,
          which offers a range of different models to suit various needs and
          budgets.
          <b>
            {" "}
            Enagic Kangen is more popular for its network marketing business
            model that allows customers to become independent distributors and
            earn commissions by selling Enagic Kangen products.
          </b>
          <br />
          <br />
          <b style={{ color: "#0082bc" }}>
            However, Enagic Kangen has some drawbacks like:
          </b>
          <br />
          <br />
          <p>
            <FaRegThumbsDown style={{ color: "#fe0000" }} />
            &nbsp;Outdated solid plates
          </p>
          <p>
            <FaRegThumbsDown style={{ color: "#fe0000" }} />
            &nbsp;Outdated Transformer power supply
          </p>
          <p>
            <FaRegThumbsDown style={{ color: "#fe0000" }} />
            &nbsp;Uses artificial enhancers
          </p>
          <p>
            <FaRegThumbsDown style={{ color: "#fe0000" }} />
            &nbsp;No sales or service support
          </p>
          <p>
            <FaRegThumbsDown style={{ color: "#fe0000" }} />
            &nbsp;High maintenance costs
          </p>
          <br />
          <br />
          <img
            src={require("../asserts/products/tyentvskamain1.jpg")}
            alt="tyentvskangenbanner"
            width={"100%"}
          />
          <br />
          <br />
          <br />
          <img
            src={require("../asserts/products/tyentvskamain2.jpg")}
            alt="tyentvskangenbanner"
            width={"100%"}
          />
          <br />
          <br />
          <br />
          <img
            src={require("../asserts/products/tyentvskamain3.jpg")}
            alt="tyentvskangenbanner"
            width={"100%"}
          />
          On the other hand, Tyent is a strong player in the water ionizer
          market, offering high-quality and innovative water ionizers with
          updated technology and superior water quality. Tyent's water ionizers
          use advanced Solid hybrid mesh plates and Patented SMPS technology to
          produce ionized hydrogen-rich alkaline water with a high antioxidant
          capacity. Tyent also offers a range of different models with advanced
          features at different price options.
          <br />
          <br />
          <b style={{ color: "#0082bc" }}>
            {" "}
            If you're looking for the best water ionizer, Tyent is the top
            choice.
          </b>
          <br />
          <br />
          <p>
            <FaRegThumbsUp style={{ color: "#009241" }} />
            &nbsp;Tyent's Solid hybrid mesh plates.
          </p>
          <p>
            <FaRegThumbsUp style={{ color: "#009241" }} />
            &nbsp;Patented SMPS PLUS
          </p>
          <p>
            <FaRegThumbsUp style={{ color: "#009241" }} />
            &nbsp;greater surface area
          </p>
          <p>
            <FaRegThumbsUp style={{ color: "#009241" }} />
            &nbsp;Extreme .01 micron filters
          </p>
          <p>
            <FaRegThumbsUp style={{ color: "#009241" }} />
            &nbsp;PAST cleaning technology
          </p>
          <p>
            <FaRegThumbsUp style={{ color: "#009241" }} />
            &nbsp;Chemical and enhancer-free ionizer
          </p>
          <p>
            <FaRegThumbsUp style={{ color: "#009241" }} />
            &nbsp;International certifications
          </p>
          <p>
            <FaRegThumbsUp style={{ color: "#009241" }} />
            &nbsp;doorstep service make
          </p>
          <br />
          <br />
          Ultimately, the choice between Enagic Kangen and Tyent depends on
          individual preferences, needs, and budget. To make an informed
          decision, it's important to do thorough research and compare the
          features, pricing, and customer reviews of each water ionizer.
        </p>
        <br />
        <CustomizedTables />
        <br />
        <PriceComp />
      </Container>
      <Footer />
    </div>
  );
};

export default TyentvsKangen;
