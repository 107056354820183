import { Col, Container, Row } from "react-bootstrap";
import Footer from "../components/Footer";
import { FaTint } from "react-icons/fa";
import React, { useEffect } from "react";
import ReactGA from "react-ga";
import { useLocation } from "react-router-dom";

const Replacement = () => {
  useEffect(() => {
    ReactGA.initialize("G-FP7N65QWQ6");
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);


  
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return (
    <div>
      <img
        src={require("../asserts/FiltersWebBanner.webp")}
        alt="FiltersWebBanner"
        width={"100%"}
      />
      <Container>
        <br />

        <p style={{ color: "#0082bc", fontSize: "35px" }}>
          {" "}
          <b>Tyent's .01 Micron extreme filtration</b>
        </p>
        <p style={{ fontSize: "25px" }}>
          {" "}
          Our Tyent's .01 Micron extreme filters are the most advanced and
          comprehensively tested water ionizer filters in the market and are
          tested at real tap flow rates. Tyent uses Independent, EPA & NSF
          (Government-Certified) Laboratories for all its Filter Testing.
        </p>

        <p style={{ fontSize: "25px" }}>
          <FaTint size={30} color="#0082bc" />
          &nbsp;Tyent filters are EPA & ANSI Certified.
          <br />
          <br />
          <FaTint size={30} color="#0082bc" />
          &nbsp;Tyent Extreme .01 micron filters remove even the toughest
          contaminants such as arsenic, chromium VI.etc
          <br />
          <br />
          <FaTint size={30} color="#0082bc" />
          &nbsp;Tyent filters can remove heavy metals, pharmaceuticals, heavy
          contamination, Micro-organisms, Bacteria, Viruses, algae, fungi.
        </p>

        <Row>
          <Col md={6}>
            <Container>
              <div className="cardfil">
                <p style={{ fontSize: "20px", textAlign: "justify" }}>
                  <span
                    style={{
                      fontSize: "40px",
                      fontWeight: "600",
                      color: "#0082bc",
                    }}
                  >
                    Carbon block{" "}
                  </span>
                  <br />
                  Activated carbon was made into a block form has a larger
                  surfer area than partice -type activated carbon. it has a very
                  high adsorption rate. it works to filter residual chlorine,
                  organic compounds and heavy metals by activated adsorption
                </p>
              </div>

              <br />
              <div className="cardfil">
                <p style={{ fontSize: "20px", textAlign: "justify" }}>
                  <span
                    style={{
                      fontSize: "40px",
                      fontWeight: "600",
                      color: "#0082bc",
                    }}
                  >
                    Membrance{" "}
                  </span>
                  <br />
                  Pretretment filtration function for filtering various
                  precipitates contained in raw water
                </p>
              </div>

              <br />
              <div className="cardfil">
                <p style={{ fontSize: "20px", textAlign: "justify" }}>
                  <span
                    style={{
                      fontSize: "40px",
                      fontWeight: "600",
                      color: "#0082bc",
                    }}
                  >
                    Calcium sulfite
                  </span>{" "}
                  <br />
                  Removes residual chlorine completely by oxidation reduction
                  reaction
                </p>
              </div>
            </Container>
          </Col>
          <Col md={6}>
            <Container>
              <div className="cardfil">
                <p
                  style={{
                    fontSize: "20px",
                    textAlign: "justify",
                    margin: "20px",
                  }}
                >
                  <span
                    style={{
                      fontSize: "40px",
                      fontWeight: "600",
                      color: "#0082bc",
                    }}
                  >
                    TM Ceramic
                  </span>
                  <br />
                  The ceramic were made by mixing the high-emissivity far
                  infrarred minerals containing potassium(k), sodium(Na) and
                  calcium(Ca) with TM and TM - X stock solutions. These multi
                  functional ceramic have antioxidants, antimicrobial and far
                  infared Radiation
                </p>
              </div>

              <br />
              <div className="cardfil">
                <p
                  style={{
                    fontSize: "20px",
                    textAlign: "justify",
                    margin: "20px",
                  }}
                >
                  <span
                    style={{
                      fontSize: "40px",
                      fontWeight: "600",
                      color: "#0082bc",
                    }}
                  >
                    UF
                  </span>
                  <br />
                  Many Fine Pores on thr filter surface remove containment from
                  the water . I removes bad elements such as bacteria viruses
                  and particulates but does not remove substance that are
                  benefical to our body, such as minerals
                </p>
              </div>
            </Container>
          </Col>
        </Row>
      </Container>
      <br />
      <br />
      <br />
      <Footer />
    </div>
  );
};

export default Replacement;
