import "../style/index.css";
import React, { useEffect } from "react";
import ReactGA from "react-ga";
import { Helmet } from "react-helmet";
import { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
const CertificatePage = () => {
  const [show, setShow] = useState(false);
  const [img, setimg] = useState();
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  useEffect(() => {
    ReactGA.initialize("G-FP7N65QWQ6");
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  <Helmet>
    <title>
      Tyent certifications | Top certifications of Tyent water ionizer
    </title>
    <meta
      name="keywords"
      content="Best water ionizer in Hyderabad, Best kangen water ionizer in Hyderabad, Most advanced water ionizer in Hyderabad, Best Enagic kangen inIndia, Best Hydrogen rich alkaline water ionizer in Telangana, Most advanced water ionizer in India, Most trusted water ionizer in the world, Most selling water ionizer in India"
    />
    <meta
      name="description"
      content="Tyent water ionizer has more than 60+ International patents and certified by all International quality certification authorities."
    />
  </Helmet>;
  const imagesrcsend = (event) => {
    setimg(event.target.src);
    setShow(true);
  };
  return (
    <div>
      <section>
        <div className="row no-gutters">
          <div className="col-md-6 no-gutters d-flex align-items-center justify-content-center text-center">
            <div className="leftside">
              <p style={{ fontSize: "75px", color: "#008ac7" }}>
                <b>Certifications & Accreditations</b>
              </p>
            </div>
          </div>
          <div className="col-md-6 no-gutters d-flex align-items-center justify-content-center text-center">
            <div className="rightside">
              <img
                src={require("../asserts/thumbsup-girl.webp")}
                alt="image"
                style={{ width: "70%" }}
              />
            </div>
          </div>
        </div>
      </section>

      <div id="grid1">
        <div>
          <div className="thumbnail">
            <a href="#">
              <img
                src={require("../asserts/certification/iso1.webp")}
                alt="Lights"
                width="90%"
                onClick={imagesrcsend}
              />
            </a>
          </div>
        </div>
        <div>
          <div className="thumbnail">
            <a href="#">
              <img
                src={require("../asserts/certification/iso2.webp")}
                alt="Lights"
                width="90%"
                onClick={imagesrcsend}
              />
            </a>
          </div>
        </div>
        <div>
          <div className="thumbnail">
            <a href="#">
              <img
                src={require("../asserts/certification/iso3.webp")}
                alt="Lights"
                width="90%"
                onClick={imagesrcsend}
              />
            </a>
          </div>
        </div>
        <div>
          <div className="thumbnail">
            <a href="#">
              <img
                src={require("../asserts/certification/iso6.webp")}
                alt="Lights"
                width="90%"
                onClick={imagesrcsend}
              />
            </a>
          </div>
        </div>
        <div>
          <div className="thumbnail">
            <a href="#">
              <img
                src={require("../asserts/certification/iso4.webp")}
                alt="Lights"
                width="90%"
                onClick={imagesrcsend}
              />
            </a>
          </div>
        </div>
        <div>
          <div className="thumbnail">
            <a href="#">
              <img
                src={require("../asserts/certification/permelc.webp")}
                alt="Lights"
                width="90%"
                onClick={imagesrcsend}
              />
            </a>
          </div>
        </div>
        <div>
          <div class="thumbnail">
            <a href="#">
              <img
                src={require("../asserts/certification/iso5.webp")}
                alt="Lights"
                width="90%"
                onClick={imagesrcsend}
              />
            </a>
          </div>
        </div>

        <div>
          <div className="thumbnail">
            <a href="#">
              <img
                src={require("../asserts/certification/iso8.webp")}
                alt="Lights"
                width="90%"
                onClick={imagesrcsend}
              />
            </a>
          </div>
        </div>
        <div>
          <div className="thumbnail">
            <a href="#">
              <img
                src={require("../asserts/certification/kita.webp")}
                alt="Lights"
                width="90%"
                onClick={imagesrcsend}
              />
            </a>
          </div>
        </div>
        <div>
          <div className="thumbnail">
            <a href="#">
              <img
                src={require("../asserts/certification/iso14.webp")}
                alt="Lights"
                width="90%"
                onClick={imagesrcsend}
              />
            </a>
          </div>
        </div>
        <div>
          <div className="thumbnail">
            <a href="#">
              <img
                src={require("../asserts/certification/iso16.webp")}
                alt="Lights"
                width="90%"
                onClick={imagesrcsend}
              />
            </a>
          </div>
        </div>

        <div>
          <div className="thumbnail">
            <a href="#">
              <img
                src={require("../asserts/certification/iso12.webp")}
                alt="Lights"
                width="90%"
                onClick={imagesrcsend}
              />
            </a>
          </div>
        </div>

        <div>
          <div className="thumbnail">
            <a href="#">
              <img
                src={require("../asserts/certification/iso11.webp")}
                alt="Lights"
                width="90%"
                onClick={imagesrcsend}
              />
            </a>
          </div>
        </div>

        <div>
          <div className="thumbnail">
            <a href="#">
              <img
                src={require("../asserts/certification/iso13.webp")}
                alt="Lights"
                width="90%"
                onClick={imagesrcsend}
              />
            </a>
          </div>
        </div>

        <div>
          <div className="thumbnail">
            <a href="#">
              <img
                src={require("../asserts/certification/iso9.webp")}
                alt="Lights"
                width="90%"
                onClick={imagesrcsend}
              />
            </a>
          </div>
        </div>
        <div>
          <div className="thumbnail">
            <a href="#">
              <img
                src={require("../asserts/certification/iso10.webp")}
                alt="Lights"
                width="90%"
                onClick={imagesrcsend}
              />
            </a>
          </div>
        </div>

        <div>
          <div className="thumbnail">
            <a href="#">
              <img
                src={require("../asserts/certification/kuv580.webp")}
                alt="Lights"
                width="90%"
                onClick={imagesrcsend}
              />
            </a>
          </div>
        </div>
        <div>
          <div className="thumbnail">
            <a href="#">
              <img
                src={require("../asserts/certification/nemuko.webp")}
                alt="Lights"
                width="90%"
                onClick={imagesrcsend}
              />
            </a>
          </div>
        </div>

        <div>
          <div className="thumbnail">
            <a href="#">
              <img
                src={require("../asserts/certification/e15.webp")}
                alt="Lights"
                width="90%"
                onClick={imagesrcsend}
              />
            </a>
          </div>
        </div>

        <div>
          <div className="thumbnail">
            <a href="#">
              <img
                src={require("../asserts/certification/iso7.webp")}
                alt="Lights"
                width="90%"
                onClick={imagesrcsend}
              />
            </a>
          </div>
        </div>

        <div>
          <div className="thumbnail">
            <a href="#">
              <img
                src={require("../asserts/certification/l17.webp")}
                alt="Lights"
                width="90%"
                onClick={imagesrcsend}
              />
            </a>
          </div>
        </div>
      </div>
      {/* modal  */}
      <Modal show={show} dialogClassName="modal-90w" onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Certificate</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <img src={img} style={{ width: "100%" }} />
        </Modal.Body>
        <Modal.Footer>
          <a href={img} download="image.jpg">
            <Button variant="secondary">Download Certificate </Button>
          </a>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default CertificatePage;
