import React, { useEffect } from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Aos from "aos";
import "aos/dist/aos.css";






const StyledTableCell = withStyles((theme) => ({
 
  
  body: {
    fontSize: 18,
    padding:'10px',
    
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
    
    },
  },
}))(TableRow);



function createData(check, calories, fat, wrong, carbs  ) {


  return {check, calories, fat, wrong, carbs};
}



const rows = [  
  createData('No','Name', 'No', 'Name'),
  createData(
           <div style={{backgroundColor:'#008ac7',color:'white',  borderRadius: '50%', width:'50%', alignItems:'center', textAlign:'center', fontSize: '20px'}}>01</div>,
           'Alkaline level 1',
           <div style={{backgroundColor:'#008ac7',color:'white',  borderRadius: '50%', width:'50%', alignItems:'center', textAlign:'center', fontSize: '20px'}}>09</div>, 'Acidic level 3'),
  createData(<div style={{backgroundColor:'#008ac7',color:'white',  borderRadius: '50%', width:'50%', alignItems:'center', textAlign:'center', fontSize: '20px'}}>02</div>,
          'Alkaline level 2',     
          <div style={{backgroundColor:'#008ac7',color:'white',  borderRadius: '50%', width:'50%', alignItems:'center', textAlign:'center', fontSize: '20px'}}>10</div>, 'Filter usage'),
  createData(
             <div style={{backgroundColor:'#008ac7',color:'white',  borderRadius: '50%', width:'50%', alignItems:'center', textAlign:'center', fontSize: '20px'}}>03</div>,
             'Alkaline level 3',     
             <div style={{backgroundColor:'#008ac7',color:'white',  borderRadius: '50%', width:'50%', alignItems:'center', textAlign:'center', fontSize: '20px'}}>11</div>, 'System setup'),
  createData(
             <div style={{backgroundColor:'#008ac7',color:'white',  borderRadius: '50%', width:'50%', alignItems:'center', textAlign:'center', fontSize: '20px'}}>04</div>,
             'Purified water',      
             <div style={{backgroundColor:'#008ac7',color:'white',  borderRadius: '50%', width:'50%', alignItems:'center', textAlign:'center', fontSize: '20px'}}>12</div>, 'Voice On/Off'),
  createData(
            <div style={{backgroundColor:'#008ac7',color:'white',  borderRadius: '50%', width:'50%', alignItems:'center', textAlign:'center', fontSize: '20px'}}>05</div>,
            'Fixed qty discharge', 
            <div style={{backgroundColor:'#008ac7',color:'white',  borderRadius: '50%', width:'50%', alignItems:'center', textAlign:'center', fontSize: '20px'}}>13</div>, 'Manual Cleaning'),
  createData(
            <div style={{backgroundColor:'#008ac7',color:'white',  borderRadius: '50%', width:'50%', alignItems:'center', textAlign:'center', fontSize: '20px'}}>06</div>,
            'Turbo water',
            <div style={{backgroundColor:'#008ac7',color:'white',  borderRadius: '50%', width:'50%', alignItems:'center', textAlign:'center', fontSize: '20px'}}>14</div>, 'Eco mode On/Off'),
  createData(
            <div style={{backgroundColor:'#008ac7',color:'white',  borderRadius: '50%', width:'50%', alignItems:'center', textAlign:'center', fontSize: '20px'}}>07 </div>,
            'Acidic level 1',       
            <div style={{backgroundColor:'#008ac7',color:'white',  borderRadius: '50%', width:'50%', alignItems:'center', textAlign:'center', fontSize: '20px'}}>15</div>, '1st Filter reset'),
  createData(
    <div style={{backgroundColor:'#008ac7',color:'white',  borderRadius: '50%', width:'50%', alignItems:'center', textAlign:'center', fontSize: '20px'}}>08</div>,
    'Acidic level 2',       
    <div style={{backgroundColor:'#008ac7',color:'white',  borderRadius: '50%', width:'50%', alignItems:'center', textAlign:'center', fontSize: '20px'}}>16</div>, '2nd Filter reset'),
  
];

const useStyles = makeStyles({
  table: {
    minWidth: 700,
    padding: 0,
  },
});


export default function Acetable  () {
    const classes = useStyles();
    useEffect(() => {
      Aos.init({
        debounceDelay: 50, 
        duration:2000,
        easing: 'ease',
        once:false,
      });
    }, []);
  
  return (
    <div>
       <TableContainer component={Paper}>
      <Table className={classes.table} aria-label="customized table">
        <TableHead>
          
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <StyledTableRow >
              <StyledTableCell >{row.check}</StyledTableCell>
              <StyledTableCell >{row.calories}</StyledTableCell>
              <StyledTableCell >{row.fat}</StyledTableCell>
              <StyledTableCell >{row.wrong}</StyledTableCell>
              <StyledTableCell >{row.carbs}</StyledTableCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
    </div>
  )
}


