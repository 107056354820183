import React from 'react'
import { Container } from 'react-bootstrap'
import Footer from '../components/Footer'

const FoFPages = () => {
  return (
    <div>
      <Container>
      <h1>404 page</h1>
      </Container>
      <Footer />
    </div>
  )
}

export default FoFPages
